@import "../../../../styles/base";

.ListItemsThesaurus {
  padding: 20px 20px 0;
  @extend %fontRegular;

  h3 {
    font-size: 12px;
    @extend %fontRoundBold;
    color: #7f808e;
    letter-spacing: 1px;
    margin-bottom: 5px;
    padding-right: 34px;
    line-height: normal;
    text-transform: uppercase;
  }

  ul {
    width: 90%;
    margin: 0 auto;

    li {
      color: $cyan50;
      padding: 7px 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.activeThesaurus {
        color: #5c5b97;
        @extend %fontRoundBold;
      }

      .tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;

        .thesaurusTooltip {
          line-height: 10px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 5px;
    padding: 10px 20px 0;

    h3 {
      font-size: 12px;
      color: #999be0;
      font-weight: 300;
      opacity: 0.7;
    }

    ul {
      width: 100%;

      li {
        font-size: 16px;
        color: #575863;
        letter-spacing: 1px;
        font-weight: 600;
        opacity: 0.8;

        &.activeThesaurus {
          color: #575863;
        }
      }
    }
  }
}
