@import "../../../styles/base.scss";

.associateFieldset {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  height: fit-content;
  position: relative;

  .fieldset {
    position: relative;
    flex: 1;

    &:nth-child(1) {
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 80%;
        background-color: #aabee7;
        right: 0;
        bottom: 10%;
        position: absolute;
        opacity: 0.3;
        z-index: 2;
      }
    }
  }

  .reactSelect {
    margin-bottom: 0;
    width: 100%;
    flex: 2;
  }

  &.showDeleteIcon {
    padding-right: 36px;

    .deleteIcon {
      height: 100%;
      width: 36px;
      background-color: #fff1f2;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #e5c2c4;
      }
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;

    .fieldset {
      min-width: 100%;

      &:nth-child(1) {
        &::after {
          right: auto;
          bottom: 0;
          height: 1px;
          width: 95%;
          left: 2.5%;
        }
      }
    }
  }
}
