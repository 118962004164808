@import "../../../styles/base";

.ScriptBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  
 

  .bgScriptBox {
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.7);
    z-index: 10;
  }

  .windowScriptBox {
    width: 768px;
    height: fit-content;
    max-height: 90vh;
    background: #e2e5fb;
    position: fixed;
    border-radius: 4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 21;
    will-change: transform;
    transform: translateY(100vh);
    transition: 300ms cubic-bezier(0, 0.8, 0.8, 1);
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      max-height: 72px;
      background: $terciaryColor;
      display: flex;
      align-items: center;
      padding: 0 25px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      svg {
        min-width: 20px;
      }

      h2 {
        font-size: 20px;
        @extend %fontRoundBold;
        color: #ffffff;
        margin-left: 15px;
      }

      .closeIcon {
        margin: 10px 0 10px auto;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .resizeHeader {
      max-height: fit-content;
      padding: 16px 25px;

      .closeIcon {
        margin-top: 0;
      }
    }

    .content {
      // padding: 24px 24px 82px 24px;
      padding: 24px;
      position: relative;
      max-height: 85vh;
      overflow-y: auto;

      &.allowOverflow {
        overflow: visible;
      }

      &.removePadding {
        padding: 0;
      }

      &.marginBottom {
        margin-bottom: 72px;
      }
    }
  }

  &.active {
    .windowScriptBox {
      transform: translateY(0);
    }
  }

  &.small {
    .windowScriptBox {
      width: 576px;
    }
  }
  &.medium {
    .windowScriptBox {
      width: 776px;
    }
  }

  &.large {
    .windowScriptBox {
      width: 960px;
    }
  }

  @media screen and (max-width: 1024px) {
    .windowScriptBox {
      width: 100%;
      max-height: 100vh;
      border-radius: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
      overflow-y: scroll;

      .header {
        border-radius: 0;
      }

      .content {
        flex-grow: 1;
        max-height: 100vh;
      }
    }

    &.small,
    &.large, &.medium {
      .windowScriptBox {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 340px) {
    .windowScriptBox {
      .header {
        h2 {
          font-size: 18px;
        }
      }
    }
  }




}
