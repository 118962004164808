@import "../../../../styles/base.scss";

.createCharacter {
  padding-bottom: 90px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;

    .formField {
      width: 100%;
      margin-bottom: 25px;
      flex-direction: column;

      .optionalText {
        line-height: 16px;
        font-size: 11px;
        letter-spacing: 0.1em;
        color: #575863;
        display: block;
        padding-left: 2px;
        margin-top: 8px;
      }

      .editSelect__menu {
        z-index: 5;
      }

      .fieldLabel {
        color: $terciaryColor;
        @extend %fontRegular;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
      }

      .fieldset {
        width: 100%;
      }

      .switchTextContainer {
        .switchText {
          .switchOption {
            flex: 1;
            min-width: 30%;
          }
        }
      }

      .dashedBox {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed rgba(170, 190, 231, 0.5);
        height: 44px;

        span {
          display: block;
          text-align: center;
          font-size: 14px;
          color: #38a86e;
          @extend %fontRoundBold;
          cursor: pointer;
        }
      }
    }

    .formFieldColumn {
      .textarea {
        margin-bottom: 0;
      }

      .charactersCounter,
      .charactersCounterError {
        line-height: 16px;
        font-size: 11px;
        letter-spacing: 0.05em;
        color: #575863;
        display: block;
        padding-left: 2px;
        margin-top: 8px;
      }

      .charactersCounterError {
        color: $errorColor;
      }
    }
  }

  @media screen and (max-width: 550px) {
    form {
      .formField {
        .switchTextContainer {
          .switchText {
            .switchOption {
              min-width: 100%;
              min-height: 50px;
            }
          }
        }
      }
    }
  }
}
