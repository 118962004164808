@import "../../../styles/base.scss";

#NewSearch {
  padding-bottom: 60px;

  #Breadcrumb {
    .container {
      max-width: 1150px;
      width: 1150px;

      .searchHeader {
        display: flex;
        margin: 0;

        .searchTitle {
          margin-right: 8px;
        }

        .searchTerms {
          color: #e2e4ff;
        }
      }
    }
  }

  #ResultSection {
    min-height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 36px;

    .sectionContainer {
      width: 100%;
      max-width: 1150px;
      display: flex;
      min-height: 600px;

      .leftContainer {
        display: flex;
        width: calc(100% - 768px);
        margin-right: 16px;
        flex-direction: column;

        .itemsCounter {
          padding: 0;
        }
      }

      .rightContainer {
        width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .noResults {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 80px;
          text-align: center;
          padding: 0 16px;
          height: fit-content;

          svg {
            margin-bottom: 12px;
          }

          .mainText {
            font-size: 18px;
            @extend %fontRoundBold;
            margin: 12px 0;
            color: $midnightBlue70;
          }

          .subText {
            font-size: 16px;
            color: $midnightBlue70;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1160px) {
    #ResultSection {
      .sectionContainer {
        flex-direction: column;

        .leftContainer {
          width: 100%;
          padding: 0 16px 32px 16px;
        }

        .rightContainer {
          width: 100%;
        }
      }
    }
  }
}
