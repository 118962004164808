@import "../../../styles/base.scss";

.centerLoading {
  padding: 10px;
  text-align: center;
}

.infoCard {
  width: 100%;
  background-color: #daddfe;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px;
  border-radius: 4px;
  margin-bottom: 36px;

  .phasesCardHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 32px;
    border-bottom: 1px solid transparent;
    max-height: 77px;
    cursor: pointer;

    .cardArrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #24233d;
      margin: 0 24px 2px 4px;
      transition: 200ms ease-in-out;
    }

    .cardTitle {
      display: flex;
      align-items: center;
      line-height: 28px;
      font-size: 20px;
      color: #1e1f24;
    }

    &.opened {
      border-bottom: 1px solid #c0c3f8;

      .cardArrow {
        transform: rotate(90deg);
      }
    }
  }

  .cardContentContainer {
    height: 0;
    overflow: hidden;

    &.opened {
      height: fit-content;
    }

    .cardContent {
      padding: 24px 32px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid hsl(237, 80%, 86%);

      &:last-of-type {
        border-bottom: none;
      }

      .phaseCardItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;

        .phaseTitle {
          text-transform: uppercase;
          color: #43444e;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 0.1em;
          margin-bottom: 4px;
          display: flex;
          align-items: center;

          .blockInfo {
            width: 70px;
            display: flex;
            border: 1px solid #7f808e;
            color: #7f808e;
            padding: 3px 6px 0 6px;
            border-radius: 2px;
            @extend %fontLight;
            text-transform: uppercase;
            margin-left: 8px;
            margin-bottom: 2px;
            font-size: 11px;
            letter-spacing: 0.1em;
          }
        }
      }

      .phaseName {
        color: #7070b3;
        @extend %fontRoundBold;
        margin: 6px 0;
        font-size: 14px;
        cursor: pointer;
        word-break: break-word;
      }

      .phaseInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 0 16px 0;

        .phasePeriod {
          font-size: 14px;
          color: #6b6c78;
        }

        .phaseStatus {
          font-size: 12px;
          color: #43444e;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .cardHeader {
      padding: 24px 16px;

      .cardContentContainer {
        padding: 24px 16px;
      }
    }
  }
}
