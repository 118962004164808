@import "../../../styles/base.scss";

.infoCard {
  width: 100%;
  background-color: #daddfe;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px;
  border-radius: 4px;
  margin-bottom: 36px;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
    border-bottom: 1px solid #c0c3f8;
    max-height: 77px;

    .cardTitle {
      display: flex;
      align-items: center;
      line-height: 28px;
      font-size: 20px;
      color: #1e1f24;
    }

    .scriptBoxCaller {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 5px;
    }
  }

  .cardContent {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #c0c3f8;

    &:last-of-type {
      border-bottom: none;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 6px 0;

      .attachmentType {
        text-transform: uppercase;
        color: #43444e;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;

        .blockInfo {
          width: 70px;
          display: flex;
          border: 1px solid #7f808e;
          color: #7f808e;
          padding: 3px 6px 0 6px;
          border-radius: 2px;
          @extend %fontLight;
          text-transform: uppercase;
          margin-left: 8px;
          margin-bottom: 2px;
          font-size: 11px;
          letter-spacing: 0.1em;
        }
      }

      .attachmentDate {
        color: #43444e;
        font-size: 14px;
        font-weight: 100;
      }
    }

    .attachmentName {
      color: #007eb5;
      @extend %fontRoundBold;
      margin: 6px 0;
      cursor: pointer;
      word-break: break-word;

      a {
        color: #007eb5;
        text-decoration: none;
      }
    }

    .attachmentInfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 5px;

      .capInfo {
        font-size: 14px;
        color: #43444e;
        line-height: 16px;
        padding-right: 8px;
        letter-spacing: 1px;
      }

      .blockInfo {
        font-size: 12px;
        text-transform: uppercase;
        border: 1px solid #8586cc;
        color: #8586cc;
        border-radius: 2px;
        line-height: 16px;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 3px 0 3px;
        margin-right: 8px;
      }

      .attachmentVersion {
        display: flex;
        align-items: center;
        margin: 0;
        color: #6b6c78;
        @extend %fontLight;

        span {
          margin-right: 10px;
        }
      }
    }

    .allAttachments {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #007eb5;
      @extend %fontRoundBold;
      margin-bottom: 0;
      padding-top: 3px;

      .scriptBoxCaller {
        position: relative;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .cardHeader,
    .cardContent {
      padding: 24px 16px;
    }
  }
}
