@import "../../../../styles/base.scss";

.loadingConflicts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 25;
  padding: 32px;
  animation: fadeIn 150ms linear;

  .loadingConflictsTitle {
    font-size: 24px;
    color: #24233d;
    @extend %fontRoundBold;
    margin-bottom: 20px;
  }

  .loadingConflictsMessage {
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
  }

  .buttonConfirm {
    margin-top: 35px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
