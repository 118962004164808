@import "../../../../styles/base";

.checkBox {
  display: flex;
  align-items: center;
  margin: 8px 0 12px 0;
  width: fit-content;
  cursor: pointer;

  &:first-of-type {
    margin-top: 12px;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 2px solid $midnightBlue20;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;

    .icon {
      display: none;
    }
  }

  .label {
    margin-left: 10px;
    font-size: 18px;
  }

  &.checked {
    .check {
      background-color: $secondaryColor;
      border-color: $secondaryColor;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
