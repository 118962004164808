@import "../../../../../styles/base.scss";

.versionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 16px 16px;
  background-color: #fff;

  .versionsHeader {
    display: flex;
    align-items: flex-start;
    padding: 8px 16px 8px 5px;
    color: $midnightBlue70;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    @extend %fontRoundBold;
    border-bottom: 1px solid $midnightBlue20;
    .versionMobile {
      display: none;
    }
    .version {
      display: flex;
      width: 35px;
    }

    .file {
      width: 292px;
    }

    .delivery {
      width: 136px;
      font-size: 10px;
      letter-spacing: 0.1px;
    }
  }

  .versionsList {
    .versionItem {
      display: flex;
      align-items: center;
      background-color: $midnightBlue0;
      border-bottom: 1px solid #fff;
      padding: 16px 16px 16px 10px;
      font-size: 14px;
      .versionInfo {
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 95.8%;
      }
      .version {
        display: flex;
        width: 30px;
        color: #9192a2;
      }
      .fileNameBlock {
        color: #9192a2;
        width: 272px;
        margin-right: 18px;
      }
      .file {
        display: flex;
        color: #9192a2;
        word-break: break-all;
        font-size: 14px;
      }
      .reason {
        display: flex;
        font-size: 13px;
        color: #333333;
        font-weight: bold;
        margin-top: 8px;
      }

      .delivery {
        font-size: 14px;
        width: 136px;
        letter-spacing: 0.1px;
      }

      .delivery {
        .datePlaceholder {
          display: flex;
          padding-left: 25px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: -70px;
        & > svg,
        & > div,
        & > span {
          margin-left: 8px;
          cursor: pointer;
        }
        .details {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0 10px 16px 10px;

    .versionsHeader {
      padding: 8px 0;

      .version {
        min-width: 110px;
      }

      .file {
        display: none;
      }

      .version,
      .delivery {
        padding: 0;
        max-width: 33%;
        width: 33%;
        margin: 0;
        font-size: 11px;
        flex-grow: 1;
      }
    }

    .versionsList {
      .versionItem {
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        padding: 16px 0 16px 0;

        .version {
          order: 0;
          margin-right: 0;
          max-width: 110px;
          max-width: 33%;
          margin: 0;
        }

        .version,
        .delivery {
          min-width: calc(33% - 22px);
          max-width: calc(33% - 22px);
          margin: 0;
          font-size: 12px;
        }

        .file {
          order: 4;
          max-width: 55%;
          flex-basis: 50%;
          margin-top: 16px;
          padding-left: 12px;
          bottom: 16px;
          left: 16px;
        }

        .actions {
          order: 5;
          max-width: 50%;
          flex-basis: 130px;
          justify-content: flex-end;
          margin-top: 16px;
          bottom: 16px;
          right: 8px;
          margin-left: auto;
          padding-right: 6px;
          align-self: flex-end;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .versionsHeader {
      display: flex;
      align-items: flex-start;
      padding: 8px 16px 8px 0px;
      color: $midnightBlue70;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      @extend %fontRoundBold;
      border-bottom: 1px solid $midnightBlue20;
      .version {
        display: none;
      }
      .versionMobile {
        display: flex;
        width: 30px;
        color: #9192a2;
      }
      .file {
        display: none;
      }

      .delivery {
        display: none;
      }
    }

    .versionsList {
      .versionItem {
        display: grid;
        grid-template-columns: repeat(2, 195px);
        grid-column-gap: 695px;
        @media screen and (max-width: 768px) {
          grid-column-gap: 440px;
        }
        @media screen and (max-width: 425px) {
          grid-column-gap: 130px;
        }
        @media screen and (max-width: 375px) {
          grid-column-gap: 80px;
        }
        @media screen and (max-width: 320px) {
          grid-column-gap: 30px;
        }
        background-color: $midnightBlue0;
        border-bottom: 1px solid #fff;
        font-size: 14px;

        .versionInfo {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin-left: 5%;
          width: 900px;
          @media screen and (max-width: 768px) {
            width: 628px;
          }
          @media screen and (max-width: 425px) {
            width: 350px;
          }
          @media screen and (max-width: 375px) {
            width: 300px;
          }
          @media screen and (max-width: 320px) {
            width: 250px;
          }
        }

        .version {
          display: flex;
          width: 100%;
          color: #9192a2;
          margin-left: 10px;
        }

        .fileNameBlock {
          color: #9192a2;
          width: 100%;
          margin-left: -15px;
          margin-right: 10px;
        }

        .file {
          display: flex;
          color: #9192a2;
          word-break: break-all;
          width: 100%;
          font-size: 14px;
        }

        .reason {
          display: flex;
          color: #333333;
          width: 100%;
          font-size: 13px;
          font-weight: bold;
        }

        .delivery {
          display: none;
        }

        .delivery {
          .datePlaceholder {
            display: flex;
            padding-left: 25px;
          }
        }

        .actions {
          display: flex;
          margin-left: -20px;
          align-self: center;
          align-items: center;
          & > svg,
          & > div,
          & > span {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .versionsHeader {
      .version,
      .delivery {
        font-size: 10px;
      }
    }

    .versionsList {
      .versionItem {
        .delivery {
          font-size: 11px;
        }
      }
    }
  }
}
