@import "../../../../../styles/base.scss";

#CommomParameters {
  padding: 24px 24px 6px 24px;
  border-top: 1px solid #c5c8f0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  #ContentCreateProspects, #ContentCreatorProductCompanies {
    width: 100%;
    margin-bottom: 25px;
    position: relative;

    label {
      margin-bottom: 8px;
      display: block;
      text-transform: uppercase;
      color: #36345a;
      letter-spacing: 0.1em;
      font-size: 12px;
      font-weight: bold;
    }

    .reactSelect {
      width: 100%;
    
      .reactSelect__control {
        min-height: 42px;
    
        .reactSelect__value-container {
          min-height: 42px;
    
          .reactSelect__placeholder {
            font-size: 14px;
          }
    
          .reactSelect__multi-value {
            min-height: 32px;
          }
        }
      }
    }
  }

  .parameter {
    display: flex;
    width: calc(33% - 10px);
    min-width: 220px;

    &.small {
      width: 33%;
    }

    &.medium {
      width: 66%;
    }

    &.large {
      width: 100%;
    }

    .fieldset {
      width: 100%;
      margin-top: auto;

      .inputContainer {
        height: 46px;

        input {
          height: 46px;
          font-size: 14px;
        }
      }

      .selectArea {
        height: 46px;
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .customDatepicker {
      width: 100%;
      margin-bottom: 25px;

      .inputBackground {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            input {
              height: 46px;
              padding: 8px 55px 5px 12px;
              font-size: 14px;

              &::placeholder {
                color: $placeholderColor;
              }
            }
          }
        }

        svg {
          right: 12px;
          top: 32px;
        }

        .react-datepicker__tab-loop {
          .react-datepicker-popper {
            transform: translate3d(0px, 68px, 0px) !important;
          }
        }
      }

      .withPortalVersion {
        div {
          .react-datepicker__input-container {
            input {
              height: 46px;
              padding: 8px 55px 5px 12px;
              font-size: 14px;

              &::placeholder {
                color: $placeholderColor;
              }
            }
          }
        }

        svg {
          right: 12px;
          top: 32px;
        }
      }
    }

    #CreatedBy,
    #ProductionCompaniesField {
      width: 100%;
      margin-bottom: 25px;
    }

    #CreatedBy {
      .newSelectFieldset {
        .newSelectInputContainer {
          .customValuesContainer {
            .valueLabel {
              min-height: 46px;

              .contentImage {
                min-width: 50px;

                .roleIcon,
                img {
                  min-width: 30px;
                  max-width: 30px;
                  min-height: 30px;
                  max-height: 30px;
                }
              }

              .label {
                .labelName {
                  font-size: 14px;
                }

                .labelRole {
                  font-size: 12px;
                }
              }
            }

            .newSelectInput {
              font-size: 14px;
              height: 28px;
            }
          }
        }

        .newSelectMenu {
          z-index: 10;
        }
      }
    }

    #ProductionCompaniesField {
      .newSimpleSelectFieldset {
        .newSelectInputContainer {
          .customValuesContainer {
            .customValuesContainer {
              .valueLabel {
                min-height: 26px;
              }
            }

            .newSimpleSelectInput {
              font-size: 14px;
            }
          }
        }

        .newSelectMenu {
          position: relative;
          bottom: 3px;
        }
      }
    }

    &:last-of-type {
      .fieldset {
        margin-bottom: 0;
      }
    }
  }

  #smallLoading {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .parameter {
      display: flex;
      width: calc(50% - 10px);
      min-width: 220px;

      &.small {
        width: calc(50% - 10px);
      }

      &.medium {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .parameter {
      display: flex;
      width: 100%;

      &.small {
        width: 100%;
      }
    }
  }
}
