.contentReleaseTextInfoVersion {
  .switch {
    transform: scale(0.8);
  }

  .switchLabel {
    margin-left: 5px;
  }

  .switchBlock {
    .switchContainer {
      .switchLabel {
        margin-left: 5px;
      }
    }
  }
}