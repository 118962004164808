@import "../../../../../styles/base.scss";

.datesHeader {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  min-width: 350px;
  max-width: 350px;
  color: $midnightBlue90;

  .dateType {
    display: flex;
    font-size: 12px;
  }

  .datesNames {
    display: flex;

    .dateName {
      display: flex;
      padding: 8px 16px;
      width: 33%;
      font-size: 11px;

      .dateValue {
        width: 85px;
        text-align: center;
      }
    }
  }

  .headerItem {
    padding: 8px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }

  .cell {
    margin-right: 16px;
  }

  .mainCell {
    flex-grow: 1;
    flex-basis: 110px;
    max-width: 200px;
  }

  @media screen and (max-width: 1200px) {
    min-width: unset;
    max-width: unset;
  }
}
