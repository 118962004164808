@import "../../../../styles/base.scss";

.activityEvaluation {
  position: relative;

  .savedAt {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #9395a3;
    margin: 24px auto 0 auto;

    svg {
      margin-right: 4px;
    }
  }

  .dotsSeparator {
    width: 45px;
    margin: 30px auto;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      background-color: #9395a3;
      width: 2px;
      height: 2px;
      border-radius: 50%;
    }
  }

  .fieldsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    padding: 0 64px 64px 64px;

    .simpleButton {
      height: 35px;
      margin: 5px;

      span {
        margin: 0 5px;
        padding-top: 1px;
        font-size: 15px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .buttonsContainer {
      padding: 0 24px 24px 24px;
    }
  }
}
