@import "../../../styles/base.scss";

.searchHeader {
  position: relative;
  max-width: 540px;
  min-width: 280px;
  width: 85vw;
  height: 48px;
  margin: 0 15px;

  form {
    height: 100%;

    input {
      background-color: rgba(170, 190, 231, 0.16);
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: none;
      padding: 16px 64px 16px 18px;
      @extend %fontRegular;
      outline: none;
      color: #c4c5cd;
      font-size: 18px;
      line-height: 24px;

      &:focus {
        border-color: #17b4e1;
        box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
        background-color: #fff;
        color: #1f1f1f;

        &::placeholder {
          color: #8e8fcf;
        }
      }

      &::placeholder {
        color: #c4c5cd;
        font-size: 18px;
      }
    }

    button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0px;
      top: 0;
      height: 100%;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      width: 60px;

      .searchIcon {
        width: 24px;
        height: 24px;
      }

      span {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
