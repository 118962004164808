@import "../../../styles/base.scss";

.relatedContentCard {
  width: 166px;
  height: 295px;
  margin-right: 16px;
  margin-bottom: 25px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px;
  background-color: #ffdee4;
  position: relative;

  .relatedCardHeader {
    min-height: 4px;
    max-height: fit-content;
    background-color: $confirmationWarning;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    top: 0;
    width: 100%;

    .relatedCardTitle {
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.1em;
      font-weight: bold;
      padding: 5px 0 2px 0;
    }
  }

  .relatedCardInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 20px;

    .relatedLink {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .typeIconReturner {
      margin-right: 0;
    }

    .relatedProductName {
      @extend %fontRoundBold;
      padding: 0 16px;
      text-align: center;
      margin-bottom: 8px;
    }

    .relatedProductCreators {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .relatedProductDetails {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      padding: 0 24px 24px;
      font-size: 14px;
      line-height: 20px;

      .details {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: 129px;
    height: fit-content;
    margin-right: 0;
  }
}
