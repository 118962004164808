@import "../../../../styles/base";

#ActivityNotification {
  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .count {
      width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btnAction {
      flex-basis: calc(33% - 10px);
      min-height: 60px;
      max-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $midnightBlue40;
      color: $cyan50;
      font-weight: bold;
      border-radius: 4px;
      text-decoration: none;
      padding: 8px 16px;
      margin-bottom: 24px;
      flex: 1;
      margin-right: 15px;

      span {
        margin-left: 8px;
        width: fit-content;
        text-align: center;
      }

      div {
        font-size: 30px;
        color: #575863;
        margin-left: 16px;
        font-weight: bold;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
