@import "../../../styles/base.scss";

.creatableFieldset {
  display: flex;
  flex-wrap: wrap;
  border: none;
  padding: 0;
  margin-bottom: 25px;

  label {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }
}
