@import "../../../styles/base";

#Proponents {
  background-color: #e0e2ff;
  min-height: calc(100vh - 164px);

  .listContainer {
    padding: 0 16px;
  }

  @media screen and (max-width: 640px) {
    .listContainer {
      padding: 0;
    }
  }
}
