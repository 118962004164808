.filterProjectsContainer {
  display: flex;
  align-items: center;
  width: 100%;

  .simpleButton {
    min-width: 200px;
    height: 50px;
    margin-left: 10px;
    z-index: 0;
    align-self: baseline;

    svg {
      margin-left: 12px;
    }

    span {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .simpleButton {
      width: 100%;
      justify-content: center;
      margin: 10px 0 0 0;
    }
  }
}
