@import "../../../styles/base.scss";

.thesaurusScope {
  position: relative;

  .SimpleScriptBox {
    z-index: 30;
  }

  .loadingCircleContainer {
    height: 100%;
    padding: 100px 0;

    .loadingCircle {
      margin: 0 auto;
    }
  }

  .thesaurusSelect {
    width: 100%;

    .reactSelect {
      .reactSelect__control {
        .reactSelect__value-container {
          padding-right: 55px;
        }
      }
    }
  }

  .scopeContent {
    background-color: #fff;
    height: fit-content;
    min-height: 100%;
  }

  @media screen and (max-width: 1024px) {
    .loadingCircleContainer {
      padding: 35vh 0;
    }
  }
}
