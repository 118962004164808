@import "../../../styles/base.scss";

.noResultsFiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-color: #fff;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 32px;
  margin-top: 0;
  position: relative;
  z-index: 0;

  p {
    color: #43444e;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .simpleButton {
    margin-top: 25px;
    max-width: 265px;
    width: 100%;
    text-align: center;

    svg {
      min-width: 18px;
    }
  }
}
