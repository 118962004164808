@import "../../../styles/base.scss";

.avatarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  background-color: #98becd;
  border-radius: 50%;

  .avatarLetter {
    color: #fff;
    margin-left: 0;
    margin-top: 3px;
  }
}
