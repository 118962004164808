@import "../../../../styles/base.scss";

.EditFileContainer {
  display: flex;
  flex-direction: column;
  min-height: 520px;
  height: 45vh;
  flex-wrap: wrap;
  padding: 24px;

  .fileInfo {
    display: flex;
    justify-content: space-between;
    padding: 0;

    .fieldset {
      width: 40%;
    }

    .customDatepicker {
      width: 28%;
    }

    .numberInput {
      margin-left: 0;
      width: 20%;
    }
  }

  .chapter {
    flex-basis: 55%;
    display: flex;
    padding: 0;

    .numberInput {
      flex-basis: 35%;
      margin-left: 0;
      min-width: 200px;
    }

    .fieldset {
      margin-top: 0;
      margin-left: 15px;
      flex: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .fileInfo {
      flex-direction: column;

      .fieldset {
        width: 100%;
        order: 0;
        margin-bottom: 0;
      }

      .numberInput {
        align-self: flex-start;
        order: 2;
        margin: 0;
        width: 200px;
      }

      .customDatepicker {
        order: 1;
        margin: 25px 0;
        width: 100%;
      }
    }

    .chapter {
      flex-direction: column;
      margin-top: 25px;

      .numberInput {
        margin-bottom: 25px;
      }

      .fieldset {
        margin-left: 0;
      }
    }
  }
}
