@import "../../../styles/base.scss";

#Research {
  #Breadcrumb {
    padding: 0 25px 0 20px;
    position: relative;

    .container {
      height: 100%;

      .registerContainer {
        min-width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .backButtonContainer {
          display: flex;
          margin-right: 20px;
        }

        .infoContainer {
          height: 100%;
          display: flex;
        }

        .registerTitle {
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex: 1;

          h2 {
            margin-bottom: 4px;
          }

          & > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-right: 12px;

            span {
              display: block;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 1px;
              color: #adafef;
              text-transform: uppercase;
            }

            .separator {
              width: 4px;
              height: 4px;
              background-color: #fff;
              margin: 0 6px 4px;
              border-radius: 50%;
            }
          }
        }

        .stepCounter {
          display: flex;
          align-items: center;
          color: #fff;

          .stepIcon {
            display: flex;
            align-items: center;
            transform: rotate(180deg);
          }

          p {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .researchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 696px;
    width: 100%;
    margin: 0 auto;

    .researchForm {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 50px;
      padding: 0 16px;

      .formField {
        display: flex;
        width: 100%;
        margin-bottom: 25px;

        &.types {
          max-width: 522px;

          .switchTextContainer {
            .switchText {
              .switchOption {
                width: 33%;
              }
            }
          }
        }

        .fieldset {
          width: 100%;
          margin-bottom: 0;
        }

        &.buttonField {
          justify-content: flex-end;
          position: relative;
          z-index: 0;

          .button {
            margin-left: 25px;

            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
      }

      .formFieldColumn {
        flex-direction: column;
        margin-bottom: 25px;
        width: 100%;

        .formField {
          width: 100%;

          .AssociatedProject {
            width: 100%;
            margin-top: 15px;

            .formField {
              margin-bottom: 0;
            }
          }

          .contentOriginContainer {
            display: flex;
            width: 100%;
            min-height: 60px;
            background-color: #fff;
            margin-bottom: 0;
            border-radius: 4px;
            position: relative;

            .fieldset {
              margin-bottom: 0;
              width: 350px;
              position: relative;

              .inputContainer {
                height: 64px;
                position: relative;
                z-index: 0;

                select {
                  min-height: 64px;
                }
              }

              &::after {
                content: "";
                display: block;
                width: 1px;
                height: 80%;
                background-color: #aabee7;
                right: 0;
                bottom: 10%;
                position: absolute;
                opacity: 0.3;
                z-index: 0;
              }
            }

            &.showDeleteIcon {
              padding-right: 36px;

              .deleteIcon {
                height: 100%;
                width: 36px;
                background-color: #fff1f2;
                position: absolute;
                right: 0;
                top: 0;
                // z-index: 16;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                cursor: pointer;

                &:hover {
                  background-color: #e5c2c4;
                }
              }
            }
          }
        }

        .dashedBox {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px dashed rgba(170, 190, 231, 0.5);
          height: 44px;
          margin: 0 0 35px 0;

          span {
            display: block;
            text-align: center;
            font-size: 14px;
            color: #38a86e;
            @extend %fontRoundBold;
            cursor: pointer;
          }
        }

        .fieldLabel {
          color: $terciaryColor;
          @extend %fontRegular;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          display: block;
          margin-bottom: 8px;
        }
      }
    }

    .uploadSection {
      .fileInfo {
        .fieldset {
          margin-bottom: 0;
        }

        .customDatepicker {
          min-width: 185px;
        }

        .numberInput {
          margin-left: 0;
          max-width: 135px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    #Breadcrumb {
      height: fit-content;

      .container {
        .registerContainer {
          .infoContainer {
            flex-direction: column;
          }

          .backButtonContainer {
            margin-right: 10px;
          }

          .stepCounter {
            position: absolute;
            right: 16px;
            bottom: unset;
            top: 38px;
          }

          .registerTitle {
            padding: 16px 0 24px 0;

            & > div {
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .researchContainer {
      .researchForm {
        .formFieldColumn {
          .formField {
            .associateFieldset {
              height: 130px;
            }

            .contentOriginContainer {
              flex-direction: column;
              min-height: 120px;
              justify-content: flex-end;

              .fieldset {
                min-width: 100%;
                width: 100%;

                &::after {
                  right: auto;
                  bottom: 0;
                  height: 1px;
                  width: 95%;
                  left: 2.5%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .researchContainer {
      .researchForm {
        .formField {
          &.buttonField {
            justify-content: flex-end;
            flex-direction: column;

            .button {
              margin-left: 0;
              margin-bottom: 25px;

              &:nth-child(1) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

.editSelect__menu {
  z-index: 5;
}
