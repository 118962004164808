@import "../../../../styles/base.scss";

#BlocksOrChaptersTable {
  width: 100%;
}

@media screen and (max-width: 1024px){
  .notMobile{
    display: none;
  }
}

