@import "./reset.scss";
@import "./print.scss";
@import "./reactSelect.scss";

$primaryColor: #24233d;
$secondaryColor: #17b4e1;
$terciaryColor: #36345a;
$placeholderColor: #8586cc;
$successColor: #1e9f69;
$errorColor: #e00038;
$confirmationWarning: #ec1045;
$alertColorPrimary: #eeb65f;
$alertColorSecondary: #fff4e3;
$alertTextColor: #996b1b;

$midnightBlue0: #e2e4ff;
$midnightBlue5: #daddfe;
$midnightBlue10: #d2d5fd;
$midnightBlue20: #c0c3f8;
$midnightBlue30: #adafef;
$midnightBlue40: #999be0;
$midnightBlue50: #8586cc;
$midnightBlue60: #7070b3;
$midnightBlue70: #5c5b97;
$midnightBlue80: #484879;
$midnightBlue90: #36355a;
$midnightBlue100: #24233d;

$cyan0: #e4fbff;
$cyan5: #c7f6fe;
$cyan10: #aaf0fd;
$cyan20: #71e1f8;
$cyan30: #3ecdef;
$cyan40: #17b4e1;
$cyan50: #0098cd;
$cyan60: #007eb5;
$cyan70: #006199;
$cyan80: #00467c;
$cyan90: #002f5f;
$cyan100: #001d42;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  @extend %fontRegular;
  background-color: #e0e2ff;
}

#root {
  height: 100%;
}

.container {
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 1120px) {
    width: 90%;
  }
}

.containerFlex {
  display: flex;
  justify-content: center;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Globotipo Text";
  src: url("../assets/fonts/GloboRegular.ttf");
}

%fontRegular {
  font-family: "Globotipo Text", sans-serif;
}

@font-face {
  font-family: "Globotipo Text Light";
  src: url("../assets/fonts/GlobotipoTexto-Light.ttf");
}

%fontLight {
  font-family: "Globotipo Text Light", sans-serif;
}

@font-face {
  font-family: "Globotipo Text Bold";
  src: url("../assets/fonts/GlobotipoRounded-Bold.ttf");
}

%fontRoundBold {
  font-family: "Globotipo Text Bold", sans-serif;
}

.button {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  align-content: space-between;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  border-width: 0;
  border-radius: 4px;
  overflow: hidden;
  transition: border-radius 0.2s ease 0s;
  padding: 8px 16px;
  @extend %fontRoundBold;

  &.buttonGreen {
    background-color: #009c69;

    &:hover {
      background-color: #00885f;
    }
  }

  &.buttonRed {
    background: #ec1045;
  }

  &.buttonGray {
    background: gray;
  }

  &.buttonBlue {
    background: #17b4e1;

    &:hover {
      background: #3d99ce;
    }
  }

  &.buttonConfirm {
    background: #ec1045;
  }

  &.buttonCancel {
    background: #fff;
    border: 1px solid #c0c3f8;
    color: #7f808e;
  }

  &.buttonCancelNormal {
    border: 1px solid #c0c3f8;
    color: #7f808e;
  }

  &.small {
    padding: 2px 12px !important;
    font-size: 10.5pt;
  }

  &.large {
    padding: 12px 32px;
  }

  &.extraLarge {
    padding: 18px 24px;
    font-size: 18px;

    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }

  &.largeRound {
    padding: 18px 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
  }

  &.borderedButton {
    background-color: #fff;
    border: 1px solid #daddfe;
    border-radius: 34px;
    color: $cyan40;
    @extend %fontRegular;
    padding: 8px;
    transition: 250ms ease-in-out;

    &:hover {
      background-color: $cyan40;
      border-color: $cyan40;
      color: #fff;
    }
  }

  &.borderedButtonDark {
    background-color: transparent;
    border: 1px solid $midnightBlue60;
    border-radius: 34px;
    color: $midnightBlue60;
    @extend %fontRegular;
    padding: 8px;
    transition: 250ms ease-in-out;

    &:hover {
      background-color: $midnightBlue60;
      color: #fff;
    }
  }
}

.errorMessage {
  display: block;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.1em;
  margin-top: 8px;
  color: $errorColor;
  padding-left: 2px;
  font-weight: 500;
}

input {
  caret-color: $cyan50;
}

select {
  cursor: pointer;
}

#portal {
  > div {
    z-index: 9999999999 !important;
  }
}
