@import "../../../../styles/base.scss";

.ProjectReleasedText {
  padding-bottom: 100px;

  .container {
    margin-top: 50px;
  }

  #Breadcrumb {
    .breadcrumbContent {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 24px 0 0 0;

      .breadcrumbHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .headerProjectReleasedText {
    a {
      color: $cyan40;
      text-decoration: none;
      @extend %fontRoundBold;
      margin-left: 16px;
      white-space: nowrap;
    }
  }

  .attachmentsHeader {
    display: flex;
    align-items: center;
    height: 34px;
    background-color: transparent;
    margin-bottom: 1px;

    li {
      color: #43444e;
      font-size: 12px;
      @extend %fontRoundBold;
      text-transform: uppercase;
    }

    .version {
      width: 125px;
      margin-right: 130px;
      text-align: center;
    }

    .fileComment {
      padding: 0 20px;
    }

    .receivedAt {
      margin-left: 90px;
      width: 80px;
    }
  }

  @media screen and (max-width: 1024px) {
    .headerProjectReleasedText {
      a {
        .goBackResponsiveText {
          display: none;
        }
      }
    }
  }
}
