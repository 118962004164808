.valueLabelContainer {
  .valueLabel {
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 4px;
    padding: 0 10px 0 0;

    .contentImage {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;

      .valueLabelImage,
      img {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        margin: 0 10px 3px 0;
        border-radius: 50%;
      }
    }

    .roleIcon {
      margin: 0 10px 3px 0;
    }

    .removeIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #aaf0fd;
      order: 0;
      width: 26px;
      height: 100%;
      color: #17b4e1;
      cursor: pointer;

      &:hover {
        background-color: #d2eefd;
      }
    }

    .label {
      display: flex;
      flex-direction: column;

      .labelName,
      .labelRole {
        text-transform: uppercase;
      }

      .labelName {
        font-size: 16px;
        height: 60%;
      }

      .labelRole {
        font-size: 13px;
        color: #7f808e;
        line-height: 13px;
      }
    }
  }
}
