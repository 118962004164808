@import '../../../../styles/base.scss';

.releasedFilterFields {
  background: #d2d5fd;
  padding: 32px 0 16px 0;

  .container {

    h2 {
      @extend %fontLight;
      font-weight: 300;
      font-size: 28px;
      color: #262626;
      margin: 0 0 25px 0;
    }

    .fields {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
      align-items: flex-end;

      fieldset {
        flex: 1;
        flex-basis: 300px;

        .inputContainer {
          height: 50px;

          input,
          select {
            height: 50px;
            padding: 2px 14px;
          }
        }
      }

      .simpleButton {
        height: 50px;
        margin-bottom: 25px;
        padding: 0 56px;
        justify-content: center;
        flex: 1;
        flex-basis: 175px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 32px 16px 16px 16px;
  }
}
