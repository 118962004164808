@import "../../../../styles/base.scss";

.titleHistory {
  padding-bottom: 12px;

  .historyHeader {
    display: flex;
    align-items: center;
    padding: 10px 24px;

    .title,
    .updatedBy {
      width: 42%;
      font-size: 12px;
      text-transform: uppercase;
      @extend %fontRoundBold;
      color: #7f808e;
    }

    .updatedAt {
      min-width: 95px;
    }
  }

  @media screen and (max-width: 768px) {
    .historyHeader {
      .title {
        width: 75%;
      }

      .updatedBy {
        display: none;
      }

      .updatedAt {
        width: 25%;
        text-align: right;
        margin-right: 0;
        display: flex;
        justify-content: flex-end;

        .reorderButton {
          margin-right: 0;
        }
      }
    }
  }
}
