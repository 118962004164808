.navItem {
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  user-select: none;
  padding: 0 10px;

  &.avatarName {
    color: #fff;
  }

  & > span {
    display: flex;
    align-items: center;
    color: #fff;
    height: 100%;
    margin-left: 10px;
    padding-top: 4px;
  }
}
