@import "../../../styles/base.scss";

.noActivityAttachments {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;

  .noActivityTitle {
    margin: 32px 0;
    font-size: 28px;
    @extend %fontLight;
  }

  .formatFilter {
    margin-bottom: 35px;

    .label {
      color: #575863;
      @extend %fontRoundBold;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 8px;
      display: block;
    }

    .simpleButton {
      margin: 4px 4px 4px 0;

      span {
        padding-top: 2px;
      }
    }
  }

  .listCounters {
    display: flex;

    .itemsCounter {
      width: fit-content;
      margin: 0;
    }

    .counterSeparator {
      color: #7f808e;
      margin: 0 4px;
    }
  }

  .contentItem {
    .header {
      margin-top: 15px;

      .details {
        & > span {
          display: flex;
          align-items: center;
          color: $cyan50;

          svg {
            margin: 0 0 5px 5px;
          }
        }
      }
    }
  }

  .noAttachmentsFound {
    margin: 85px auto;
    text-align: center;
    padding: 0 24px;
    font-size: 24px;
    max-width: 500px;
    @extend %fontLight;
    line-height: 36px;
  }

  @media screen and (max-width: 1120px) {
    .noActivityAttachments {
      .noActivityTitle,
      .formatFilter,
      .listCounters {
        padding: 0 16px;
      }
    }
  }

  @media screen and (max-width: 920px) {
    .noActivityAttachments {
      .contentItem {
        .header {
          .reorderButton {
            margin-left: auto;
          }

          & > .title {
            max-width: 30%;
            margin-right: auto;
          }

          .details {
            display: flex;
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .noActivityAttachments {
      .listCounters {
        .itemsCounter {
          padding: 0;
        }
      }
    }
  }
}
