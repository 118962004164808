@import "../../../styles/base";

#Breadcrumb {
  min-height: 100px;
  width: 100%;
  background: $terciaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  .container {
    width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    h2 {
      font-size: 28px;
      display: flex;
      align-items: center;
      color: #fff;
      @extend %fontRoundBold;
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      h2 {
        font-size: 20px;
      }
    }
  }
}
