@import "../../../styles/base.scss";

.addMore {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgba(170, 190, 231, 0.5);
  height: 44px;
  margin: 30px 0 25px 0;

  .text {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #38a86e;
    @extend %fontRoundBold;
    cursor: pointer;
  }
}
