@import "../../../styles/base";

#ViewContainer {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .ScriptBox {
    .windowScriptBox {
      .downloadAction {
        cursor: pointer;
      }
      .content {
        padding: 0;
        .ViewWrapper {
          height: 76vh;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  & > div {
    .close-file-view {
      font-size: 2rem;
      position: absolute;
      top: 40px;
      z-index: 9999999;
      left: 40px;
      cursor: pointer;
      font-weight: bold;
      color: #17b4e1;
    }
  }

  & > iframe {
    border: none;
    width: 50%;
    height: 80%;
    overflow: hidden;

    body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
