@import "../../../../styles/base";

#FilterListStatusActivities {
  display: flex;
  height: fit-content;
  margin-top: 6px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  .formItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 10px 0 0;

    & > label {
      margin-bottom: 10px;
    }

    & > button {
      width: 60px;
      height: 35px;
    }

    .fieldset {
      margin-bottom: 0;
    }

    .simpleButton {
      height: 46px;
    }

    .formField {
      width: 100%;

      .fieldset {
        .inputContainer {
          height: 46px;

          select {
            height: 100%;
            padding: 0 40px 0 12px;
            font-size: 14px;
          }

          .selectArea {
            background-position: right 8px top 50%, 0 0;
          }
        }
      }
    }

    .customDatepicker {
      width: 100%;

      .inputBackground {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            input {
              height: 46px;
              padding: 8px 55px 5px 12px;
              font-size: 14px;

              &::placeholder {
                color: $placeholderColor;
              }
            }
          }
        }

        svg {
          right: 12px;
          top: 32px;
        }

        .react-datepicker__tab-loop {
          .react-datepicker-popper {
            transform: translate3d(0px, 68px, 0px) !important;
          }
        }
      }

      .withPortalVersion {
        div {
          .react-datepicker__input-container {
            input {
              height: 46px;
              padding: 8px 55px 5px 12px;
              font-size: 14px;

              &::placeholder {
                color: $placeholderColor;
              }
            }
          }
        }

        svg {
          right: 12px;
          top: 32px;
        }
      }
    }

    &.large {
      max-width: 272px;
      min-width: 272px;
    }

    &.medium {
      max-width: 198px;
      min-width: 198px;
    }

    &.small {
      max-width: 148px;
      min-width: 148px;
    }

    &.smaller {
      max-width: fit-content;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1120px) {
    .formItem {
      &.large {
        max-width: calc(50% - 6px);
        min-width: calc(50% - 6px);
        margin-right: 0;
      }

      &.medium {
        max-width: calc(25% - 10px);
        min-width: calc(25% - 10px);
        margin-right: 0;
        margin-left: 12px;
      }

      &.small {
        max-width: calc(25% - 10px);
        min-width: calc(25% - 10px);
      }

      &.smaller {
        max-width: calc(50% - 6px);
        min-width: calc(50% - 6px);

        .simpleButton {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    .formItem {
      &.large {
        max-width: 100%;
        min-width: 100%;
        margin-right: 0;
      }

      &.medium {
        max-width: calc(50% - 6px);
        min-width: calc(50% - 6px);
        margin-right: 0;
        margin-left: 0;
      }

      &.small {
        max-width: calc(50% - 6px);
        min-width: calc(50% - 6px);
        margin-right: 0;
      }

      &.smaller {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .formItem {
      &.medium,
      &.small {
        max-width: 100%;
        min-width: 100%;
      }

      &.smaller {
        .simpleButton {
          margin-top: 16px;
        }
      }
    }

    .customDatepicker {
      margin-bottom: 0;
    }
  }
}
