@import "../../../../styles/base.scss";

.infoPaper {
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px;
  border-radius: 4px;

  .paper {
    .paperTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 32px;

      span {
        display: flex;
        line-height: 28px;
        font-size: 20px;
        color: #1e1f24;
      }

      .scriptBoxCaller {
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .infoFieldContainer {
      padding: 12px 32px 0 32px;
      border-bottom: 1px solid #aabee754;

      .infoField {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        position: relative;

        .noInfo {
          color: #7f808e;
        }

        .infoName {
          color: #7f808e;
          text-transform: uppercase;
          line-height: 22px;
          font-size: 12px;
          min-width: 30%;
          letter-spacing: 0.1em;
          display: flex;
          @extend %fontRoundBold;
          margin-right: 20px;
        }

        .infoDataList {
          .infoData {
            padding-right: 0;

            svg {
              margin-left: 6px;
              margin-right: 6px;
            }

            .contentData {
              display: flex;
              flex-direction: column;
              margin-bottom: 15px;

              .contentOriginName {
                color: #7f808e;
                text-transform: uppercase;
                font-weight: 300;
                font-size: 14px;
                margin-bottom: 5px;
              }

              .descriptions {
                display: flex;
                flex-wrap: wrap;

                .descriptionName {
                  .originSeparator {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }

        .infoData {
          line-height: 22px;
          padding-right: 48px;
          text-align: justify;
        }

        .projectTitle {
          display: flex;
          flex-wrap: wrap;

          span {
            text-align: left;
            padding-right: 16px;
            margin-bottom: 3px;
            white-space: pre;
          }

          .scriptBoxCaller {
            width: fit-content;
            height: 100%;
            display: flex;
            align-items: center;
            text-decoration: underline;
            @extend %fontRegular;
            font-weight: 400;
            position: relative;
            top: 0;
            text-align: left;
            line-height: 20px;
          }
        }

        .clientsList {
          .clientItem {
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .paper {
      .infoFieldContainer {
        .infoField {
          .infoName {
            min-width: 40%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .paper {
      .paperTitle {
        padding: 24px 16px;
      }

      .infoFieldContainer {
        padding-left: 16px;
        padding-right: 16px;

        .infoField {
          .infoData {
            padding-right: 16px;
          }

          &.hasChildren {
            .infoData {
              flex-direction: column;
            }

            .scriptBoxCaller {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
