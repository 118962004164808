@import "../../../../styles/base.scss";

.downloadAllSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 32px 16px;
  margin-bottom: 32px;
  border-radius: 4px;

  .message {
    font-size: 22px;
    margin-bottom: 24px;
    text-align: center;
    @extend %fontLight;
  }
}
