@import "../../../styles/base.scss";

.thesaurusSelect {
  width: 100%;

  .reactSelect {
    min-height: 50px;

    .filterProjects__control {
      border: 2px solid #fff;
      border-radius: 4px;
      min-height: 50px;
      width: 100%;

      &:hover {
        border: 2px solid #fff;
      }

      .filterProjects__value-container {
        display: flex;
        height: 100%;

        .filterProjects__placeholder {
          font-size: 18px;
          color: #8586cc;
        }

        .filterProjects__input {
          font-size: 18px;
        }

        .filterProjects__single-value {
          font-size: 18px;
        }

        .filterProjects__multi-value {
          display: flex;
          flex-direction: row-reverse;
          margin: 4px;
          min-height: 38px;
          background-color: #e4f8ff;
          border: 1px solid #aaf0fd;
          border-radius: 4px;

          .filterProjects__multi-value__remove {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 26px;
            max-width: 26px;
            color: #17b4e1;
            border-right: 1px solid #aaf0fd;
            cursor: pointer;

            &:hover {
              background-color: #d0ecfd;
            }
          }

          .filterProjects__multi-value__label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            flex-wrap: wrap;
            max-width: 100%;
            padding: 6px 10px 4px 10px;
            white-space: normal;
          }
        }
      }

      .filterProjects__indicators {
        min-height: 100%;
        max-height: 100%;

        .filterProjects__indicator-separator,
        .filterProjects__dropdown-indicator {
          display: none;
        }

        .filterProjects__clear-indicator {
          cursor: pointer;
        }
      }
    }

    .filterProjects__control--is-focused {
      border-color: #17b4e1;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);

      &:hover {
        border-color: #17b4e1;
      }
    }

    .filterProjects__menu {
      top: calc(100% - 11px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none;
      border: 2px solid #17b4e1;
      border-top-width: 1px;
      border-top-color: #d4d5e2;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
      max-height: 250px;

      .filterProjects__menu-list {
        padding: 0;
        max-height: 247px;

        .filterProjects__option {
          font-size: 18px;
          padding: 14px 12px;
          cursor: pointer;

          &:hover {
            background-color: #e4f8ff;
          }

          &:last-of-type {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        .filterProjects__option--is-focused {
          background-color: #e4f8ff;
          color: #000;
        }

        .filterProjects__option--is-selected {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }

  .dropdownIndicator {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;

    &.isFocused {
      transform: rotate(180deg);
    }

    &.isLoading,
    &.hasValue {
      display: none;
    }
  }

  .optionItem {
    display: flex;
    padding: 6px 12px;
    min-height: 50px;

    .optionInfo {
      display: flex;
      align-items: center;
      padding-top: 2px;

      .contentName {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        .optionName {
          color: #43444e;
          padding-right: 16px;
          min-width: fit-content;
          font-size: 16px;
          margin-bottom: 4px;
        }

        .remissivesList {
          display: flex;
          flex-wrap: wrap;

          .remissiveItem {
            display: flex;
            align-items: center;
            background-color: $alertColorSecondary;
            border-radius: 18px;
            margin: 3px 16px 3px 0;

            .remissiveTag {
              text-transform: uppercase;
              color: #fff;
              background-color: #fdaf34;
              @extend %fontRoundBold;
              padding: 8px;
              border-radius: 18px;
            }

            .remissiveText {
              color: $alertTextColor;
              font-size: 16px;
              @extend %fontRoundBold;
              padding: 8px 12px;
            }
          }
        }
      }
    }

    &:hover {
      background-color: #d1f0f9;
      cursor: pointer;
    }

    &.isFocused {
      background-color: #d1f0f9;
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  .clearIndicator {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 100%;
    padding-right: 8px;
  }
}
