@import "../../../../styles/base.scss";

#RecomendationCard {
  width: 100%;
  background-color: #daddfe;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px;
  border-radius: 4px;
  margin-bottom: 36px;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
    border-bottom: 1px solid #c0c3f8;
    max-height: 77px;

    .cardTitle {
      display: flex;
      align-items: center;
      line-height: 28px;
      font-size: 20px;
      color: #1e1f24;
    }

    .scriptBoxCaller {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 5px;
    }
  }

  .cardContentContainer {
    .cardContent {
      padding: 24px 32px;
      display: flex;
      flex-direction: column;

      .recomendationType {
        color: $midnightBlue60;
        font-size: 16px;
        margin-bottom: 11px;
        @extend %fontRoundBold;
      }

      .justification {
        color: #575863;
        font-size: 14px;
        margin-bottom: 17px;
        line-height: normal;
      }

      .userInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 6px 0;

        .userAvatar {
          display: flex;
          align-items: center;

          .avatarIcon {
            margin-right: 10px;
            @extend %fontRoundBold;
          }

          .userName {
            font-size: 14px;
            @extend %fontRoundBold;
          }
        }

        .recomendationDate {
          color: #43444e;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .cardHeader {
      padding: 24px 16px;
    }

    .cardContentContainer {
      .cardContent {
        padding: 24px 16px;
      }
    }
  }
}
