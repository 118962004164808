@import "../../../styles/base.scss";

.switchBlock {
  height: fit-content;

  .blockLabel {
    color: $primaryColor;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }

  .switchContainer {
    display: flex;
    align-items: center;
    margin-top: 7px;

    .switch {
      width: 60px;
      height: 34px;
      border-radius: 34px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      transition: all 200ms ease-in;
      position: relative;
      cursor: pointer;

      .switchKey {
        width: 26px;
        height: 26px;
        background-color: #fff;
        box-shadow: rgba(31, 31, 31, 0.24) 0px 2px 4px;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        transition: all 250ms ease-out;
      }

      &.switchOn {
        background-color: #31c38e;

        .switchKey {
          transform: translateX(24px);
        }
      }
    }

    .switchLabel {
      margin-left: 12px;
      font-size: 14px;
    }
  }
}
