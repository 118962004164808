@import "../../../../../styles/base.scss";

.evaluationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  max-height: 64px;
  background-color: $midnightBlue100;
  padding-right: 20px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);

  .analistHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    color: #fff;

    .backButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 55px;
      cursor: pointer;
    }

    .aboutEvaluation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;

      .avatar {
        display: flex;
        align-items: center;
        margin-top: 3px;

        .avatarIcon {
          width: 24px;
          height: 24px;
          margin-right: 6px;

          .avatarLetter {
            font-size: 14px;
            @extend %fontRoundBold;
          }
        }

        & > span {
          color: $midnightBlue30;
          font-size: 14px;
          margin-top: 2px;
        }
      }
    }
  }

  .simpleButton {
    height: 40px;

    span {
      margin-top: 3px;
    }
  }

  @media screen and (max-width: 640px) {
    padding-right: 6px;

    .analistHeader {
      .backButton {
        width: 40px;
        margin-right: 5px;
      }
    }
  }

  @media screen and (max-width: 380px) {
    .simpleButton {
      max-width: 75px;
      padding: 0 4px;
    }
  }
}

.subHeader {
  background-color: $terciaryColor;
  height: 236px;
  width: 100%;
  position: absolute;
  top: 64px;
  z-index: 1;
}
