@import "../../../styles/base.scss";

.switchTextContainer {
  flex: 1;

  .switchLabel {
    display: block;
    color: $primaryColor;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .switchText {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 0 2px;
    min-height: 60px;
    align-items: center;
    max-width: 100%;
    width: fit-content;
    color: #7f808e;

    &.small {
      width: 50%;
      min-width: 333px;

      .switchOption {
        width: 100%;
        max-width: calc(50% - 15px);
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 288px;
      }
    }

    &.medium {
      width: 75%;

      .switchOption {
        width: calc(50% - 15px);
      }
    }

    .switchOption {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 158px;
      height: 46px;
      cursor: pointer;
      margin: 0 7px;
      border: 1px solid #fff;

      @media screen and (max-width: 340px) {
        font-size: 14px;
      }

      svg {
        display: none;
      }

      &:nth-child(odd) {
        &:before {
          content: "";
          width: 1px;
          height: 46px;
          background-color: #aabee74d;
          position: absolute;
          left: -9px;
        }
      }

      &:first-of-type {
        &:before {
          display: none;
        }

        &:after {
          content: "";
          width: 1px;
          height: 46px;
          background-color: #aabee74d;
          position: absolute;
          right: -8px;
        }
      }

      &:last-of-type {
        &:before {
          content: "";
          width: 1px;
          height: 46px;
          background-color: #aabee74d;
          position: absolute;
          left: -9px;
        }
      }

      &.selected {
        background-color: #e4f8ff;
        border: 1px solid #aaf0fd;
        border-radius: 4px;
        color: #000;

        svg {
          display: block;
          margin-right: 7px;
        }

        @media screen and (max-width: 340px) {
          svg {
            margin-right: 5px;
          }
        }
      }
    }

    &.large {
      width: 100%;

      .switchOption {
        width: calc(25% - 15px);
      }
    }

    &.large,
    &.small {
      @media screen and (max-width: 550px) {
        flex-direction: column;
        padding: 3px 10px;

        .switchOption {
          min-width: 100%;
          margin: 7px auto;
          font-size: 18px;

          &:after {
            content: "";
            width: calc(100% - 10px);
            height: 1px;
            background-color: #aabee74d;
            position: absolute;
            top: -8px;
            left: 4px;
          }

          &:nth-child(odd) {
            &:after {
              content: "";
              width: calc(100% - 10px);
              height: 1px;
              background-color: #aabee74d;
              position: absolute;
              bottom: 10px;
              left: 4px;
            }

            &:before {
              display: none;
            }
          }

          &:first-of-type {
            &:before {
              display: none;
            }

            &:after {
              // content: "";
              // width: 1px;
              // height: 46px;
              // background-color: #aabee74d;
              // position: absolute;
              // right: -8px;
              display: none;
            }
          }

          &:last-of-type {
            &:before {
              // content: "";
              // width: 1px;
              // height: 46px;
              // background-color: #aabee74d;
              // position: absolute;
              // left: -9px;
              display: none;
            }
          }
        }
      }
    }
  }
}
