@import "../../../../styles/base";

.containerFilter {
  width: 270px;
  height: fit-content;
  background: #ffffff;
  position: absolute;
  top: 22px;
  left: 0;
  z-index: 5;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 8px 10px, rgba(0, 0, 0, 0.12) 0 6px 30px,
    rgba(0, 0, 0, 0.14) 0 16px 24px;

  .blocksFormats {
    font-size: 14px;
    overflow-y: auto;
    max-height: 300px;

    .itemFormats,
    .title {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #daddfe;

      .checkBox {
        width: 100%;
        height: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0;
        padding: 0 20px;

        .label {
          font-size: 14px;
          @extend %fontRegular;
          text-transform: capitalize;
          margin: 0;
        }

        &:hover {
          background-color: $cyan0;
        }
      }

      &.loading {
        pointer-events: none;
      }
    }

    .title {
      background: #f5f5f5;
      padding: 0 20px;

      .label {
        color: $midnightBlue70;
        text-transform: uppercase;
        font-size: 14px;
        @extend %fontRoundBold;
      }
    }
  }

  .contentButtonFilter {
    width: 100%;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    .buttonGreen {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  @media screen and (max-width: 468px) {
    left: -90px;
  }
}

.details {
  position: relative;
  cursor: pointer;
}
