@import "../../../../../styles/base.scss";

.blocksTableHeader {
  display: flex;
  align-items: flex-end;
  color: $midnightBlue90;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0 16px;
  @extend %fontRoundBold;
  margin-right: auto;

  .headerItem {
    padding: 8px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }

  .cell {
    margin-right: 16px;
  }

  .mainCell {
    flex-grow: 1;
    flex-basis: 110px;
    max-width: 200px;
  }

  .blockNumber {
    width: 100px;
    justify-content: flex-start;
    padding-left: 24px;
  }

  .chaptersNumber {
    width: 100px;
  }

  .placeholder {
    flex-grow: 1;
    flex-basis: 125px;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}
