@import "../../../../styles/base";

.ThesaurusTypeNavigation {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 15px 25px;
  cursor: pointer;

  .iconType {
    width: 32px;

    .typeIconReturner {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  .title {
    color: $midnightBlue30;
    font-size: 18px;
    @extend %fontRoundBold;
  }

  &.active {
    border-bottom: 2px solid #ffffff;

    .title {
      color: #fff;
    }
  }
}
