@import "../../../../styles/base";

.contentItem {
  .header {
    display: flex;
    align-items: center;
    flex: 1;
    height: 32px;
    padding: 0 54px 0 16px;
    margin-top: 32px;

    li {
      font-size: 12px;
      color: #6b6c78;
      text-transform: uppercase;
      @extend %fontRoundBold;
    }

    .title {
      flex: 2;
      max-width: 305px;
    }

    .creator {
      flex: 2;
      max-width: 305px;
      padding-right: 16px;
    }

    .details {
      flex: 1;
      max-width: 152px;
    }

    .origin {
      flex: 1;
    }

    .lastAttachment {
      flex: 1;
    }
  }

  .listItem {
    .projectDetails {
      display: flex;
      background-color: #fff;
      padding: 0 24px 0 16px;
      margin-bottom: 1px;
      cursor: pointer;

      li {
        display: flex;
        align-items: center;

        .typeIconReturner {
          margin-right: 0;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        span {
          color: #43444e;
          font-size: 14px;
        }

        .projectTitle {
          @extend %fontRoundBold;
          font-size: 16px;
          padding-right: 16px;
        }

        &.creator {
          text-transform: uppercase;
          flex: 2;
          max-width: 305px;
          display: flex;
          flex-wrap: wrap;
          padding: 10px 0;
          padding-right: 16px;

          & > div {
            display: flex;
            align-items: center;

            span {
              display: block;
              margin: 3px 0;
            }

            .creatorSeparator {
              margin: 0 4px;
              color: $midnightBlue10;
            }
          }
        }

        &.title {
          flex: 2;
          max-width: 305px;

          .itemArrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 6px;
            border-color: transparent transparent transparent #24233d;
            margin-bottom: 2px;
            margin-left: 4px;
            transition: 200ms ease-in-out;
          }
        }

        &.details {
          flex: 1;
          max-width: 152px;
        }

        &.origin {
          flex: 1;
        }

        &.lastAttachment {
          flex: 1;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
      }
    }

    .projectAttachments {
      transition: 100ms ease-in-out;
      max-height: 0;
      overflow-y: hidden !important;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;
      background-color: #575863;
      padding: 0 12px;
      border-radius: 2px;

      span {
        font-size: 16px;
        color: #fff;
        @extend %fontRoundBold;
        margin: 0 12px;
      }
    }

    &.opened {
      .projectDetails {
        li {
          &.title {
            .itemArrow {
              transform: rotate(90deg);
            }
          }
        }
      }

      .projectAttachments {
        max-height: 500000000px;
      }
    }
  }

  .SimpleScriptBox,
  .ScriptBox {
    .windowScriptBox {
      .header {
        margin-top: 0;
        height: fit-content;
      }
    }
  }

  @media screen and (max-width: 920px) {
    .header {
      padding-right: 16px;

      .creator,
      .details,
      .origin {
        display: none;
      }

      .lastAttachment {
        text-align: right;
      }
    }

    .listItem {
      .projectDetails {
        flex-direction: column;
        position: relative;

        li {
          .projectTitle {
            padding: 16px 0;
          }

          &.title {
            max-width: 70%;
          }

          &.creator {
            max-width: 100%;
          }

          &.details,
          &.origin {
            margin: 4px 0 8px 0;
          }

          &.lastAttachment {
            position: absolute;
            right: 16px;
            top: 20px;
          }
        }

        a {
          position: absolute;
          right: 12px;
          bottom: 12px;
        }
      }
    }
  }
}
