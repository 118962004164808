@import "../../../../styles/base.scss";

.openScope {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #c0c3f8;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
