@import "../../../../styles/base.scss";

#Breadcrumb {
  padding: 0 25px 0 20px;
  position: relative;

  .container {
    height: 100%;

    .registerContainer {
      min-width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .backButtonContainer {
        display: flex;
        margin-right: 20px;
      }

      .infoContainer {
        height: 100%;
        display: flex;
      }

      .registerTitle {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;

        h2 {
          margin-bottom: 4px;
        }

        & > div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding-right: 12px;

          span {
            display: block;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            color: #adafef;
            text-transform: uppercase;
          }

          .separator {
            width: 4px;
            height: 4px;
            background-color: #fff;
            margin: 0 6px 4px;
            border-radius: 50%;
          }
        }
      }

      .stepCounter {
        display: flex;
        align-items: center;
        color: #fff;

        .stepIcon {
          display: flex;
          align-items: center;
          transform: rotate(90deg);
        }

        p {
          margin-left: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    #Breadcrumb {
      height: 148px;

      .container {
        .registerContainer {
          height: 100px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    #Breadcrumb {
      .container {
        .registerContainer {
          .backButtonContainer {
            margin-right: 10px;
          }

          .stepCounter {
            position: absolute;
            right: 16px;
            bottom: 10px;
            height: 30px;
          }

          .registerTitle {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
