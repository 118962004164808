@import "../../../../../styles/base.scss";

.evaluationTitle {
  padding: 28px 62px 0 62px;

  textarea {
    color: #1e1f24;
    font-size: 34px;
    @extend %fontLight;
    margin-bottom: 45px;
    border: none;
    outline: none;
    min-height: 38px;
    border: 2px dashed transparent;
    padding: 4px 0;
    transition: 100ms ease-in-out;
    width: 100%;
    resize: none;
    font-size: 28px;

    &::placeholder {
      color: $midnightBlue50;
    }

    &:focus,
    &:hover {
      border: 2px dashed $midnightBlue10;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 20px 24px 0 24px;
  }
}
