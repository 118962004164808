@import "../../../../../styles/base.scss";

#VarietiesParameters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .parametersSection {
    width: 100%;
  }

  .parameter {
    display: flex;
    width: calc(33% - 10px);
    min-width: 220px;

    .creatableFieldset {
      .reactSelect
        .reactSelect__control
        .reactSelect__value-container
        .reactSelect__placeholder {
        font-size: 14px;
      }

      .reactSelect .reactSelect__control {
        min-height: 45px;
      }
    }

    &.small {
      width: 33%;
    }

    &.medium {
      width: 66%;
    }

    &.large {
      width: 100%;
    }

    .fieldset {
      width: 100%;
      margin-top: auto;

      .inputContainer {
        height: 46px;

        input {
          height: 46px;
          font-size: 14px;
        }
      }

      .selectArea {
        height: 46px;
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .parameter {
      display: flex;
      width: calc(50% - 10px);
      min-width: 220px;
    }
  }

  @media screen and (max-width: 540px) {
    .parameter {
      display: flex;
      width: 100%;
    }
  }
}
