@import "../../../../styles/base.scss";

.tabs {
  display: flex;
  width: 100%;
  margin-top: 32px;

  .tab {
    display: flex;
    color: #bfc2f6;
    @extend %fontRoundBold;
    font-size: 18px;
    padding: 12px 24px;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    &.active {
      color: #fff;
      border-bottom: 3px solid #fff;
    }
  }
}
