@import "../../../../styles/base.scss";

.ListVideos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0;
  flex-wrap: wrap;
  border-bottom: 1px solid #c0c3f8;

  .nameAndPasswordVideos {
    width: 70%;
    color: $secondaryColor;
    @extend %fontRoundBold;
    word-break: break-all;

    & > div {
      cursor: pointer;
    }

    a {
      color: $secondaryColor;
      @extend %fontRoundBold;
      text-decoration: none;
    }

    span {
      width: 100%;
      display: block;
      @extend %fontRegular;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .platform {
    @extend %fontRegular;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .password {
    padding: 0;
    width: 100%;
    display: block;
    @extend %fontRegular;
    font-size: 14px;
    margin-top: 10px;
    color: #6b6c78;
  }

  &:last-child {
    border-bottom: none;
  }
}
