#EditStatusExternalProject {
  padding: 30px 20px;

  .contentEditStatusExternalProject {
    min-height: 370px;

    .daysRemaining {
      width: 92%;
      margin: 10px auto 0;
      display: flex;
      align-items: center;
      color: #996b1b;
      background: #fff4e3;
      padding: 20px 25px;
      border: 1px solid #eeb65f;

      strong {
        margin: 0 5px;
      }

      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }

    form {
      width: 92%;
      margin: 30px auto 0;

      .formItem {
        margin-bottom: 25px;

        .groupSelect__control {
          background-color: #ffffff;
        }

        .charactersCounterError {
          color: red;
        }
      }
    }
  }
}
