@import "../../../../styles/base.scss";

#NewActivity {
  background-color: #e0e2ff;
  min-height: 100vh;

  #Breadcrumb {
    .newActivityHeader {
      flex: 1;

      span {
        font-size: 16px;
        color: $midnightBlue10;
        @extend %fontRoundBold;
        text-transform: capitalize;
        display: block;
        margin-top: 4px;
      }
    }

    .goBack {
      font-size: 16px;
      color: $cyan40;
      @extend %fontRoundBold;
      text-align: right;
      text-decoration: none;
      margin-left: 10px;
    }
  }

  #SearchArea {
    min-height: 200px;
    height: fit-content;
    background-color: $midnightBlue10;
    padding: 0 16px 60px 16px;

    .searchContainer {
      display: flex;
      flex-direction: column;
      max-width: 1120px;
      width: 100%;
      margin: 0 auto;

      .searchTitle {
        font-size: 28px;
        @extend %fontLight;
        margin: 34px 0 16px 0;
      }

      .formArea {
        display: flex;
        width: 100%;
        align-items: center;
        min-height: 50px;
      }
    }

    .foundProject {
      display: flex;
      align-items: center;
      width: calc(100% - 210px);
      min-height: 64px;
      background-color: #fff;
      padding: 8px;
      margin: 10px 0 1px 0;
      border-radius: 2px;

      .title {
        display: flex;
        align-items: center;
        flex: 1;

        .typeIconReturner {
          margin-right: 0;

          svg {
            width: 24px;
            size: 24px;
          }
        }

        .projectTitle {
          @extend %fontRoundBold;
          font-size: 16px;
          padding: 8px 0;
          margin-right: 16px;
        }
      }

      span {
        flex: 1;
        color: #43444e;
        font-size: 14px;
      }

      .creatorName {
        display: flex;
        flex: 1;
        text-transform: uppercase;
        flex-wrap: wrap;

        & > div {
          display: flex;
          margin: 4px 0;
        }

        .creatorSeparator {
          max-width: 1px;
          margin: 0 4px;
          color: $midnightBlue10;
        }
      }
    }
  }

  .ScriptBox {
    &.removePadding {
      .content {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 920px) {
    #SearchArea {
      .foundProject {
        min-width: 100%;
        flex-direction: column;
        padding-bottom: 24px;

        .title,
        .creatorName,
        .type {
          width: 100%;
          padding-left: 8px;
        }

        .creatorName {
          margin-bottom: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    #Breadcrumb {
      .goBack {
        span:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
