@import "../../../styles/base.scss";

#Header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  @extend %fontRoundBold;
  font-weight: 700;
  font-size: 16px;
  padding: 0 8px;
  background-color: $primaryColor;
  position: relative;

  &.manyItems {
    nav {
      & > a {
        span {
          display: none;
        }
      }
    }
  }

  .scriptIcon {
    margin: 0 10px 0 12px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .featherIcon {
    display: none;

    @media screen and (max-width: 480px) {
      display: block;
      margin-left: 5px;
    }
  }

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-left: auto;

    & > a {
      display: flex;
      align-items: center;
      margin: 0;
      cursor: pointer;
      height: 100%;
      user-select: none;
      text-decoration: none;
      padding: 0 10px;
      position: relative;

      &.active:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $cyan50;
        height: 4px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      span {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 10px;
        padding-top: 4px;
        color: $cyan40;
        white-space: nowrap;
      }
    }

    & > a.active {
      span {
        color: #fff;
      }
    }

    .navItem {
      display: flex;
      align-items: center;
      margin: 0;
      height: 100%;
      user-select: none;
      padding: 0 10px;

      & > span {
        display: flex;
        align-items: center;
        color: #fff;
        height: 100%;
        margin-left: 10px;
        padding-top: 4px;
      }

      &.mobileSearchIcon {
        display: none;

        @media screen and (max-width: 1024px) {
          display: flex;
          padding-left: 5px;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    nav {
      & > div,
      & > a {
        span {
          display: none;
        }
      }

      & > .avatarName {
        display: none;
      }
    }
  }
}
