@import "../../../../../styles/base";

#HeaderTable {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;

  li {
    width: calc(100% / 6);
    height: 100%;
    font-size: 12px;
    color: #7f808e;
    @extend %fontRoundBold;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 2;
    margin-bottom: 2px;
    background: none;

    &.title {
      min-width: 215px;
      max-width: 215px;
    }

    &.type {
      min-width: 135px;
      max-width: 135px;
    }

    &.attachmentType {
      min-width: 170px;
    }

    &.analyst {
      min-width: 200px;
      position: relative;
      z-index: 3;
    }

    &.info {
      display: flex;
      min-width: 125px;
      max-width: 125px;
    }

    &.blueArrow {
      color: #0098cd;
      cursor: pointer;
    }

    &.canceledOrDone {
      min-width: 155px;
      max-width: 155px;
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: space-between;

    li {
      &.title,
      &.analyst {
        min-width: unset;
      }

      &.type,
      &.attachmentType,
      &.info {
        display: none;
      }

      &.analyst {
        justify-content: center;
      }

      &.canceledOrDone {
        justify-content: flex-end;
      }
    }
  }
}
