.reactSelect {
  width: 100%;

  .reactSelect__control {
    border: 2px solid #fff;
    border-radius: 4px;
    min-height: 60px;
    width: 100%;

    &:hover {
      border: 2px solid #fff;
    }

    .reactSelect__value-container {
      padding: 6px 8px;
      display: flex;
      min-height: 60px;

      .reactSelect__placeholder {
        font-size: 18px;
        color: #8586cc;
      }

      .reactSelect__input {
        font-size: 18px;
      }

      .reactSelect__single-value {
        font-size: 18px;
      }

      .reactSelect__multi-value {
        display: flex;
        flex-direction: row-reverse;
        margin: 4px;
        min-height: 38px;
        background-color: #e4f8ff;
        border: 1px solid #aaf0fd;
        border-radius: 4px;
        word-break: break-all;

        .reactSelect__multi-value__remove {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 26px;
          max-width: 26px;
          color: #17b4e1;
          border-right: 1px solid #aaf0fd;
          cursor: pointer;

          &:hover {
            background-color: #d0ecfd;
          }
        }

        .reactSelect__multi-value__label {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          flex-wrap: wrap;
          max-width: 100%;
          padding: 6px 10px 4px 10px;
          white-space: normal;
        }
      }
    }

    .reactSelect__indicators {
      .reactSelect__indicator-separator,
      .reactSelect__dropdown-indicator {
        display: none;
      }

      .reactSelect__clear-indicator {
        cursor: pointer;
      }
    }
  }

  &.isAlert {
    .reactSelect__control {
      border-bottom-color: #eeb65f;
    }

    .reactSelect__control--is-focused {
      border-color: #17b4e1;

      &:hover {
        border-color: #17b4e1;
      }
    }
  }

  &.blueBordered {
    .reactSelect__control {
      border-color: #daddfe;

      &:hover {
        border: 2px solid #daddfe;
      }
    }

    .reactSelect__control--is-focused {
      border-color: #17b4e1;

      &:hover {
        border-color: #17b4e1;
      }
    }
  }

  .reactSelect__control--is-focused {
    border-color: #17b4e1;
    box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);

    &:hover {
      border-color: #17b4e1;
    }
  }

  .reactSelect__menu {
    top: calc(100% - 11px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    border: 2px solid #17b4e1;
    border-top-width: 1px;
    border-top-color: #d4d5e2;
    box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
    max-height: 250px;
    z-index: 5;

    .reactSelect__menu-list {
      padding: 0;
      max-height: 247px;

      .reactSelect__option {
        font-size: 18px;
        padding: 14px 12px;
        cursor: pointer;
        word-break: break-all;

        &:hover {
          background-color: #e4f8ff;
        }

        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .reactSelect__option--is-focused {
        background-color: #e4f8ff;
        color: #000;
      }

      .reactSelect__option--is-selected {
        background-color: #fff;
        color: #000;
      }
    }
  }
}
