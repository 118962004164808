@import "../../../styles/base.scss";

.tooltip {
  display: inline;

  div {
    font-size: 11px;
    font-weight: 900;
    cursor: pointer;
    color: $cyan60;
    letter-spacing: 0.1em;
    line-height: 16px;
    user-select: none;
  }
}

.tippy-popper {
  .tippy-tooltip {
    padding: 20px 30px;

    .tippy-tooltip-content {
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        li {
          margin: 2px 0;
          position: relative;
          font-size: 16px;

          &::before {
            content: "";
            display: inline-block;
            width: 1px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            bottom: 7px;
            left: -7px;
          }
        }
      }
    }
  }
}
