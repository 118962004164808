@import "../../../../../../styles/base.scss";

.dateTooltip {
  display: flex;
  flex-direction: column;

  p {
    text-align: left;
    line-height: 20px;

    strong {
      color: #1b8561;
    }

    &:first-of-type {
      margin-bottom: 16px;
    }
  }
}
