@import "../../../../styles/base.scss";

.fullCircleLoading {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  line-height: 70px;
  border: 5px solid #24233d;
  border-radius: 50%;
  border-right-color: #d8d8d8;
  border-bottom-color: #d8d8d8;
  border-left-color: #d8d8d8;
  animation: loadingSpin 750ms linear infinite;
  will-change: transform;
  padding: 1px;

  @keyframes loadingSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
