@import "../../../../styles/base.scss";

.characterInput {
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 25px;
  position: relative;

  .reactSelect {
    margin-bottom: 25px;
  }

  .buttonsList {
    display: flex;
    flex-wrap: wrap;
    z-index: 0;
    position: relative;

    .borderedButton {
      margin: 0 10px 15px 0;
    }
  }

  &.showDeleteIcon {
    padding-right: 58px;

    .deleteIcon {
      height: 100%;
      width: 36px;
      background-color: #fff1f2;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 16;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #e5c2c4;
      }
    }
  }
}
