@import "../../../../styles/base.scss";

.seasonsCarousel {
  display: flex;
  width: 100%;
  height: 336px;
  background-color: $midnightBlue5;
  padding: 56px 0 80px 0;
  margin-top: 50px;

  .carouselContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    .seasonsHeader {
      margin: 16px 0;
      padding-left: 16px;

      .seasonName {
        color: #6b6c78;
        font-size: 20px;
      }

      .seasonSeparator {
        color: #6b6c78;
        font-size: 20px;
        margin: 0 6px;
      }

      .seasonsTitle {
        font-size: 20px;
      }
    }

    .slick-slider {
      position: relative;
      padding-bottom: 60px;
      padding-left: 16px;

      .slick-list {
        padding: 12px;

        .slick-track {
          margin-right: auto;
          margin-left: 0;
        }
      }

      .slick-arrow {
        position: absolute;
        top: 85%;
      }

      .slick-prev {
        left: calc(50% - 60px);

        svg {
          transform: rotate(180deg);
        }
      }

      .slick-next {
        right: calc(50% - 60px);
      }
    }
  }
}
