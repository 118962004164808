@import "../../../styles/base.scss";

.overflowMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &:hover,
  &.openedOverflowMenu {
    background-color: #19182b;
  }

  .menu {
    display: none;
  }

  &.openedOverflowMenu {
    .menu {
      display: flex;
      flex-direction: column;
      width: 232px;
      // height: 122px;
      height: fit-content;
      background-color: #fff;
      border-radius: 4px;
      padding: 12px 0;
      position: absolute;
      top: 100%;
      right: 0;
      box-shadow: 0 2px 4px 0 #00000080;
      z-index: 15;

      .menuLinks {
        padding: 15px 24px;
        width: 100%;
        cursor: pointer;
        font-weight: normal;
        @extend %fontRegular;
        text-decoration: none;
        color: #000;
        user-select: none;
        margin: 0;

        &:hover {
          background-color: #e4f8ff;
        }

        &.active {
          color: $midnightBlue80;
          @extend %fontRoundBold;
        }
      }

      .governaceItem {
        background: #ededed;
        font-size: 11px;
        @extend %fontRoundBold;
        letter-spacing: 1px;
        padding: 7px 20px 5px;
        border-top: 1px solid #cccccc;
        color: #7f808e;
      }
    }
  }
}
