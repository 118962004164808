@import "../../../../../styles/base.scss";

.complementariesInfoContainer {
  padding-left: 38px;

  .complementariesInfo {
    display: flex;
    align-items: center;
    padding: 3px 0;
    flex-wrap: wrap;

    .keyName {
      text-transform: uppercase;
      font-size: 12px;
      color: $midnightBlue40;
      letter-spacing: 1px;
      @extend %fontRoundBold;
      margin-right: 4px;
    }

    .keyValue {
      font-size: 14px;
      display: flex;
      min-width: fit-content;
      word-wrap: none;
      white-space: nowrap;

      .separator {
        margin: 0 4px;
        text-decoration: none;
        border-bottom: none;
        max-width: 6px;
      }
    }
  }

  .contentDetails {
    max-width: 245px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 24px;
    min-width: 200px;
    flex: 1;

    .detailItem {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;

      & > span {
        margin-right: 4px;
        line-height: 20px;
      }

      &.markMatch {
        display: inline-block;
      }

      .detailItemText {
        color: #1e1f24;
        line-height: 18px;
      }

      .detailsSeparator {
        display: block;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #1e1f24;
        margin: 0 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}
