@import "../../../styles/base.scss";

#LiteraryWork {
  .literaryContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 696px;
    width: 100%;
    margin: 0 auto;

    .literaryForm {
      margin-top: 50px;
      width: 100%;
      padding-bottom: 50px;

      .showStep {
        display: block;
      }

      .hideStep {
        display: none;
      }

      .formField {
        display: flex;
        width: 100%;
        margin-bottom: 25px;
        justify-content: space-between;
        min-height: 64px;

        .dateField {
          width: 236px;

          .customDatepicker {
            width: 100%;
          }
        }

        .formatField {
          width: 100%;
          margin-right: 25px;
        }

        .yearField {
          min-width: 270px;

          .fieldset {
            .inputContainer {
              input {
                padding-right: 24px;
              }
            }
          }
        }

        .fieldset {
          width: 100%;
          flex-direction: column;
        }

        &.buttonField {
          display: flex;
          justify-content: flex-end;

          button {
            margin-left: 25px;
          }
        }

        .formFieldColumn {
          margin-bottom: 0;
          width: 100%;

          .titleNumber {
            width: auto;
            flex-basis: 100%;
            flex-grow: 2;
          }

          .fieldset {
            width: 100%;
            flex-direction: column;
          }
        }

        .checkBoxContainer {
          .checkBoxes {
            display: flex;

            .checkBox {
              margin-left: 16px;
            }
          }

          .errorMessage {
            margin: 0 0 0 16px;
          }
        }
      }

      .formFieldColumn {
        flex-direction: column;
        margin-bottom: 25px;
        position: relative;

        .associationField {
          margin-top: 25px;
        }

        .creatableFieldset {
          position: relative;
        }

        label {
          color: $terciaryColor;
          @extend %fontRegular;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          display: block;
          margin-bottom: 8px;
        }

        .helpContainer {
          margin-top: 6px;
        }

        .creatableFieldset {
          margin-bottom: 0;
        }

        .optionalText {
          line-height: 16px;
          font-size: 11px;
          letter-spacing: 0.1em;
          color: #575863;
          display: block;
          padding-left: 2px;
          margin-top: 8px;
        }
      }

      .fieldTitle {
        color: $terciaryColor;
        @extend %fontRegular;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
      }

      .fieldset {
        margin-bottom: 0;
      }

      .dashedBox {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed rgba(170, 190, 231, 0.5);
        height: 44px;
        margin: 30px 0 25px 0;

        span {
          display: block;
          text-align: center;
          font-size: 14px;
          color: #38a86e;
          @extend %fontRoundBold;
          cursor: pointer;
        }
      }
    }

    .closeButtonContainer {
      position: absolute;
      right: 50px;
    }

    @media screen and (max-width: 768px) {
      .literaryForm {
        padding: 0 16px;
        margin-top: 30px;
        padding-bottom: 15px;

        .formField {
          flex-direction: column;

          .dateField {
            width: 100%;
          }

          .formatField {
            margin-bottom: 25px;
          }

          .customDatepicker {
            margin-top: 25px;
          }

          .numberInput {
            margin-top: 25px;
          }

          &.buttonField {
            padding: 0;
            margin-bottom: 0;

            button {
              margin: 5px 0;
            }
          }

          .smallField {
            margin: 25px 0 0 0;
          }
        }
      }
    }
  }

  #Success {
    background-color: #e2e4ff;
    min-height: calc(100vh - 264px);
    width: 100%;

    .success-icon {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #36355a;

      .container {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin: 50px 0;
        }
      }
    }

    h2 {
      max-width: 800px;
      margin: 65px auto 30px;
      @extend %fontLight;
      text-align: center;
      font-weight: 300;
      font-size: 37px;
      color: #000;

      a {
        color: #17b4e1;
        display: inline-block;
        margin: 0 5px;
        border-bottom: 4px solid #d2d5fd;
        @extend %fontRoundBold;
        text-decoration: none;
      }
    }

    p {
      text-align: center;
      margin: 32px 0 48px;
      font-size: 18px;
      @extend %fontLight;
      color: rgb(87, 88, 99);
      font-weight: 300;
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        margin-right: 15px;
      }

      svg {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 640px) {
      h2 {
        font-size: 30px;
      }

      .buttons {
        flex-direction: column;

        div {
          margin: 0 auto 15px auto;
        }
      }
    }
  }
}
