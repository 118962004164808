@import "../../../../styles/base.scss";

.conflictsTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 0;

  .conflictItem {
    .conflictHeader {
      height: 70px;
      background-color: #f6f6ff;
      display: flex;
      align-items: center;
      padding: 24px 11px 24px 24px;
      border-radius: 2px;
      margin-bottom: 1px;
      transition: 200ms ease-in-out;
      cursor: pointer;

      .itemArrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent #24233d;
        margin-right: 14px;
        margin-bottom: 2px;
        transition: 200ms ease-in-out;
      }

      .conflictName {
        @extend %fontRoundBold;
        color: #43444e;
      }

      .conflictsLevel {
        display: flex;
        margin-left: auto;

        .dot {
          margin: 0 3px;
        }
      }

      .removeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #c0c3f8;
        width: 41px;
        height: 39px;
        border-radius: 4px;
        margin-left: 21px;
        cursor: pointer;

        &:hover {
          background-color: #f3f3f3;
        }
      }
    }

    .conflict {
      max-height: 0;
      overflow-y: hidden !important;
      transition: 200ms ease-in-out;
      display: flex;
      background-color: #fff;
      padding: 0 5px;

      .conflictBox {
        padding: 16px;
        flex: 1;
        display: flex;
        min-height: 100px;
        height: fit-content;
        opacity: 0;
        transition: 300ms ease-in-out;
        width: auto;
        flex-wrap: wrap;

        .productInfo {
          width: 100%;
          display: flex;
          flex-direction: column;

          .productInfoName {
            @extend %fontRoundBold;
            margin-bottom: 4px;
          }

          .productPeriod {
            width: 100%;
            @extend %fontLight;
            font-size: 14px;
            line-height: 20px;
          }
        }

        .characterInfo {
          .characterName {
            @extend %fontRoundBold;
            color: #43444e;
            margin-bottom: 4px;
            display: block;

            .nickName {
              font-weight: 100;
              @extend %fontRegular;
            }
          }

          .characterDescription {
            span {
              @extend %fontLight;
              font-weight: 100;
              font-size: 14px;
              line-height: 20px;
              margin-right: 6px;
            }
          }
        }

        .conflictTags {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          height: fit-content;

          .conflictTag {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 4px 2px 4px;
            font-size: 14px;
            min-width: fit-content;
            word-wrap: inherit;
            word-break: keep-all;
            height: 20px;
            margin: 2px;
            border-radius: 4px;
            border: solid 1px #aaf0fd;
            background-color: #e4fbff;
            color: #43444e;
          }
        }

        .conflictedProducts {
          min-height: 20px;
          position: relative;
          @extend %fontRoundBold;
          color: #43444e;
          font-size: 14px;
          margin-bottom: 6px;

          .importanceMark {
            position: absolute;
            left: -20px;
          }

          .conflictDays {
            @extend %fontRegular;
          }
        }

        .scriptBoxCaller {
          position: relative;
          font-weight: 200;
          color: $secondaryColor;
          display: inline-block;
          margin-left: 0;
          top: 0;
        }
      }

      .removeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #c0c3f8;
        width: 41px;
        height: 39px;
        border-radius: 4px;
        margin: 16px 6px 24px 21px;
        cursor: pointer;

        &:hover {
          background-color: #f3f3f3;
        }

        &.hide {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }

    &.opened {
      .conflictHeader {
        background-color: #fff;

        .itemArrow {
          transform: rotate(90deg);
        }
      }

      .conflict {
        max-height: 1000px;
        margin-bottom: 1px;

        .conflictBox {
          opacity: 1;
        }
      }

      .conflictsGraphic {
        max-height: 10000px;
        padding: 27px 0 27px 19px;
      }
    }
  }

  .boxParagraph {
    font-size: 18px;
    color: #24233d;
    margin: 25px 0;
    line-height: 24px;
    word-break: break-all;
  }

  @media screen and (max-width: 920px) {
    .conflictItem {
      .conflict {
        flex-direction: column;
        position: relative;

        .conflictBox {
          max-width: 100%;
          min-height: 50px;
          padding: 8px 16px;

          .conflictedProducts {
            margin-left: 20px;
          }
        }

        .conflictBox:first-of-type {
          padding-top: 16px;
        }

        .conflictBox:last-of-type {
          padding-bottom: 16px;
        }

        .removeButton {
          position: absolute;
          right: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .conflictItem {
      .conflictHeader,
      .conflict {
        border-radius: 0;
      }
    }
  }
}
