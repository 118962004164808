@import "../../../styles/base.scss";

.uploadSection {
  z-index: 0;

  .attachmentTitle {
    @extend %fontLight;
    font-size: 32px;
    font-weight: 300;
    color: #1f1f1f;
    margin-bottom: 15px;
    display: block;
  }

  .attachmentSubTitle {
    color: #575863;
    @extend %fontRegular;
    font-weight: 300;
    margin: 0;
    display: block;
  }

  .optionalText {
    line-height: 16px;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #575863;
    display: block;
    padding-left: 2px;
    margin-top: 8px;
  }

  @media screen and (max-width: 768px) {
    .optionalText {
      margin-bottom: 25px;
    }
  }
}
