@import "../../../../../styles/base.scss";

.phaseItem {
  flex-direction: column;

  .phaseTitle {
    width: 100%;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #43444e;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    .phaseDot {
      width: 6px;
      height: 6px;
      background-color: #7070b3;
      border-radius: 50%;
      margin-bottom: 2px;
      margin-right: 20px;
    }
  }

  .phaseBlockContainer {
    position: relative;
    padding: 0 0 0 25px;

    &:last-of-type {
      margin-bottom: 6px;
      padding-bottom: 25px;
    }

    &::before {
      content: "";
      width: 2px;
      height: 100%;
      background-color: #7070b3;
      position: absolute;
      left: 2px;
    }

    .phaseBlock {
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 20px 12px 20px 20px;
      border-radius: 2px;
      margin-bottom: 1px;

      .phaseName {
        flex: 2;
        color: #7070b3;
        @extend %fontRoundBold;
        font-size: 14px;
        padding-right: 24px;
      }

      .phaseInfo {
        flex: 2;
        font-size: 14px;
        color: #6b6c78;
        padding-right: 24px;

        .phasePeriod {
          &.inProgress {
            @extend %fontRoundBold;
            color: #000;
          }
        }
      }

      .phaseStatus {
        flex: 1;
        font-size: 12px;
        color: #43444e;

        &.inProgress {
          color: $successColor;
          @extend %fontRoundBold;
        }
      }
    }
  }

  &:last-of-type {
    .phaseBlockContainer {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .phaseBlockContainer {
      .phaseBlock {
        flex-direction: column;
        align-items: flex-start;

        .phaseName {
          padding-right: 0;
          margin-bottom: 6px;
        }

        .phaseInfo {
          padding-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}
