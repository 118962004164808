@import "../../../../styles/base.scss";

#ProjectRecomendation {
  background-color: $midnightBlue0;
  height: 100%;
  padding-bottom: 72px;

  .recomendationContent {
    padding: 22px 42px 24px;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .recomendationTypeInput {
      margin-bottom: 16px;

      .radioInput {
        .radioOptionList {
          .radioOption {
            .radioButton {
              min-width: 18px;
              max-width: 18px;
              max-height: 18px;
              background-color: transparent;
              border: solid 2px #adafef;

              &.selectedRadio {
                .radioDot {
                  background-color: #7171b4;
                }
              }
            }

            .radioText {
              color: #43444e;
              font-size: 16px;
            }
          }
        }
      }
    }

    .justificationInput {
      .textarea {
        .textareaContainer {
          textarea {
            font-size: 16px;
          }
        }
      }
    }
  }

  .recomendationFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 28px;
    height: 72px;
    background-color: $midnightBlue0;
    box-shadow: 0 -10px 10px 0 #00000019;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
