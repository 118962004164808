@import "../../../../styles/base.scss";

.thesaurusSearch {
  min-height: 70px;
  width: 100%;
  padding: 11px 20px;
  background-color: $midnightBlue0;
  box-shadow: 0 4px 4px 0 #0000004c;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 25;

  .reactSelect {
    min-height: 48px;

    .thesaurusSearch__control {
      border: 2px solid #fff;
      border-radius: 4px;
      min-height: 48px;
      width: 100%;
      padding-left: 8px;

      &:hover {
        border: 2px solid #fff;
      }

      .thesaurusSearch__value-container {
        display: flex;
        height: 100%;

        .thesaurusSearch__placeholder {
          font-size: 18px;
          color: #8586cc;
        }

        .thesaurusSearch__input {
          font-size: 18px;
        }

        .thesaurusSearch__single-value {
          font-size: 18px;
        }
      }

      .thesaurusSearch__indicators {
        min-height: 100%;
        max-height: 100%;

        .thesaurusSearch__indicator-separator,
        .thesaurusSearch__dropdown-indicator {
          display: none;
        }

        .thesaurusSearch__clear-indicator {
          cursor: pointer;
        }
      }
    }

    .thesaurusSearch__control--is-focused {
      border-color: #17b4e1;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);

      &:hover {
        border-color: #17b4e1;
      }
    }

    .thesaurusSearch__menu {
      top: calc(100% - 11px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none;
      border: 2px solid #17b4e1;
      border-top-width: 1px;
      border-top-color: #d4d5e2;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
      max-height: 150px;

      .thesaurusSearch__menu-list {
        padding: 0;
        max-height: 147px;
      }
    }
  }

  .optionItem,
  .singleValueSearch {
    display: flex;
    padding: 6px 12px;
    min-height: 50px;

    .optionInfo {
      display: flex;
      align-items: center;
      padding-top: 2px;

      .contentName {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        .optionName {
          color: #43444e;
          padding-right: 16px;
          min-width: fit-content;
          font-size: 16px;
          margin-bottom: 4px;
        }

        .remissivesList {
          display: flex;
          flex-wrap: wrap;

          .remissiveItem {
            display: flex;
            align-items: center;
            background-color: $alertColorSecondary;
            border-radius: 18px;
            margin: 3px 16px 3px 0;

            .remissiveTag {
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;
              color: #fff;
              background-color: #fdaf34;
              @extend %fontRoundBold;
              padding: 8px;
              border-radius: 18px;
              font-size: 14px;
              height: 100%;
            }

            .remissiveText {
              color: $alertTextColor;
              font-size: 16px;
              @extend %fontRoundBold;
              padding: 8px 12px;
            }
          }
        }
      }
    }

    &:hover {
      background-color: #d1f0f9;
      cursor: pointer;
    }

    &.isFocused {
      background-color: #d1f0f9;
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  .singleValueSearch {
    padding: 0;
    max-width: calc(100% - 10px);

    .optionInfo {
      padding-top: 0;

      .contentName {
        padding: 4px 0;

        .optionName {
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      background-color: transparent;
      cursor: unset;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 11px;
  }
}
