@import '../../../../styles/base.scss';

.releasedItemProject {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  flex-wrap: wrap;
  box-sizing: border-box;

  a {
    color: $midnightBlue10;
  }

  .containerTitleAndCreators {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .contentTitle {
      width: 100%;
      display: flex;
      align-items: flex-start;
      @extend %fontRoundBold;

      .typeIconReturner {
        padding: 0;
        margin-right: 8px;
        min-width: 26px;
        width: 26px;
        height: 32px;

        svg {
          width: 26px;
          height: 26px;
        }
      }

      .title {
        padding: 8px 16px 8px 0;
      }
    }

    .creatorsReleased {
      width: 100%;
      text-transform: uppercase;
      display: flex;
      flex-wrap: wrap;
      padding: 8px 16px 8px 0;
      color: #5c5c68;

      .creatorSeparator {
        margin: 0 4px;
        color: #5c5c68;
      }
    }
  }

  .formatReleased {
    margin-bottom: 6px;
    color: #5c5c68;
  }

  .lastAttachment {
    color: #5c5c68;

    .date {
      @extend %fontRoundBold;
      color: #575763;
    }
  }
}
