@import "../../../../styles/base.scss";

.thesaurusItem {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
  page-break-inside: avoid;
  max-width: fit-content;

  & > svg {
    margin-right: 7px;
    min-width: 15px;
  }

  & > span {
    font-size: 16px;
    color: $cyan50;
  }
}
