@import "../../../styles/base.scss";

.fieldset {
  display: flex;
  flex-wrap: wrap;
  border: none;
  padding: 0;
  margin-bottom: 25px;
  position: relative;

  label {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }

  .inputContainer {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;

    .selectArea {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      line-height: 24px;
      font-size: 18px;
      color: rgb(31, 31, 31);
      background-color: transparent;
      padding: 16px 64px 16px 14px;
      transition: border 200ms ease-out 0s;
      border-width: 2px;
      border-style: solid;
      border-color: transparent transparent rgb(255, 255, 255);
      border-image: initial;
      border-bottom: 2px solid rgb(255, 255, 255);
      outline: none;
      max-height: 60px;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 4px;
      z-index: 5;
      background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='22' height='22' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%2317b4e1'/></g></svg>")
        no-repeat;
      background-position: right 16px top 50%, 0 0;

      &::placeholder {
        color: $placeholderColor;
      }

      &:focus {
        border: 2px solid #17b4e1;
        box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
      }

      &.error {
        border-bottom-color: $errorColor;

        &:focus {
          border: 2px solid #17b4e1;
        }
      }
    }

    .errorMessage {
      display: block;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.1em;
      margin-top: 8px;
      color: $errorColor;
      padding-left: 2px;
      font-weight: 500;
    }

    .errorIcon {
      position: absolute;
      right: 20px;
      top: 17px;
    }

    &.darkBackgroundColor {
      select {
        background-color: #e2e4ff;
        border-color: #e2e4ff;

        &:focus {
          border: 2px solid #17b4e1;
          box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
        }

        &.error {
          border-bottom-color: $errorColor;

          &:focus {
            border: 2px solid #17b4e1;
          }
        }
      }
    }

    &.disabledFieldsetSelect {
      select {
        background-color: #f0f0f0;
        border-color: #f0f0f0;
      }
    }
  }
}
