@import "../../../../../styles/base.scss";

.attachmentItem {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid $midnightBlue0;
  }

  .chapterItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 16px 14px 20px;
    cursor: pointer;

    .chapters {
      display: flex;
      align-items: center;
      flex-grow: 2;
      font-size: 14px;
      @extend %fontRoundBold;
      white-space: nowrap;

      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 4px;
        border-color: transparent transparent $midnightBlue40 transparent;
        position: relative;
        transform: rotate(90deg);
        margin: 4px 8px 4px 0;
      }
    }

    .delivery {
      max-width: 260px;
      width: 100%;
      margin-right: 88px;
    }

    .delivery,
    .released {
      flex-grow: 1;
      font-size: 14px;

      .datePlaceholder {
        display: flex;
        padding-left: 24px;
      }
    }

    .released {
      max-width: 100px;
      padding: 0 16px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 270px;
      margin-left: auto;
      margin-left: 4px;

      & > svg,
      & > div {
        margin-left: 8px;
        cursor: pointer;
      }

      .borderedButton {
        &:hover {
          background-color: #fff;
          border: 1px solid #daddfe;
          color: $cyan40;
        }
      }
    }
  }

  .chapterItemToggle {
    max-height: 0;
    overflow: hidden;
  }

  &.open {
    .chapterItem {
      .triangle {
        transform: rotate(180deg);
      }
    }

    .chapterItemToggle {
      max-height: 999999px;
    }
  }

  @media screen and (max-width: 1200px) {
    .chapterItem {
      padding: 14px 0;

      .chapters {
        font-size: 12px;
        padding: 0 16px;
        width: 100%;
      }

      .delivery {
        max-width: unset;
        width: unset;
        margin-right: 0;
        padding-right: 16px;
      }

      .delivery,
      .released {
        flex-grow: 1;
        width: 100%;
        font-size: 12px;

        .datePlaceholder {
          display: flex;
          padding: 0 16px;
        }
      }

      .released {
        max-width: unset;
        padding: 0 16px;
      }

      .actions {
        padding-right: 16px;
        margin-left: 0;
        min-width: 80px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .chapterItem {
      .chapters {
        font-size: 12px;
        width: 100%;
        min-width: 120px;
      }

      .delivery {
        max-width: unset;
        width: unset;
        margin-right: 0;
      }

      .delivery,
      .released {
        flex-grow: 1;
        width: 100%;
        font-size: 11px;

        .datePlaceholder {
          display: flex;
          padding: 0 8px;
        }
      }

      .released {
        max-width: unset;
        padding: 0 16px;
      }
    }
  }
}
