@import "../../../styles/base";

#Activities {
  background-color: #e0e2ff;
  min-height: 100vh;
  padding-bottom: 30px;

  #Breadcrumb {
    .container {
      margin: 0;
    }
  }

  .container {
    width: 100%;
    margin-bottom: 30px;

    .actionButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .btnAction {
        flex-basis: calc(33% - 10px);
        min-height: 60px;
        max-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $midnightBlue40;
        color: $cyan50;
        font-weight: bold;
        border-radius: 4px;
        text-decoration: none;
        padding: 8px 16px;
        margin-bottom: 24px;
        flex: 1;
        margin-right: 15px;

        span {
          margin-left: 8px;
          width: fit-content;
          text-align: center;
        }

        div {
          font-size: 30px;
          color: #575863;
          margin-left: 16px;
          font-weight: bold;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .activitiesTopSection {
      display: flex;
    }

    .activitiesTable {
      .activitiesListHeader {
        display: flex;
        height: 50px;
        width: 100%;
        position: sticky;
        top: 54px;
        z-index: 10;
        background-color: #e0e2ff;
        border-bottom: 1px solid #c0c3f8;

        .activitiesItemHeader {
          display: flex;
          align-items: center;

          .activityHeaderTitle {
            font-size: 12px;
            color: #7f808e;
            text-transform: uppercase;
            @extend %fontRoundBold;
            padding-top: 4px;
          }

          &.activityTitle {
            flex: 1;
            width: 335px;
            min-width: 225px;
            padding-left: 24px;
          }

          &.activityType {
            width: 190px;
          }

          &.activityAttachments {
            width: 290px;
          }

          &.activityAnalist {
            width: 200px;
            padding-top: 4px;
          }

          &.activityStatus {
            width: 175px;
          }

          &.activityCreatedAt {
            width: 110px;
            min-width: 100px;
          }

          &.activityConfig {
            width: 82px;
            min-width: 82px;
          }
        }
      }

      .activitySection {
        .activitySectionHeader {
          display: flex;
          align-items: center;
          width: 100%;
          height: 40px;
          background-color: #d2d5fd;
          padding: 18px;
          margin-bottom: 1px;
          cursor: pointer;

          .itemArrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: #000 transparent transparent transparent;
            margin-right: 14px;
            margin-bottom: 2px;
            transition: 200ms ease-in-out;
          }

          .sectionName {
            font-size: 14px;
            @extend %fontRoundBold;

            &.feedbackSection {
              color: #db1a33;
            }
          }

          .listFeedback {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;

            .listFeedbackText {
              color: #f42b5a;
              @extend %fontRoundBold;
              font-size: 14px;
              display: block;
            }
          }
        }

        .goToDoneActivitiesLink {
          display: flex;
          align-items: center;
          height: 40px;
          background-color: #fff;
          padding: 6px 16px;
          @extend %fontRoundBold;
          color: $cyan50;
          margin-bottom: 1px;
          font-size: 14px;
          cursor: pointer;
          text-decoration: none;
        }

        .activitiesList {
          .activityListPlaceholder {
            min-height: 64px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            background-color: #fff;
            color: #757575;
            font-size: 14px;
          }

          .activityItem {
            display: flex;
            min-height: 64px;
            background-color: #fff;
            margin-bottom: 1px;
            border-radius: 2px;

            .activityCell {
              display: flex;
              align-items: center;
              padding-top: 5px;

              &.activityTitle {
                flex: 1;
                width: 335px;
                min-width: 225px;
                color: #575863;
                @extend %fontRoundBold;
                font-size: 16px;
                padding: 10px 16px;
                align-items: flex-start;

                a,
                span {
                  color: #575863;
                  text-decoration: none;
                  cursor: pointer;
                  line-height: 20px;
                }

                .typeIconReturner {
                  min-width: 30px;
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  margin-bottom: 2px;

                  svg {
                    width: 24px;
                    height: 24px;
                  }
                }

                .containerText {
                  display: flex;
                  flex-direction: column;
                  padding-top: 6px;

                  .Author {
                    text-transform: uppercase;
                    font-size: 13px;
                    @extend %fontRegular;
                    line-height: 1.23;
                    color: #7f808e;
                    width: 100%;
                    display: inline;

                    .separator {
                      margin: 0 2px;
                    }

                    .creator {
                      width: fit-content;
                      display: inline-block;
                    }
                  }
                }
              }

              &.activityType {
                width: 190px;
                color: #7f808e;
                font-size: 14px;
                padding-right: 24px;

                a {
                  color: #7f808e;
                  font-size: 14px;
                  text-decoration: none;
                }
              }

              &.activityAttachments {
                width: 290px;
                color: #7f808e;
                font-size: 14px;
                padding: 16px 24px 12px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .activityTypeName {
                  font-size: 16px;
                  color: #979797;
                  margin-bottom: 4px;
                  display: block;
                  @extend %fontRoundBold;
                }

                .attachmentsInfo {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  flex-wrap: wrap;

                  .file {
                    font-size: 14px;
                    color: #43444e;
                    line-height: 18px;
                    padding-right: 4px;
                    letter-spacing: 1px;
                    display: flex;
                    flex-wrap: wrap;
                  }

                  .fileBlock {
                    font-size: 12px;
                    text-transform: uppercase;
                    border: 1px solid #8586cc;
                    color: #8586cc;
                    border-radius: 2px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 2px 3px 0 3px;
                    margin-right: 8px;
                    margin-left: 2px;
                  }

                  .fileVersion {
                    font-size: 14px;
                    color: #6b6c78;
                    padding-right: 10px;
                    white-space: nowrap;
                  }
                }

                & > span {
                  margin-bottom: 2px;
                }
              }

              &.activityAnalist {
                width: 200px;
                color: #7f808e;
                font-size: 14px;
                padding-right: 16px;

                .avatarIcon {
                  @extend %fontRoundBold;
                  margin-right: 8px;
                  min-width: 28px;
                }
              }

              &.activityStatus {
                width: 175px;
                color: #7f808e;
                font-size: 14px;
                padding-right: 16px;
                display: flex;
                align-items: center;
              }

              &.activityCreatedAt {
                width: 110px;
                min-width: 100px;
                font-size: 14px;
                padding-right: 12px;
                color: #7f808e;
              }

              &.activityConfig {
                display: flex;
                justify-content: flex-end;
                width: 82px;
                min-width: 82px;

                .configActivity {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 16px;

                  svg {
                    cursor: pointer;
                  }

                  .editActivityIcon {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .feedbackIcons {
                    margin-left: 12px;

                    .feedbackDone {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 6px 4px 6px 6px;
                      cursor: pointer;
                    }

                    .newFeedback {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      cursor: pointer;

                      &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        bottom: 4px;
                        right: 5px;
                        background-color: #f42b5a;
                        border-radius: 50%;
                      }
                    }
                  }
                }

                .dragIcon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 16px 0 0;
                }
              }
            }
          }
        }

        &.closed {
          .goToDoneActivitiesLink {
            height: 0;
            padding: 0;
            overflow: hidden;
          }

          .activitySectionHeader {
            .itemArrow {
              transform: rotate(-90deg);
            }
          }

          .activitiesList {
            max-height: 0;
            overflow-y: hidden !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      padding: 0;

      .activitiesTable {
        .activitiesListHeader {
          .activitiesItemHeader {
            &.activityType,
            &.activityAttachments,
            &.activityAnalist,
            &.activityStatus,
            &.activityConfig {
              display: none;
            }

            &.activityCreatedAt {
              width: fit-content;
              text-align: right;
              min-width: fit-content;
              padding-right: 12px;
            }
          }
        }

        .activitySection {
          .activitiesList {
            .activityItem {
              flex-direction: column;
              position: relative;
              padding-bottom: 16px;

              .activityCell {
                &.activityTitle {
                  max-width: 70%;
                  padding-bottom: 0;

                  .typeIconReturner {
                    align-self: baseline;
                  }
                }

                &.activityType {
                  position: relative;
                  left: 56px;
                }

                &.activityAttachments {
                  position: relative;
                  padding-right: 100px;
                  width: 80%;
                  left: 56px;
                }

                &.activityAnalist {
                  width: 245px;
                  color: #7f808e;
                  font-size: 14px;
                  padding-right: 16px;
                  position: relative;
                  left: 56px;

                  .avatarIcon {
                    margin-right: 8px;
                  }
                }

                &.activityStatus {
                  position: relative;
                  left: 56px;
                }

                &.activityCreatedAt {
                  width: fit-content;
                  min-width: fit-content;
                  max-width: fit-content;
                  position: absolute;
                  top: 10px;
                  right: 0;
                  text-align: right;
                }

                &.activityConfig {
                  position: absolute;
                  bottom: 12px;
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .container {
      .activitiesTopSection {
        flex-direction: column;
      }

      .actionButtons {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        .btnAction {
          width: calc(100% - 32px);
          margin: 0;

          &:nth-child(2) {
            margin: 10px 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    .container {
      .activitiesTable {
        .activitySection {
          .activitySectionHeader {
            .listFeedback {
              .listFeedbackText {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
