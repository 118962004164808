@import "../../../styles/base.scss";

#Table {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .tableContainer {
    max-width: 1120px;
    width: 100%;
    min-height: fit-content;
    /*max-height: 645px;*/
    display: flex;
    flex-direction: column;

    .tableList {
      display: flex;
      flex-direction: column;
      width: 100%;
      animation: fadeIn 250ms;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .itemsList {
        display: flex;
        flex-direction: column;

        .listItem:not(:nth-child(1)) {
          box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px;
        }

        .listItem:not(:nth-child(1)) {
          .registerAt {
            font-size: 14px;
          }
        }

        .listItem {
          display: flex;
          align-items: center;
          background-color: #fff;
          border-bottom: 1px solid #d4d5e2;
          border-radius: 2px;
          min-height: 60px;
          padding: 16px;

          .listFlex {
            display: flex;
            align-items: center;
            width: 100%;
          }

          &:nth-child(1) {
            border-bottom: 1px solid rgb(192, 195, 248);
          }

          &.headerItem {
            background-color: transparent;
            display: flex;
            text-transform: uppercase;
            @extend %fontRoundBold;
            padding: 8px 16px;
            min-height: 40px;
            color: #7f808e;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
          }

          & > span {
            display: flex;
            align-items: center;
          }

          .small {
            width: 115px;
          }

          .medium {
            flex: 2;
            min-width: 280px;
            max-width: 320px;
          }

          .large {
            flex: 3;
          }

          .name {
            color: #43444e;
            font-weight: bold;
            font-size: 16px;
            align-items: center;
            display: flex;

            svg {
              margin: 0 10px 0 2px;
              min-width: 24px;
            }
          }

          .contactsHeader {
            padding: 0 10px 0 0;
          }

          .contactsItem {
            display: flex;
            flex-wrap: wrap;
            padding-right: 10px;
            color: #43444e;
            font-size: 14px;

            svg {
              margin-right: 3px;
            }
          }

          .editItem {
            display: flex;
            justify-content: flex-end;
            width: 36px;
            height: 24px;

            svg {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .tableContainer {
      max-height: fit-content;

      .tableList {
        .itemsList {
          .listFlex {
            flex-direction: column;
          }

          .listItem:nth-child(1) {
            .registerAt {
              flex: 1;
              justify-content: flex-end;
            }

            .medium,
            .large {
              min-width: 100px;
            }

            .contactsHeader,
            .editItem {
              display: none;
            }
          }

          .listItem:not(:nth-child(1)) {
            min-height: 90px;
            position: relative;

            .medium,
            .large {
              min-width: 150px;
            }

            .name {
              align-self: flex-start;
            }

            .medium {
              min-width: auto;
              padding-right: 10px;
            }

            .contactsItem {
              align-self: flex-start;
              margin-top: 10px;
              margin-left: 38px;
            }

            .registerAt {
              width: 85px;
              position: absolute;
              right: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .tableContainer {
      .itemsCounter {
        margin-left: 16px;
      }
    }
  }
}
