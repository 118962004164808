@import "../../../styles/base.scss";

#ThesaurusDescription {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  height: 100%;

  .descriptionLabel {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-right: 8px;
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
  }

  #smallLoading {
    margin: 2px 0;
  }

  .descriptionContainer {
    padding: 30px 40px 55px 30px;
    background-color: #fff;
    height: 100%;

    h2 {
      font-size: 24px;
      color: #43444e;
      font-weight: normal;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: #7f808e;
    }
  }

  .SimpleScriptBox {
    h2 {
      margin-left: 0;
    }
  }
}
