.InfoPaper {
  background: #ffffff;
  padding: 30px;
  display: flex;
  margin-bottom: 1px;
  flex-wrap: wrap;
  position: relative;

  .ico {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  h2 {
    width: 100%;
  }

  .space {
    width: 100%;
    height: 30px;
  }

  .left-Info {
    width: 250px;
  }

  .right-info {
    width: calc(100% - 310px);
  }
}