@import "../../../styles/base.scss";

.boxFooter {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 50px;
  padding: 0 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e1e4fa;
  margin-top: auto;
}
