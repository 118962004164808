@import "../../../styles/base.scss";

.filterButtonContainer {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  .filterButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-bottom: 5px;
    }

    .filterText {
      font-size: 12px;
      color: #0098cd;
      text-transform: uppercase;
      @extend %fontRoundBold;
      margin-right: 8px;
    }
  }

  .filterMenu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 200px;
    width: fit-content;
    max-height: 250px;
    overflow-y: auto;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px,
      rgba(0, 0, 0, 0.12) 0px 6px 30px, rgba(0, 0, 0, 0.14) 0px 16px 24px;

    .filterOption {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #aabee766;
      height: fit-content;

      &:hover {
        background-color: #e4f8ff;
      }

      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .avatarOption {
        display: flex;
        align-items: center;

        .avatarIcon {
          margin-right: 8px;
          min-width: 28px;
          min-height: 28px;
          @extend %fontRoundBold;
        }

        .optionText {
          color: #7f808e;
          font-size: 14px;
          padding-top: 3px;
        }
      }

      .optionText {
        color: #7f808e;
        font-size: 14px;
        padding-top: 3px;
      }

      .checkBox {
        margin: 0;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        padding: 14px 16px;

        .label {
          margin-left: 0;
          padding-right: 8px;
          line-height: 18px;
          color: #7f808e;
          font-size: 14px;
        }
      }
    }
  }
}
