@import "../../../styles/base.scss";

.mobileSearch {
  display: none;

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    z-index: 3;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px;

    .backArrow {
      position: absolute;
      left: 10px;
      z-index: 3;
      top: 10px;
      height: 45px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    form {
      width: 100%;
      height: 100%;

      input {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0 60px 0 60px;
        border: none;
        outline: none;
        font-size: 18px;
        line-height: 24px;
        transition: 200ms ease-in-out;
        border: 2px solid #fff;
        font-family: "Globotipo Text";

        &:focus {
          border-color: #17b4e1;
          box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
          background-color: #fff;
          color: #1f1f1f;

          &::placeholder {
            color: #8e8fcf;
          }
        }

        &::placeholder {
          color: #c4c5cd;
          font-size: 18px;
        }
      }

      button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0px;
        top: 0;
        height: 100%;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        width: 64px;

        .searchIcon {
          width: 24px;
          height: 24px;
        }

        span {
          display: none;
        }
      }
    }
  }
}
