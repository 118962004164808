@import "../../../styles/base";

.ScriptBoxDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  .bgScriptBox {
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.7);
    z-index: 20;
  }

  .windowScriptBox {
    width: 768px;
    height: fit-content;
    background: #e2e5fb;
    position: fixed;
    border-radius: 4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 21;
    will-change: transform;
    transform: translateY(100vh);
    transition: 300ms cubic-bezier(0, 0.8, 0.8, 1);
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      min-height: 72px;
      background: $terciaryColor;
      display: flex;
      align-items: center;
      padding: 25px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      svg {
        min-width: 20px;
      }

      .scriptBoxTitleContainer {
        display: flex;
        flex-direction: column;

        h2 {
          font-size: 18px;
          @extend %fontRoundBold;
          color: #ffffff;
          margin: 5px 0 12px 0;
        }

        .scriptBoxSubTitle {
          color: #fff;
          font-size: 16px;
        }
      }

      .closeIcon {
        margin: 10px 0 10px auto;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .content {
      padding: 48px;
      position: relative;
      max-height: 85vh;
      overflow-y: scroll;

      &.removePadding {
        padding: 0;
      }
    }
  }

  &.active {
    .windowScriptBox {
      transform: translateY(0);
    }
  }

  &.large {
    .windowScriptBox {
      width: 960px;
    }
  }

  @media screen and (max-width: 1024px) {
    .windowScriptBox {
      width: 100%;
      border-radius: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
      overflow-y: scroll;

      .header {
        border-radius: 0;
      }

      .content {
        flex-grow: 1;
        max-height: 100vh;
      }
    }

    &.large {
      .windowScriptBox {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .windowScriptBox {
      .content {
        padding: 24px;
      }
    }
  }

  @media screen and (max-width: 340px) {
    .windowScriptBox {
      .header {
        h2 {
          font-size: 18px;
        }
      }
    }
  }
}
