@import "../../../../styles/base";

#NavigationThesarus {
  padding-bottom: 24px;
  position: relative;

  h2 {
    padding: 20px;
    color: #43444e;
    font-size: 24px;
    @extend %fontRegular;
    font-weight: 400;
  }

  .search {
    display: flex;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;

    .searchIcon {
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      color: $midnightBlue70;
      @extend %fontRoundBold;
    }
  }

  .contentListItemThesaurus {
    .titleMobile {
      svg {
        transition: 500ms;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0;

    .contentListItemThesaurus {
      width: calc(100% - 40px);
      overflow-y: hidden;
      height: 60px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 6px;
      position: absolute;
      left: 20px;
      top: 0;
      border: 2px solid #fff;
      z-index: 2;

      &.activeSelect {
        height: fit-content;
        border: 2px solid #17b4e1;
        box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);

        .contentAbsoluteListThesaurus {
          max-height: 320px;
          overflow-y: auto;
          padding: 10px 0;

          .ListItemsThesaurus {
            padding-left: 0;
            padding-right: 0;

            h3 {
              letter-spacing: 1px;
              padding-left: 21px;
              padding-right: 21px;
            }

            ul {
              .li-list {
                padding: 9px 21px;
                color: #575863;

                &:hover {
                  background-color: #dcf4fb;
                }
              }

              .activeThesaurus {
                background-color: #dcf4fb;
              }
            }
          }
        }

        .titleMobile {
          margin-bottom: 0;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
