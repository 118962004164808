@import "../../../styles/base";

#ThesaurusAdmin {
  padding-bottom: 36px;

  #Breadcrumb {
    padding: 0;

    .container {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;

      h2 {
        width: 100%;
        padding: 25px 0 20px 0;
        padding-left: 16px;
      }
    }
  }

  .contentTypes {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
  }

  .ThesaurusTypeNavigation {
    margin-top: 10px;
  }

  .mainThesarus {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    .blockLeft {
      width: 270px;
      height: fit-content;
      background: $midnightBlue10;
      border-radius: 4px;
      padding-bottom: 20px;

      .titleMobile {
        display: none;
      }
    }

    .blockRight {
      width: calc(100% - 300px);
      position: relative;

      .loadingCircleContainer {
        height: auto;
      }

      .containerThesaurus {
        background-color: #fff;
        padding: 24px 30px;
        border-radius: 4px;

        .blockRightIcons {
          display: flex;
          position: absolute;
          top: 24px;
          right: 24px;

          .updateOrigins,
          .registerThesaurus {
            cursor: pointer;
            margin-left: 20px;
          }
        }

        h3 {
          color: #43444e;
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 20px;
        }

        .thesaurusList {
          column-gap: 50px;
          margin-bottom: 12px;
          height: auto;
          padding-right: 32px;

          &.twoColumns {
            column-count: 2;
          }

          &.threeColumns {
            column-count: 3;
          }

          .thesaurusItem {
            display: flex;
            align-items: center;
            color: $cyan50;
            padding: 6px;
            margin-bottom: 8px;
            cursor: pointer;
            width: fit-content;
            page-break-inside: avoid;
            word-break: break-word;

            svg {
              margin-right: 7px;
              min-width: 15px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1140px) {
    .mainThesarus {
      .blockRight {
        .containerThesaurus {
          .thesaurusList {
            padding-right: 0;

            &.threeColumns {
              column-count: 2;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    #Breadcrumb {
      .container {
        h2 {
          padding: 20px;
        }
      }
    }

    .separator {
      display: none;
    }

    .mainThesarus {
      width: 100%;
      flex-wrap: wrap;

      .blockLeft {
        width: 100%;
        background: none;

        .titleMobile {
          font-size: 16px;
          color: $midnightBlue40;
          border-bottom: 1px solid $midnightBlue20;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          height: 58px;

          svg {
            margin-right: 10px;
          }

          h3 {
            width: calc(100% - 30px);
            padding: 20px;
            font-size: 18px;
            font-weight: 300;
            color: #000;
          }
        }
      }

      .blockRight {
        width: calc(100% - 40px);
        margin: 0 auto;

        .containerThesaurus {
          .thesaurusList {
            flex-wrap: wrap;
            font-size: 18px;
            color: #0098cd;

            svg {
              display: none;
            }

            &.twoColumns,
            &.threeColumns {
              column-count: 1;
            }

            .thesaurusItem {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.separator {
  width: 100%;
  border-bottom: 1px solid $midnightBlue20;
}
