@import '../../../../styles/base.scss';

#FilteredReleased {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;

  h2 {
    @extend %fontLight;
    font-weight: 300;
    font-size: 20px;
    color: #262626;
    margin-right: 10px;
  }

  .tags {
    display: flex;
  }
}
