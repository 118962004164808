@import "../../../styles/base.scss";

.customDatepicker {
  position: relative;
  width: 210px;
  height: fit-content;

  .datepickerGlass {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: -1;
    transform: scale(10);
  }

  .datepickerLabel {
    color: $terciaryColor;
    @extend %fontRoundBold;
    letter-spacing: 0rem;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bolder;
    display: block;
    margin-bottom: 8px;
    z-index: 10;
  }

  .withPortalVersion {
    background-color: #fff;
    border-radius: 4px;

    svg {
      position: absolute;
      right: 15px;
      top: 38px;
      z-index: 1;
    }

    &.darkBackground {
      background-color: #e2e4ff;
    }

    div {
      .react-datepicker__input-container {
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: transparent;
        border-radius: 4px;

        input {
          width: 100%;
          height: 60px;
          background-color: transparent;
          border: 2px solid #fff;
          border-radius: 4px;
          padding: 8px 55px 5px 18px;
          outline: none;
          cursor: pointer;
          transition: 300ms ease-in-out;
          font-size: 18px;
          z-index: 5;

          &:focus {
            border: 2px solid #17b4e1;
            box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
          }
        }
      }

      .react-datepicker__portal {
        background-color: rgba(255, 255, 255, 0.8);

        div {
          .react-datepicker {
            border-radius: 3px;
            border: 1px solid #e5e5e9;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 0 20px 20px 20px;
            position: relative;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

            .react-datepicker__triangle {
              display: none;
            }

            .react-datepicker__navigation--previous {
              top: 0;
              left: 0px;
              background: url(../../../assets/icons/arrow.png) no-repeat center
                right;
              width: 35px;
              height: 48px;
              border: 1px solid #fff;
              background-color: #fff;
              outline: none;
            }

            .react-datepicker__navigation--next {
              right: 130px;
              top: 0;
              background: url(../../../assets/icons/arrow.png) no-repeat center;
              width: 35px;
              height: 48px;
              border: 1px solid #fff;
              background-color: #fff;
              outline: none;
              transform: rotate(180deg);
            }

            .react-datepicker__month-container {
              width: 100%;
              height: 100%;

              .react-datepicker__header {
                border-bottom: none;
                background-color: #fff;
                padding-top: 50px;

                .react-datepicker__current-month {
                  background-color: #fff;
                  @extend %fontRegular;
                  font-weight: 400;
                  text-transform: capitalize;
                  color: #484848;
                  font-size: 18px;
                  text-align: center;
                  display: inline-block;
                  width: 192px;
                  height: 50px;
                  position: absolute;
                  left: -20px;
                  bottom: 33px;
                  padding: 15px;
                  border-bottom: 1px solid #ccc;
                  border-top-left-radius: 3px;
                  user-select: none;
                }

                .react-datepicker__header__dropdown {
                  background-color: #fff;
                  display: inline-block;
                  width: 127px;
                  height: 50px;
                  color: #484848;
                  font-size: 18px;
                  text-align: left;
                  font-weight: 400;
                  @extend %fontRegular;
                  border-bottom: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  padding: 15px;
                  position: absolute;
                  right: -20px;
                  bottom: 33px;
                  border-top-right-radius: 3px;
                  user-select: none;

                  .react-datepicker__year-dropdown-container {
                    width: 125px;
                    height: 48px;
                    position: relative;
                    bottom: 14px;
                    right: 17px;
                    display: flex;
                    align-items: center;

                    .react-datepicker__year-dropdown {
                      border: 1px solid $secondaryColor;
                      background-color: #fff;
                      top: 49px;
                      border-radius: 0;
                      width: 127px;
                      height: 200px;
                      left: -1px;
                      animation: fadeIn 300ms ease-in-out;

                      @keyframes fadeIn {
                        0% {
                          opacity: 0;
                        }
                        100% {
                          opacity: 1;
                        }
                      }

                      .react-datepicker__year-option {
                        display: flex;
                        align-items: center;
                        width: 109px;
                        height: 30px;
                        margin: 0;
                        border-radius: 0;
                        padding-left: 18px;

                        &:hover {
                          background-color: $secondaryColor;
                          color: #fff;
                        }

                        &:last-of-type {
                          display: none;
                        }

                        .react-datepicker__year-option--selected {
                          display: none;
                        }

                        .react-datepicker__navigation--years-previous {
                          display: none;
                        }
                      }
                    }

                    .react-datepicker__year-read-view {
                      visibility: visible !important;
                      width: 125px;
                      height: 48px;
                      display: flex;
                      align-items: center;
                      padding-left: 17px;

                      .react-datepicker__year-read-view--down-arrow {
                        display: none;
                      }
                    }
                  }
                }

                .react-datepicker__day-names {
                  background-color: #fff;
                  user-select: none;
                  margin-bottom: 2px;

                  .react-datepicker__day-name {
                    width: 39px;
                    height: 32px;
                    margin: 0;
                    padding: 5px 0;
                    color: #757575;
                    background-color: #fff;
                    @extend %fontRegular;
                  }
                }
              }

              .react-datepicker__month {
                margin: 0;
                padding-top: 1px;

                .react-datepicker__week {
                  display: flex;

                  .react-datepicker__day {
                    width: 40px;
                    height: 38px;
                    margin: 0;
                    border: 1px solid #e4e7e7;
                    @extend %fontRegular;
                    padding: 5px 0;
                    outline: none;
                    border-radius: 0;
                    margin: -1px 0 0 -1px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                      background-color: #e4e7e7;
                    }
                  }

                  .react-datepicker__day--today {
                    background-color: #fff;
                    border-radius: 0px;
                    font-weight: normal;
                  }

                  .react-datepicker__day--selected {
                    background-color: $secondaryColor;
                    color: #fff;
                    border-radius: 0px;
                    font-weight: normal;
                  }

                  .react-datepicker__day--keyboard-selected {
                    background-color: $secondaryColor;
                    border-radius: 0px;
                  }

                  .react-datepicker__day--outside-month {
                    color: #cacccd;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .inputBackground {
    background-color: #fff;
    border-radius: 4px;

    &.darkBackground {
      background-color: #e2e4ff;
    }

    &.isError {
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            border-bottom-color: $errorColor;
          }
        }
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
      height: 100%;
      z-index: 10;

      .react-datepicker__input-container {
        width: 100%;
        height: 100%;
        z-index: 3;
        // background-color: #fff;
        background-color: transparent;
        border-radius: 4px;

        input {
          width: 100%;
          height: 60px;
          background-color: transparent;
          border: 2px solid #fff;
          border-radius: 4px;
          padding: 8px 55px 5px 18px;
          outline: none;
          cursor: pointer;
          transition: 300ms ease-in-out;
          font-size: 18px;
          z-index: 5;

          &:focus {
            border: 2px solid #17b4e1;
            box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
          }
        }
      }
    }

    &.darkBackground {
      .react-datepicker-wrapper {
        width: 100%;
        height: 100%;
        z-index: 10;

        .react-datepicker__input-container {
          background-color: #e2e4ff;

          input {
            border-color: #e2e4ff;

            &:focus {
              border: 2px solid #17b4e1;
            }
          }
        }
      }
    }

    svg {
      position: absolute;
      right: 15px;
      top: 38px;
      z-index: 1;
    }

    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        border: none;
        margin-top: 0;
        min-width: 319px;
        height: 322px;
        left: auto !important;
        right: 0 !important;
        z-index: 3;
        margin-bottom: 0;
        transform: translate3d(0px, 82px, 0px) !important;

        & > div {
          width: 100%;
          height: 100%;
          border-top: 1px solid #e5e5e9;
          border-radius: 3px;

          .react-datepicker {
            border-radius: 3px;
            border: 1px solid #e5e5e9;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 0 20px 20px 20px;
            position: relative;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

            .react-datepicker__triangle {
              display: none;
            }

            .react-datepicker__navigation--previous {
              top: 0;
              left: 0px;
              background: url(../../../assets/icons/arrow.png) no-repeat center
                right;
              width: 35px;
              height: 48px;
              border: 1px solid #fff;
              background-color: #fff;
              outline: none;
            }

            .react-datepicker__navigation--next {
              right: 130px;
              top: 0;
              background: url(../../../assets/icons/arrow.png) no-repeat center;
              width: 35px;
              height: 48px;
              border: 1px solid #fff;
              background-color: #fff;
              outline: none;
              transform: rotate(180deg);
            }

            .react-datepicker__month-container {
              width: 100%;
              height: 100%;

              .react-datepicker__header {
                border-bottom: none;
                background-color: #fff;
                padding-top: 50px;

                .react-datepicker__current-month {
                  background-color: #fff;
                  @extend %fontRegular;
                  font-weight: 400;
                  text-transform: capitalize;
                  color: #484848;
                  font-size: 18px;
                  text-align: center;
                  display: inline-block;
                  width: 192px;
                  height: 50px;
                  position: absolute;
                  left: -20px;
                  bottom: 33px;
                  padding: 15px;
                  border-bottom: 1px solid #ccc;
                  border-top-left-radius: 3px;
                  user-select: none;
                }

                .react-datepicker__header__dropdown {
                  background-color: #fff;
                  display: inline-block;
                  width: 127px;
                  height: 50px;
                  color: #484848;
                  font-size: 18px;
                  text-align: left;
                  font-weight: 400;
                  @extend %fontRegular;
                  border-bottom: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  padding: 15px;
                  position: absolute;
                  right: -20px;
                  bottom: 33px;
                  border-top-right-radius: 3px;
                  user-select: none;

                  .react-datepicker__year-dropdown-container {
                    width: 125px;
                    height: 48px;
                    position: relative;
                    bottom: 14px;
                    right: 17px;
                    display: flex;
                    align-items: center;

                    .react-datepicker__year-dropdown {
                      border: 1px solid $secondaryColor;
                      background-color: #fff;
                      top: 49px;
                      border-radius: 0;
                      width: 127px;
                      height: 200px;
                      left: -1px;
                      animation: fadeIn 300ms ease-in-out;

                      @keyframes fadeIn {
                        0% {
                          opacity: 0;
                        }
                        100% {
                          opacity: 1;
                        }
                      }

                      .react-datepicker__year-option {
                        display: flex;
                        align-items: center;
                        width: 109px;
                        height: 30px;
                        margin: 0;
                        border-radius: 0;
                        padding-left: 18px;

                        &:hover {
                          background-color: $secondaryColor;
                          color: #fff;
                        }

                        &:last-of-type {
                          display: none;
                        }

                        .react-datepicker__year-option--selected {
                          display: none;
                        }

                        .react-datepicker__navigation--years-previous {
                          display: none;
                        }
                      }
                    }

                    .react-datepicker__year-read-view {
                      visibility: visible !important;
                      width: 125px;
                      height: 48px;
                      display: flex;
                      align-items: center;
                      padding-left: 17px;

                      .react-datepicker__year-read-view--down-arrow {
                        display: none;
                      }
                    }
                  }
                }

                .react-datepicker__day-names {
                  background-color: #fff;
                  user-select: none;
                  margin-bottom: 2px;

                  .react-datepicker__day-name {
                    width: 39px;
                    height: 32px;
                    margin: 0;
                    padding: 5px 0;
                    color: #757575;
                    background-color: #fff;
                    @extend %fontRegular;
                  }
                }
              }

              .react-datepicker__month {
                margin: 0;
                padding-top: 1px;

                .react-datepicker__week {
                  .react-datepicker__day {
                    width: 40px;
                    height: 38px;
                    margin: 0;
                    border: 1px solid #e4e7e7;
                    @extend %fontRegular;
                    padding: 5px 0;
                    outline: none;
                    border-radius: 0;
                    margin: -1px 0 0 -1px;

                    &:hover {
                      background-color: #e4e7e7;
                    }
                  }

                  .react-datepicker__day--today {
                    background-color: #fff;
                    border-radius: 0px;
                    font-weight: normal;
                  }

                  .react-datepicker__day--selected {
                    background-color: $secondaryColor;
                    color: #fff;
                    border-radius: 0px;
                    font-weight: normal;
                  }

                  .react-datepicker__day--keyboard-selected {
                    background-color: $secondaryColor;
                    border-radius: 0px;
                  }

                  .react-datepicker__day--outside-month {
                    color: #cacccd;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.focused {
  z-index: 25 !important;
}

.disabled {
  opacity: 0.65;
}
