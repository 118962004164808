@import "../../../../styles/base.scss";

#EditSheetComplement {
  padding-bottom: 20px;

  .formField {
    .formFieldColumn {
      width: calc(50% - 20px);
    }
  }

  .formFieldColumn {
    margin-bottom: 25px;
    flex-direction: column;

    .editSelect__menu {
      z-index: 5;
    }

    .fieldLabel {
      color: $terciaryColor;
      @extend %fontRegular;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      display: block;
      margin-bottom: 8px;
    }

    .contentLabelIcon {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      svg {
        cursor: pointer;
        margin-top: -3px;
      }

      .fieldLabel {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    .formField {
      .associateFieldset {
        height: 66px;
        margin-bottom: 25px;

        .fieldset {
          margin-bottom: 0;
        }
      }

      .contentOriginContainer {
        display: flex;
        width: 100%;
        min-height: 60px;
        background-color: #fff;
        margin-bottom: 25px;
        border-radius: 4px;
        position: relative;

        .fieldset {
          margin-bottom: 0;
          width: 350px;

          .inputContainer {
            height: 64px;

            select {
              min-height: 64px;
            }
          }

          &::after {
            content: "";
            display: block;
            width: 1px;
            height: 80%;
            background-color: #aabee7;
            right: 0;
            bottom: 10%;
            position: absolute;
            opacity: 0.3;
            z-index: 2;
          }
        }

        &.showDeleteIcon {
          padding-right: 36px;

          .deleteIcon {
            height: 100%;
            width: 36px;
            background-color: #fff1f2;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 16;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            cursor: pointer;

            &:hover {
              background-color: #e5c2c4;
            }
          }
        }
      }
    }

    .dashedBox {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed rgba(170, 190, 231, 0.5);
      height: 44px;
      margin: 0 0 35px 0;

      span {
        display: block;
        text-align: center;
        font-size: 14px;
        color: #38a86e;
        @extend %fontRoundBold;
        cursor: pointer;
      }
    }
  }

  .formField {
    &.storylineField {
      width: 100%;
    }
  }

  .newSimpleSelectFieldset {
    margin-bottom: 25px;
  }

  .boxFooter {
    z-index: 15;
    position: fixed;
    background-color: #e2e5fb;
  }

  @media screen and (max-width: 768px) {
    .formField {
      flex-direction: column;

      .formFieldColumn {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .formFieldColumn {
      .formField {
        .associateFieldset {
          height: 130px;
        }

        .contentOriginContainer {
          flex-direction: column;
          min-height: 120px;
          justify-content: flex-end;

          .fieldset {
            min-width: 100%;
            width: 100%;

            &::after {
              right: auto;
              bottom: 0;
              height: 1px;
              width: 95%;
              left: 2.5%;
            }
          }
        }
      }
    }
  }
}
