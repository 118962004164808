@import "../../../../../styles/base.scss";

.noAttachments {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 40px 16px;

  .noAttachmentsMessage {
    @extend %fontRoundBold;
    color: #565764;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
  }
}
