@import "../../../../styles/base.scss";

.searchResultsTable {
  width: 100%;

  .searchListItem {
    min-height: 64px;
    background-color: #fff;
    border-radius: 2px;
    display: flex;
    width: 100%;
    margin-bottom: 1px;
    padding: 22px 30px 22px 20px;
    flex-direction: column;
    position: relative;

    .searchListContentInfo {
      display: flex;
      word-break: break-all;

      .typeIcon {
        .typeIconReturner {
          min-width: 30px;
          width: 30px;
          height: 30px;
          margin-right: 8px;
          margin-bottom: 2px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .contentTitle {
        display: flex;
        align-items: center;
        padding-right: 16px;
        line-height: 22px;
        width: calc(100% - 100px);
        flex-wrap: wrap;

        .titleText {
          text-decoration: none;
          color: #000;
          font-size: 18px;

          .markMatch {
            text-decoration: none;
            font-weight: bold;
            background-color: transparent;
          }
        }

        .contentCreatorSeparator {
          width: 1px;
          height: 18px;
          background-color: #0c0c0c;
          margin: 0 6px;
        }
      }
    }

    .contentDate {
      font-size: 14px;
      width: 90px;
      padding-right: 0;
      position: absolute;
      top: 30px;
      right: 22px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }

    .info {
      padding-right: 16px;
      align-items: center;
      display: flex;
      position: relative;
    }
  }

  @media screen and (max-width: 768px) {
    .searchListItem {
      .searchListContentInfo {
        .contentTitle {
          .typeIconReturner {
            min-width: 30px;
            width: 30px;
            height: 30px;
            margin-right: 8px;
            margin-bottom: 2px;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          .titleText {
            text-decoration: none;
            color: #000;
            font-size: 18px;
          }
        }
      }
    }
  }
}
