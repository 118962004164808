@import "../../../../../styles/base.scss";

.creatorName {
  color: #7f808e;
  text-transform: uppercase;
  margin: 2px 0 2px 0;

  .separator {
    margin: 0 4px;
    border-bottom: none;
  }

  .markMatch {
    margin-right: 0;
    text-decoration: none;
    font-weight: bold;
    background-color: transparent;
  }
}
