@import '../../../../../styles/base.scss';

#ReleaseTextsModal {
  display: flex;
  flex-direction: column;
  height: 100%;

  .releaseTextsModalContent {
    padding: 30px;
    overflow: auto;
    max-height: 450px;

    .switchBlock {
      margin-bottom: 25px;
    }
  
    .textarea {
      margin-top: 25px;
      margin-bottom: 0;
  
      textarea {
        font-size: 16px;
      }
    }
  
    .contentReleaseTextInfo {
      margin-bottom: 25px;
    }
  
    .optionalText {
      display: block;
      margin: 6px 0 20px 0;
      line-height: 16px;
      font-size: 11px;
      letter-spacing: 0.1em;
      color: #575863;
      display: block;
      padding-left: 2px;
    }
  }

  .releaseTextsFooter {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0 28px;
    min-height: 72px;
    background-color: $midnightBlue0;
    box-shadow: 0 -10px 10px 0 #00000019;
    z-index: 2;

    .buttonCancel {
      background-color: transparent;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: calc(100% - 72px);

    .releaseTextsModalContent {
      min-height: 100%;
      padding: 30px 16px;
    }
  }
}
