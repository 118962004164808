@import "../../../../styles/base.scss";

.ListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 20px 10px;
  border-bottom: 1px solid #e2e4ff;
  position: relative;

  li {
    color: #000000;
    font-size: 14px;
    margin-top: 3px;
    font-weight: bold;
    @extend %fontRegular;
    display: flex;
    justify-content: center;
    align-items: normal;
    width: fit-content;

    &.title {
      width: calc(100% - 480px);

      .titleContent {
        display: flex;
      }

      .iconType {
        width: 30px;
        display: flex;
        align-items: baseline;
        margin-right: 10px;

        .typeIconReturner {
          margin-right: 0;
          display: flex;
          justify-content: center;
          align-items: baseline;
          border-radius: initial;
          width: inherit;
          height: inherit;
          min-width: fit-content;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .contentText {
        display: flex;
        width: calc(100% - 40px);
        flex-wrap: wrap;

        div {
          a {
            color: #000;
            text-decoration: none;
          }
        }

        .title {
          width: 100%;
          font-size: 18px;
          padding: 0;
          margin-bottom: 5px;
          font-weight: 500;

          .titleContent {
            .markMatch {
              text-decoration: none;
              font-weight: bold;
              background-color: transparent;
              @extend %fontRoundBold;
            }
          }

          .creator {
            span {
              color: #7f808e;
              font-size: 14px;
              @extend %fontRegular;
              text-transform: uppercase;
            }
          }
        }

        .storylineContent {
          display: flex;
          max-width: 100%;
          position: relative;

          .scriptBoxCaller {
            position: relative;
            margin-left: 2px;
            top: unset;
          }

          .storyline {
            display: flex;
            font-size: 14px;
            @extend %fontLight;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            position: relative;

            .markMatch {
              text-decoration: none;
              font-weight: bold;
              background-color: transparent;
              @extend %fontRoundBold;
            }
          }

          a {
            color: $secondaryColor;
            text-decoration: none;
            margin-left: 4px;
          }
        }
      }
    }

    &.date {
      width: 100px;
    }

    &.status {
      width: 150px;
    }

    &.analise {
      width: 160px;

      &.red {
        color: #db1a33;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 64px;

    li {
      justify-content: flex-start;
      width: 100%;

      &.title {
        width: 100%;

        .titleContent {
          flex-direction: column;
        }
      }

      &.date {
        position: absolute;
        bottom: 40px;
        right: 16px;
        justify-content: flex-end;
      }

      &.status {
        position: absolute;
        bottom: 16px;
      }

      &.analise {
        position: absolute;
        bottom: 16px;
        right: 16px;
        justify-content: flex-end;
      }
    }
  }
}
