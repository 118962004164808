#ContentCreateProspects {
  .reactSelect {
    position: relative;
  }

  & > .errorIcon {
    width: fit-content;
    position: absolute;
    right: 18px;
    top: 42px;
  }

  &.isError {
    & > .reactSelect {
      .reactSelect__control {
        border-bottom: 2px solid red;
      }

      .reactSelect__control--is-focused {
        border-color: #17b4e1;
      }
    }
  }

  .SimpleScriptBox {
    .content {
      padding: 24px;
      margin-bottom: 72px;
      
      div {
        .fieldset {
          margin-bottom: 25px;
        }

        .createbleFieldset {
          margin-bottom: 25px;
        }
      }
    }
  }
}
