@import "../../../styles/base.scss";

#Footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 80px;

  svg {
    margin: 20px 0 32px 0;
  }
}
