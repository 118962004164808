@import "../../../../styles/base.scss";

.statusColorBall {
  min-width: 11px;
  max-width: 11px;
  min-height: 11px;
  max-height: 11px;
  margin-right: 12px;
  border-radius: 50%;
}
