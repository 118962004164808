@import "../../../../../styles/base.scss";

.deleteTooltip {
  display: flex;
  flex-direction: column;

  p {
    text-align: left;
    line-height: 20px;

    strong {
      color: #fff;
    }
  }
}
