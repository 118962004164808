@import "../../../../styles/base.scss";

#ListHeader {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @extend %fontRoundBold;

  .format {
    min-width: 140px;
    position: relative;
    font-size: 13px;

    .formatFilter {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $cyan50;

      & > svg {
        margin-left: 4px;
        position: relative;
        bottom: 2px;
      }
    }

    .containerFilter {
      left: unset;
      right: 0;
    }
  }

  .lastRelease {
    .reorderButton {
      .reorderText {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding-top: 16px;
    justify-content: space-between;
    width: 100%;

    .format {
  
      .containerFilter {
        left: 0;
        right: unset;
      }
    }
  }
}
