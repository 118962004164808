@import "../../../../styles/base.scss";

.scopeInformation {
  padding: 32px 34px 32px 34px;

  &.showBreadcrumb {
    padding-top: 0;
  }

  .scopeName {
    display: block;
    font-size: 24px;
    color: #43444e;
    margin-bottom: 8px;
  }

  .scopeDescription {
    font-size: 16px;
    color: #7f808e;
    line-height: 1.5;
    margin-bottom: 20px;
    display: block;
  }

  .scopeRemissives {
    .remissivesTitle {
      font-size: 14px;
      text-transform: uppercase;
      color: #565763;
      @extend %fontRoundBold;
    }

    & > ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $alertColorSecondary;
        color: $alertTextColor;
        border-radius: 18px;
        padding: 8px 16px;
        margin: 8px 8px 0 0;

        &.searchTerm {
          @extend %fontRoundBold;
        }
      }
    }
  }

  .scopeButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;

    .buttonGreen {
      margin-left: 10px;
    }

    .selectScopeButton {
      position: relative;

      .tooltip {
        .tipBalloon {
          min-height: 100%;
          min-width: 100%;
          position: absolute;

          .tip {
            min-height: 100%;
            min-width: 100%;
            position: absolute;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 32px 16px 32px 16px;

    .scopeButtons {
      justify-content: space-between;

      .simpleButton,
      .selectScopeButton {
        width: calc(50% - 8px);

        .simpleButton {
          width: 100%;
        }

        .buttonGreen {
          margin-left: 0;
        }
      }

      .simpleButton {
        display: flex;
        justify-content: center;
      }
    }
  }
}
