@import "../../../../styles/base.scss";

.charactersTable {
  display: flex;
  flex-direction: column;
  width: 100%;

  .noCharactersCreated {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .noCharactersMessage {
      color: #7070b3;
      font-size: 14px;
    }
  }

  .charactersListItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 2px, rgb(218, 221, 254) 0 1px 0 inset;

    .charactersItem {
      display: flex;
      width: 100%;
      background-color: #fff;
      min-height: 56px;
      height: fit-content;
      padding: 16px;
      border-radius: 2px;
      align-items: center;

      &.opacity {
        background-color: rgba(255, 255, 255, 0.5);
        color: rgba(0, 0, 0, 0.4);

        > div:not(.characterOptions) {
          opacity: 0.6;
        }

        .characterOptions {
          .overflowMenu {
            background-color: transparent;
            color: #000;
          }
        }
      }

      .characterNames {
        width: 225px;
        display: flex;
        flex-wrap: wrap;
        padding-right: 16px;

        .characterName {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-right: 6px;

          .name {
            @extend %fontRoundBold;
            margin-right: 5px;
            line-height: 24px;
          }

          .nickName {
            color: #43444e;
            font-size: 16px;
            @extend %fontRegular;

            &::before {
              content: "(";
            }

            &::after {
              content: ")";
            }
          }
        }

        .characterSeparator {
          display: flex;
          align-items: center;
          margin: 0 6px;
          color: #43444e;
        }
      }

      .talentNames {
        flex: 1;
        min-width: 175px;
        font-size: 16px;
        padding-right: 16px;
        color: #43444e;

        .talentName {
          span {
            display: inline;

            &:first-of-type {
              @extend %fontRoundBold;
            }
          }

          .characterSeparator {
            display: inline;
            margin: 0 6px;
            color: #43444e;
          }
        }
      }

      .characterRelevance {
        min-width: 95px;
        flex: 1;
        display: flex;
        align-items: center;

        .relevanceType {
          margin-left: 5px;
        }
      }

      .characterWeight {
        width: 66px;
      }

      .characterDetails {
        width: 215px;

        .detailsTags {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          height: fit-content;
          padding-right: 12px;

          .detailTag {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 5px 5px 6px;
            font-size: 16px;
            min-width: fit-content;
            word-wrap: inherit;
            word-break: break-all;
            min-height: 24px;
            line-height: 20px;
            margin: 4px;
            border-radius: 4px;
            border: solid 1px #aaf0fd;
            background-color: #e4fbff;
            color: #43444e;
          }
        }
      }

      .characterDescription {
        width: 215px;
        position: relative;
        line-height: 24px;
        word-break: break-all;
        padding-right: 16px;

        .scriptBoxCaller {
          position: relative;
          font-weight: 200;
          color: $secondaryColor;
          display: inline-block;
          top: 0;
        }
      }

      .characterOptions {
        width: 32px;

        .overflowMenu {
          width: 32px;
          height: 32px;
          background-color: #fff;

          &.active {
            background-color: #fff;
          }

          .optionItem {
            display: flex;
            align-items: center;
            padding: 0 16px;
            height: 60px;

            &:hover {
              background-color: #e4f8ff;
            }

            svg {
              width: 32px;
              margin: 0 16px 0 4px;
            }
          }
        }
      }
    }
  }

  .charactersTableHeader {
    display: flex;
    height: 40px;
    padding: 0 16px;
    width: 100%;

    .charactersItemHeader {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #575863;
      text-transform: uppercase;
      @extend %fontRoundBold;

      &.characterName {
        width: 225px;
      }

      &.talentNames {
        flex: 1;
        min-width: 175px;
      }

      &.characterRelevance {
        min-width: 95px;
        flex: 1;
      }

      &.characterWeight {
        width: 66px;
      }

      &.characterDetails {
        width: 215px;
      }

      &.characterDescription {
        width: 215px;
        padding-right: 16px;
      }

      &.characterOptions {
        width: 32px;
      }
    }
  }

  .boxParagraph {
    word-break: normal;
  }

  @media screen and (max-width: 1024px) {
    .charactersTableHeader {
      .charactersItemHeader {
        &.characterName {
          width: 215px;
        }

        &.talentNames,
        &.characterWeight,
        &.characterDetails,
        &.characterDescription,
        &.characterOptions {
          display: none;
        }

        &.characterRelevance {
          min-width: 95px;
          flex: 1;
          justify-content: flex-end;
        }
      }
    }

    .charactersListItems {
      .charactersItem {
        border-radius: 0;
        position: relative;
        min-height: 192px;
        flex-direction: column;
        align-items: flex-start;

        .characterNames {
          width: 85%;
          margin-bottom: 10px;
          padding-right: 40px;
        }

        .talentNames {
          width: 90%;
          max-height: fit-content;
          min-height: 24px;
          margin-bottom: 10px;
        }

        .characterDetails {
          width: 90%;
          margin-bottom: 16px;
          position: relative;
          left: -3px;
        }

        .characterDescription {
          width: 90%;
          height: fit-content;
          margin-bottom: 14px;
        }

        .characterWeight {
          position: absolute;
          right: 16px;
          top: 52px;
          display: flex;
          justify-content: flex-end;
        }

        .characterRelevance {
          position: absolute;
          right: 16px;
          top: 16px;
          justify-content: flex-end;
        }

        .characterOptions {
          position: absolute;
          right: 5px;
          bottom: 16px;
        }
      }
    }
  }
}
