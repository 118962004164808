@import "../../../../../styles/base.scss";

.addAttachmentsModal {
  display: flex;
  flex-direction: column;

  .itemsCounter {
    padding-left: 16px;
  }

  .listAttachments {
    .attachmentsHeader {
      background-color: transparent;
      padding-left: 16px;
      padding-right: 16px;

      .type {
        max-width: 200px;
      }

      .version {
        margin: 0 24px;
      }
    }

    .attachmentItem {
      .mainVersionItem {
        padding-left: 16px;
        padding-right: 16px;

        .type {
          max-width: 200px;
        }

        .version {
          margin: 0 24px;
        }
      }

      &.opened {
        .versionsList {
          .versionItem {
            padding-left: 16px;
            padding-right: 16px;

            .type {
              max-width: 200px;
            }

            .version {
              margin: 0 24px;
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    background-color: #575863;
    padding: 0 12px;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    border-radius: 0;

    .unmarkContainer {
      display: flex;
      align-items: center;

      .unmarkButton {
        background-color: $cyan50;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-self: center;
        cursor: pointer;

        div {
          margin: auto;
          width: 9px;
          height: 3px;
          background-color: #fff;
        }
      }
    }

    span {
      font-size: 16px;
      color: #fff;
      @extend %fontRoundBold;
      margin: 0 12px;
      padding-top: 2px;
    }
  }

  @media screen and (max-width: 1024px) {
    .listAttachments {
      .attachmentsHeader {
        padding-right: 16px;

        .type {
          max-width: 100%;
        }
      }

      .attachmentItem {
        .mainVersionItem {
          padding-right: 16px;

          .type {
            max-width: 100%;
          }
        }

        &.opened {
          .versionsList {
            .versionItem {
              padding-right: 16px;

              .type {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    .listAttachments {
      .attachmentsHeader {
        .version {
          margin: 0;
        }
      }

      .attachmentItem {
        .mainVersionItem {
          .version {
            margin: 0;
          }
        }

        &.opened {
          .versionsList {
            .versionItem {
              .version {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
