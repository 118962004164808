@import "../../../styles/base.scss";

#ExternalProjects {
  .externalProjectsContainer {
    max-width: 1120px;
    margin: 0 auto;

    .noResults {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 80px;
      text-align: center;
      padding: 0 16px;
      height: fit-content;

      svg {
        margin-bottom: 12px;
      }

      .mainText {
        font-size: 18px;
        @extend %fontRoundBold;
        margin: 12px 0;
        color: $midnightBlue70;
      }

      .subText {
        font-size: 16px;
        color: $midnightBlue70;
      }
    }
  }
}
