@import "../../../../styles/base.scss";

#ActivitiesAttachmentsList {
  background-color: #fff;
  position: relative;

  .sheetActivityItem {
    background-color: #fff;
    position: relative;

    .cardContent {
      background-color: #fff;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
