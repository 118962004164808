.containerCkEditor {
  ul {
    list-style: square inside;

    li {
      list-style: square inside;
      color: black;
      padding: 5px;
    }
  }

  ol {
    list-style: decimal;

    li {
      list-style: decimal;
      margin-left: 25px;
    }
  }

  p {
    margin-bottom: 20px;
  }
}
