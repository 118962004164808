@import "../../../../../styles/base.scss";

.creatorAndResearcher {
  font-size: 14px;
  padding-right: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}
