@import "../../../styles/base.scss";

.fieldset {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: none;
  padding: 0;
  margin-bottom: 25px;
  position: relative;

  label {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }

  .inputContainer {
    position: relative;

    input[type="text"],
    input[type="password"],
    textarea {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      line-height: 24px;
      font-size: 18px;
      color: rgb(31, 31, 31);
      background: rgb(255, 255, 255);
      border-radius: 4px;
      padding: 16px 64px 16px 12px;
      transition: border 200ms ease-out 0s;
      border-width: 2px;
      border-style: solid;
      border-color: transparent transparent rgb(255, 255, 255);
      border-image: initial;
      border-bottom: 2px solid rgb(255, 255, 255);
      outline: none;

      &::placeholder {
        color: $placeholderColor;
      }

      &:focus {
        border: 2px solid #17b4e1;
        box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
      }

      &.error {
        border-bottom-color: $errorColor;

        &:focus {
          border: 2px solid #17b4e1;
        }
      }
    }

    &.darkBackgroundColor {
      input {
        background-color: #e2e4ff;
        border-bottom: 2px solid #e2e4ff;

        &::placeholder {
          color: #1f1f1f;
        }
      }
    }

    textarea {
      resize: none;
      padding-right: 12px;
    }

    .errorMessage {
      display: block;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.1em;
      margin-top: 8px;
      color: $errorColor;
      padding-left: 2px;
      font-weight: 500;
    }

    .errorIcon {
      position: absolute;
      right: 20px;
      top: 17px;
    }
  }
}
