@import "../../../../styles/base.scss";

.feedbackForm {
  margin-top: 16px;

  .feedbackLabel {
    padding-right: 36px;
    display: flex;
    align-items: center;
    @extend %fontRoundBold;
    margin-bottom: 16px;

    svg {
      position: relative;
      right: 6px;
      width: 34px;
      height: 34px;
      margin-right: 0;
    }

    .feedbackNoApply {
      margin-left: auto;

      .checkBox {
        .check {
          .icon {
            justify-content: center;
            align-items: center;
            position: relative;

            svg {
              position: relative;
              left: 0;
              width: 16px;
              height: 16px;
            }
          }
        }

        .label {
          color: #575863;
          font-size: 14px;
          @extend %fontRegular;
          margin-top: 3px;
          user-select: none;
        }
      }
    }
  }

  .feedbackFields {
    display: flex;
    flex-direction: row;
    padding: 0 36px;

    .fieldset {
      min-width: calc(50% - 16px);
      flex: 1;

      label {
        font-size: 11px;
        color: #575863;
      }

      .inputContainer {
        input {
          background-color: #d2d5fd;
          border-color: #d2d5fd;

          &:focus {
            background-color: #fff;
          }
        }
      }
    }

    .customDatepicker {
      min-width: calc(50% - 16px);
      flex: 1;
      margin-left: 20px;
      margin-bottom: 25px;

      .datepickerLabel {
        color: #575863;
        font-size: 11px;
      }

      .inputBackground,
      .withPortalVersion,
      .darkBackground {
        background-color: #d2d5fd;
        border-color: #d2d5fd;

        & > div {
          input {
            border-color: transparent;
          }
        }

        .react-datepicker-wrapper {
          transition: 100ms ease-in-out;

          .react-datepicker__input-container {
            background-color: transparent;
          }
        }
      }
    }
  }

  .formType {
    padding: 0 36px;
    margin-bottom: 16px;

    label {
      font-size: 11px;
      color: #575863;
      text-transform: uppercase;
      @extend %fontRoundBold;
      letter-spacing: 0.1em;
    }

    .radioInput {
      .radioOptionList {
        flex-direction: row;

        .radioOption {
          margin-right: 32px;
        }
      }
    }
  }

  .textarea {
    padding: 0 36px;

    label {
      font-size: 11px;
      color: #575863;
    }
  }

  @media screen and (max-width: 640px) {
    .feedbackLabel {
      padding-right: 16px;
    }

    .feedbackFields {
      flex-direction: column;
      padding-right: 16px;

      .customDatepicker {
        margin-left: 0;
      }
    }

    .textarea {
      padding-right: 16px;
    }
  }
}
