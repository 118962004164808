@import "../../../../../styles/base.scss";

.versionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 16px 16px;
  background-color: #fff;

  .versionsHeader {
    width: 100%;
    display: flex;
    align-items: center;
    color: $midnightBlue70;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    @extend %fontRoundBold;
    border-bottom: 1px solid $midnightBlue20;
    .versionMobile {
      display: none;
    }
    .version {
      display: flex;
      width: 35px;
    }

    .actions {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-left: auto;
      padding-right: 0px;
    }

    .file {
      width: 292px;
    }

    .delivery {
      width: 136px;
      font-size: 10px;
      letter-spacing: 0.1px;
    }
    .releaseDate {
      padding-left: 148px;
      display: flex;
      align-items: center;
      gap: 170px;
    }
  }

  .versionsList {
    .versionItem {
      display: flex;
      align-items: center;
      background-color: $midnightBlue0;
      border-bottom: 1px solid #fff;
      padding: 16px 16px 16px 10px;
      font-size: 14px;
      .versionInfo {
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 95.8%;
      }
      .version {
        display: flex;
        width: 30px;
        color: #9192a2;
      }
      .fileNameBlock {
        color: #9192a2;
        width: 272px;
        margin-right: 18px;
      }
      .file {
        display: flex;
        color: #9192a2;
        word-break: break-all;
        font-size: 14px;
      }
      .reason {
        display: flex;
        font-size: 13px;
        color: #333333;
        font-weight: bold;
        margin-top: 8px;
      }

      .delivery {
        font-size: 14px;
        width: 136px;
        letter-spacing: 0.1px;
        padding-left: 25px;
      }

      .delivery {
        .datePlaceholder {
          display: flex;
          padding-left: 65px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: -70px;
        & > svg,
        & > div,
        & > span {
          margin-left: 8px;
          cursor: pointer;
        }
        .details {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0 10px 16px 10px;

    .versionsHeader {
      padding: 8px 0;

      .version {
        min-width: 110px;
      }

      .file {
        display: none;
      }

      .version,
      .delivery {
        padding: 0;
        max-width: 33%;
        width: 33%;
        margin: 0;
        font-size: 11px;
        flex-grow: 1;
      }
    }

    .versionsList {
      .versionItem {
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        padding: 16px 0 16px 0;

        .version {
          order: 0;
          margin-right: 0;
          max-width: 110px;
          max-width: 33%;
          margin: 0;
        }

        .version,
        .delivery {
          min-width: calc(33% - 22px);
          max-width: calc(33% - 22px);
          margin: 0;
          font-size: 12px;
        }

        .file {
          order: 4;
          max-width: 55%;
          flex-basis: 50%;
          margin-top: 16px;
          padding-left: 12px;
          bottom: 16px;
          left: 16px;
        }

        .actions {
          order: 5;
          max-width: 50%;
          flex-basis: 130px;
          justify-content: flex-end;
          margin-top: 16px;
          bottom: 16px;
          right: 8px;
          margin-left: auto;
          padding-right: 6px;
          align-self: flex-end;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .versionsHeader {
      display: flex;
      align-items: space-between;
      padding: 8px 16px 8px 0px;
      color: $midnightBlue70;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      @extend %fontRoundBold;
      border-bottom: 1px solid $midnightBlue20;
      .version {
        display: none;
      }
      .versionMobile {
        display: flex;
        width: 30px;
        color: #9192a2;
      }
      .file {
        display: none;
      }

      .delivery {
        display: none;
      }
    }

    .versionsList {
      .versionItem {
        display: grid;
        grid-template-columns: repeat(2, 195px);
        grid-column-gap: 695px;
        @media screen and (max-width: 768px) {
          grid-column-gap: 440px;
        }
        @media screen and (max-width: 425px) {
          grid-column-gap: 130px;
        }
        @media screen and (max-width: 375px) {
          grid-column-gap: 80px;
        }
        @media screen and (max-width: 320px) {
          grid-column-gap: 30px;
        }
        background-color: $midnightBlue0;
        border-bottom: 1px solid #fff;
        font-size: 14px;

        .versionInfo {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin-left: 5%;
          width: 900px;
          @media screen and (max-width: 768px) {
            width: 628px;
          }
          @media screen and (max-width: 425px) {
            width: 350px;
          }
          @media screen and (max-width: 375px) {
            width: 300px;
          }
          @media screen and (max-width: 320px) {
            width: 250px;
          }
        }

        .version {
          display: flex;
          width: 100%;
          color: #9192a2;
          margin-left: 10px;
        }

        .fileNameBlock {
          color: #9192a2;
          width: 100%;
          margin-left: -15px;
          margin-right: 10px;
        }

        .file {
          display: flex;
          color: #9192a2;
          word-break: break-all;
          width: 100%;
          font-size: 14px;
        }

        .reason {
          display: flex;
          color: #333333;
          width: 100%;
          font-size: 13px;
          font-weight: bold;
        }

        .delivery {
          display: none;
        }

        .delivery {
          .datePlaceholder {
            display: flex;
            padding-left: 25px;
          }
        }

        .actions {
          display: flex;
          margin-left: -20px;
          align-self: center;
          align-items: center;
          & > svg,
          & > div,
          & > span {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .versionsHeader {
      .version,
      .delivery {
        font-size: 10px;
      }
    }

    .versionsList {
      .versionItem {
        .delivery {
          font-size: 11px;
        }
      }
    }
  }
}

.blockListItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid $midnightBlue0;
  .blockListHeader{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f6f6ff;
    transition: 100ms ease-in-out;
  }
  .blockListItemHeaderNotClick {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f6f6ff;
    transition: 100ms ease-in-out;
    

    .blockListItemHeader {
      width: calc(100% - 110px);
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 15px 16px 15px 12px;

      .cell {
        margin-right: 16px;
        padding: 8px 16px;
      }

      .mainCell {
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-basis: 110px;
        max-width: 200px;

        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 5px 4px;
          border-color: transparent transparent $midnightBlue40 transparent;
          position: relative;
          transform: rotate(90deg);
          margin: 4px 8px 4px 0;
        }
      }

      .blockNumber {
        display: flex;
        align-items: center;
        width: 100px;
        font-size: 20px;
        white-space: nowrap;
        margin-right: 16px;

        & > svg {
          margin: 4px 4px 4px 0;
        }
      }

      .episode {
        margin-right: 14px;
        @extend %fontRoundBold;
        
      }

      .chaptersNumber {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100px;
        font-size: 16px;
        color: #9595a3;
        font-weight: 600;
        background-color: blue($color: #000000);

        .responsive {
          display: none;
        }
      }

      .dates {
        min-width: 350px;
        max-width: 350px;
        display: flex;

        .date {
          display: flex;
          font-size: 14px;
          padding: 0 16px 0 16px;
          width: 33%;
          color: #9595a3;
          font-weight: 600;

          .datePlaceholder {
            display: flex;
            width: 100%;
            padding-right: 16px;
            justify-content: center;
          }
        }
      }

      .datesText {
        min-width: 205px;
        max-width: 205px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

        .date {
          display: flex;
          font-size: 14px;
          padding: 0 16px 0 16px;
          width: 100%;
          color: #9595a3;
          font-weight: 600;
          justify-content: center;
          text-align: center;

          .datePlaceholder {
            display: flex;
            width: 100%;
            padding-right: 16px;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }

  .blockActions {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 116px;
    justify-content: flex-end;
    padding-right: 16px;

    & > div,
    & > svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .blockListItemContent {
    max-height: 0;
    overflow: hidden;
  }

  &.open {
    .blockListItemHeaderNotClick {
      background-color: #fff;

      .blockListItemHeader {
        .blockNumber {
          & > svg {
            transform: rotate(90deg);
          }
        }

        .episode {
          & > .triangle {
            transform: rotate(180deg);
          }
        }
      }
    }

    .blockListItemContent {
      max-height: 999999px;
    }

  }

  .tableItemDates{
    display: block;
  }
  .mobile{
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .blockListItemHeaderNotClick {
      .blockListItemHeader {
        justify-content: space-between;

        .blockNumber {
          max-width: 120px;
          margin-right: 0;
        }

        .blockActions {
          flex-grow: 0;
        }

        .chaptersNumber {
          flex: 1;
          width: 100%;
          max-width: 100%;
          margin-right: 0;

          .responsive {
            display: block;
            margin-left: 6px;
          }
        }

        .dates {
          display: none;
        }
      }
    }

  }

  @media screen and (max-width: 620px) {
    .blockListItemHeader {
      .blockNumber {
        font-size: 18px;
      }

      .chaptersNumber {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 1024px){
    .blockListItemHeaderNotClick{
      display: flex;
      flex-direction: column;
    }
    .mobile{
      display: block;
    }
    .tableItemDates{
      display: none;
    }
    
  }
}
