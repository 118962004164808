@import "../../../../styles/base.scss";

.errorPage {
  background-color: $midnightBlue90;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .errorInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 16px;
    z-index: 2;
    width: 100%;

    .codeError {
      margin-top: 10vh;
      text-transform: uppercase;
      color: $midnightBlue30;
      text-align: center;
      letter-spacing: 0.1em;
      font-size: 12px;
      line-height: 16px;
      @extend %fontRoundBold;
    }

    .errorTitle {
      color: #fff;
      letter-spacing: -0.02em;
      font-size: 37px;
      line-height: 44px;
      @extend %fontRoundBold;
      margin-top: 8px;
      text-align: center;
    }

    .contactMessage {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      text-align: center;
      margin-top: 12px;
    }

    .simpleButton {
      max-height: 40px;
      margin-top: 24px;
      max-width: 126px;
    }

    @media screen and (max-width: 768px) {
      .codeError {
        margin-top: 6vh;
      }
    }
  }

  .animationsContainer {
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    flex: 1;

    .security {
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: contain;
      position: fixed;
      width: 395px;
      height: 290px;
      bottom: 36px;
      z-index: 2;
    }

    .door {
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      position: fixed;
      width: 444px;
      height: 300px;
      bottom: 72px;
    }

    .floor {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 72px;
      background-color: $midnightBlue20;
    }

    @media screen and (max-width: 768px) {
      .security {
        width: 280px;
        height: 215px;
      }

      .door {
        width: 354px;
        height: 210px;
      }
    }

    @media screen and (max-height: 710px) {
      .security {
        width: 280px;
        height: 215px;
      }

      .door {
        width: 354px;
        height: 210px;
      }
    }

    @media screen and (orientation: landscape) and (max-height: 600px) {
      .security,
      .door,
      .floor {
        display: none;
      }
    }
  }
}
