@import "../../../../styles/base.scss";

.editSheetRelevance {
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  .relevanceNavigation {
    display: flex;
    height: 54px;
    padding: 0 24px;
    border-bottom: 1px solid #c0c3f8;
    overflow-x: auto;
    position: sticky;
    top: 0;
    background-color: #e2e5fb;
    z-index: 15;

    .tab {
      padding: 18px 24px;
      color: #999be0;
      font-size: 18px;
      @extend %fontRoundBold;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $cyan50;
        border-bottom-color: $cyan50;
      }
    }
  }

  .relevanceTip {
    font-size: 16px;
    color: #43444e;
    margin: 20px 0;
  }

  .relevanceForm {
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    .formContent {
      .formFieldColumn {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        width: 100%;

        .fieldLabel {
          color: $terciaryColor;
          @extend %fontRegular;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          display: block;
          margin-bottom: 8px;
        }
      }
    }
  }
}
