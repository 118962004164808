@import "../../../styles/base";

#PaginationContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 20px 0;

  .prevPagination,
  .nextPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 28px;
    height: 28px;
    position: relative;
    cursor: pointer;

    .prevArrow {
      display: block;
      width: 10px;
      height: 2px;
      background-color: #ccc;
      transition: 150ms ease-in-out;

      &:nth-child(1) {
        transform: rotate(-45deg);
        margin-bottom: 2px;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
        margin-top: 2px;
      }
    }

    .nextArrow {
      display: block;
      width: 10px;
      height: 2px;
      background-color: #ccc;
      transition: 150ms ease-in-out;

      &:nth-child(1) {
        transform: rotate(45deg);
        margin-bottom: 2px;
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
        margin-top: 2px;
      }
    }

    &.hasPage {
      .prevArrow,
      .nextArrow {
        background-color: $cyan50;
      }
    }
  }

  ul {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0 85px 0 95px;

    li {
      width: 24px;
      height: 24px;
      margin: 0 10px 0 0;
      display: flex;
      align-content: center;
      justify-content: center;
      border-radius: 100%;

      a {
        width: 100%;
        height: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: $cyan50;
      }

      &.active {
        background-color: $midnightBlue90;
        pointer-events: none;

        a {
          color: #ffffff;
        }
      }
    }

    @media screen and (max-width: 500px) {
      margin: 0 55px 0 65px;
    }

    @media screen and (max-width: 400px) {
      margin: 0 35px 0 45px;
    }
  }
}
