@import "../../../styles/base";

#UploadBox {
  display: block;

  > .newActivity {
    margin: 0 0 30px;
  }

  @media screen and (max-width: 920px) {
    .newActivity {
      max-width: 100%;
    }
  }
}
