@import "../../../styles/base.scss";

#RegisterSuccess {
  background-color: #e2e4ff;
  min-height: calc(100vh - 212px);
  width: 100%;

  .successIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #36355a;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 148px;

      .successIcon {
        border: 4px solid #484879;
        border-radius: 50%;
        width: 96px;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  h2 {
    max-width: 800px;
    margin: 65px auto 30px;
    @extend %fontLight;
    text-align: center;
    font-weight: 300;
    font-size: 37px;
    color: #000;

    a {
      color: #17b4e1;
      display: inline-block;
      margin: 0 5px;
      border-bottom: 4px solid #d2d5fd;
      @extend %fontRoundBold;
      text-decoration: none;
    }
  }

  p {
    text-align: center;
    margin: 32px 0 48px;
    font-size: 18px;
    @extend %fontLight;
    color: rgb(87, 88, 99);
    font-weight: 300;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 640px) {
    h2 {
      font-size: 30px;
    }

    .buttons {
      flex-direction: column;

      div {
        margin: 0 auto 15px auto;
      }
    }
  }
}
