@import "../../../styles/base.scss";

#Login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, rgb(36, 35, 61) 100%, rgb(54, 53, 90) 0%);
  padding: 20vh 20px 24px;
  box-shadow: inset 0px -30px 30px rgba(42, 41, 71, 0.5);

  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loginText {
      color: #fff;
      margin: 16px 0 48px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }

  a {
    text-decoration: none;
  }
}
