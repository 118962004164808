@import "../../../../../styles/base.scss";

#DramaturgyParameters,
#VarietiesParameters {
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
  }

  .parametersSection {
    padding: 24px 0 6px 24px;
    border-top: 1px solid #c5c8f0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &.paddingBottom {
      padding-bottom: 30px;
    }

    .parameter {
      display: flex;
      flex-direction: column;
      width: calc(33% - 20px);
      min-width: 220px;
      margin-right: 20px;

      .creatableFieldset {
        .reactSelect
          .reactSelect__control
          .reactSelect__value-container
          .reactSelect__placeholder {
          font-size: 14px;
        }

        .reactSelect .reactSelect__control {
          min-height: 45px;
        }

        .reactSelect .reactSelect__control .reactSelect__value-container {
          min-height: 40px;
        }
      }

      &.small {
        width: 33%;
      }

      &.medium {
        width: 66%;
      }

      &.large {
        width: 100%;
      }

      .subParameter {
        margin-bottom: 19px;

        .reactSelectDramaturgyParameters {
          margin-bottom: 0;
        }
      }

      .labelParameter {
        color: #36345a;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
      }

      .fieldset {
        width: 100%;
        margin-top: auto;

        .inputContainer {
          height: 46px;

          input {
            height: 46px;
            font-size: 14px;
          }

          .selectArea {
            height: 46px;
            font-size: 14px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .parametersSection {
      .parameter {
        display: flex;
        width: calc(50% - 10px);
        min-width: 220px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .parametersSection {
      .parameter {
        display: flex;
        width: 100%;
      }
    }
  }
}
