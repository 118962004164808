@import "../../../../../styles/base.scss";

.createActivity {
  position: relative;

  .listFiles {
    .insideContainer {
      position: relative;

      .containerFiles {
        display: flex;
        background: #ffffff;
        padding: 12px;
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        border-radius: 4px;
        min-height: 62px;

        .fileInfo {
          display: flex;
          flex-direction: column;
          padding-right: 12px;
          flex: 1;

          h2 {
            width: 100%;
            font-size: 16px;
            margin-bottom: 5px;
            @extend %fontRoundBold;
          }

          span {
            font-size: 12px;
            @extend %fontLight;
          }
        }

        .deleteFiles {
          cursor: pointer;
          border: 1px solid #aabee799;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          border-radius: 4px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .createActivityContainer {
    padding: 18px 27px 0 27px;

    .field {
      margin-bottom: 25px;

      .outerContainer {
        display: flex;
        padding: 0 36px;

        .insideContainer {
          flex: 1;
          padding: 0;

          &:nth-child(1) {
            margin-right: 10px;
          }

          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }

      .insideContainer {
        padding: 0 36px;

        .radioInput {
          margin-bottom: 25px;

          .radioOptionList {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .radioOption {
              width: 50%;
            }
          }
        }

        .textarea {
          label {
            color: #575863;
            font-size: 11px;
          }
        }

        .statusSelect {
          max-width: 305px;
        }

        .uploadSection {
          position: relative;

          .attachmentTitle,
          .attachmentSubTitle {
            display: none;
          }
        }
      }

      .fieldLabel {
        display: flex;
        align-items: center;
        color: #1e1f24;
        @extend %fontRoundBold;
        margin-bottom: 12px;

        svg {
          margin-right: 14px;
          margin-bottom: 2px;
        }
      }

      .attachmentBoxes {
        margin-bottom: 25px;

        .attachmentBox {
          display: flex;
          flex-direction: column;
          background-color: #fff;
          border-radius: 4px;
          padding: 15px 12px;
          position: relative;
          margin-bottom: 8px;

          .attachmentType {
            color: $terciaryColor;
            @extend %fontRegular;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            display: block;
            margin-bottom: 8px;
          }

          .attachmentName {
            display: flex;
            align-items: center;
            color: $cyan50;
            @extend %fontRoundBold;
            margin-bottom: 4px;
            word-break: break-all;

            svg {
              margin-right: 4px;
            }
          }

          .receivedAt {
            @extend %fontLight;
            font-size: 14px;
            color: #7f808e;
          }

          .removeAttachment {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 6px;
            right: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      .addAttachment {
        display: block;
        color: $cyan50;
        border-bottom: 1px solid $cyan50;
        font-size: 14px;
        line-height: 14px;
        width: fit-content;
        cursor: pointer;
        position: relative;
        z-index: 0;

        .scriptBoxCaller {
          display: block;
          color: $cyan50;
          font-size: 14px;
          line-height: 14px;
          width: fit-content;
          cursor: pointer;
          position: relative;
          text-decoration: none;
        }
      }
    }
  }

  .boxFooter {
    padding: 0 12px;
    background-color: $midnightBlue0;

    .simpleButton {
      max-width: 175px;
      height: 48px;
      padding: 16px;

      span {
        margin: 0;
      }
    }
  }

  .addAttachmentsModal {
    height: 100%;
    border: 1px solid red;

    .content {
      height: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    .createActivityContainer {
      .field {
        .outerContainer {
          padding: 0 16px 0 36px;
          display: flex;
          flex-direction: column;

          .insideContainer {
            flex: 1;
            padding: 0;
            margin-bottom: 16px;

            &:nth-child(1) {
              margin-right: 0;
            }

            &:nth-child(2) {
              margin-left: 0;
            }
          }
        }

        .insideContainer {
          padding: 0 16px 0 36px;

          .basic-single {
            max-width: 100%;
          }

          .radioInput {
            .radioOptionList {
              flex-direction: column;

              .radioOption {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
