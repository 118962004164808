@import "../../../../styles/base.scss";

.editChaptersOrEpisodes {
  width: 100%;
  

  .fileInfo {
    display: flex;
    width: 100%;
    padding: 25px;
  }

  .numberInput {
    margin-right: 25px;
  }

  .boxFooter {
    .buttonCancelNormal {
      margin-right: 10px;
    }
  }

  .attachDates{
    margin-top: 25px;
  }
  #FormAndFields {
    width: 100%;
    display: flex;
    flex-direction: column;

    .customDatesContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 11px;
      width: 94.5%;
      margin-left: 25px;

      .customDate {
        flex-basis: calc(50% - 10px);
        display: flex;
        justify-content: center;

        .customDatepicker {
          width: 100%;
          position: relative;
        }
      }
    }
    .formAndFieldsFooter{
      height: 0px;
      margin-top: 40px;
    }
     .customBox{
        height: 72px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 24px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e1e4fa;
        margin-top: auto;
      }
      
     
    .buttonCancelNormal {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .fileInfo {
      flex-direction: column;
    }

    .numberInput {
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 425px) {
    .fileInfo {
      flex-direction: column;
    }

    .numberInput {
      margin-bottom: 25px;
    }
    #FormAndFields {
      width: 100%;
      display: flex;
      flex-direction: column;
  
      .customDatesContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 11px;
        width: 94%;
        @media screen and (max-width: 375px) {
          width: 92%;
        }
  
        .customDate {
          flex-basis: calc(50% - 10px);
          display: flex;
          justify-content: center;
  
          .customDatepicker {
            width: 100%;
            position: relative;
          }
        }
      }
  
      .buttonCancelNormal {
        margin-right: 10px;
      }
    }
  }
}
