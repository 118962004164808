@import "../../../../../styles/base.scss";

.evaluationField {
  padding: 48px 64px;
  min-height: 190px;
  transition: 100ms ease-in-out;
  position: relative;

  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 1px 18px rgba(0, 0, 0, 0.04),
      0 6px 10px rgba(0, 0, 0, 0.03);
  }

  &.isFocused {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0 1px 18px rgba(0, 0, 0, 0.12),
      0 6px 10px rgba(0, 0, 0, 0.14);

    .removeButton {
      opacity: 1;
    }
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;

    .fieldHeader {
      display: flex;

      .fieldTitle {
        width: 100%;
        margin-right: 32px;

        textarea {
          color: #1e1f24;
          @extend %fontLight;
          margin-bottom: 8px;
          border: none;
          outline: none;
          min-height: 38px;
          padding: 4px 0;
          transition: 100ms ease-in-out;
          width: calc(100% - 50px);
          resize: none;
          font-size: 28px;

          &::placeholder {
            color: $midnightBlue50;
          }
        }

        span,
        & > div {
          display: block;
          color: #1e1f24;
          @extend %fontLight;
          margin-bottom: 12px;
          min-height: 38px;
          padding: 4px 0;
          width: calc(100% - 50px);
          font-size: 28px;
        }
      }
    }

    .editorArea {
      & > div {
        margin-top: 25px;
      }

      ol {
        display: flex;
        flex-wrap: wrap;

        li {
          width: calc(100% - 16px);
          margin-left: 16px;
          margin-bottom: 5px;
          list-style: decimal;
        }
      }

      p {
        margin-bottom: 20px;
        line-height: 22px;
      }

      .ck {
        &.ck-blurred {
          padding-left: 0;
          transition: 100ms ease-in-out;

          &:hover {
            border: 1px solid #47a4f5;
            padding-left: 10px;
          }
        }

        &.ck-focused {
          margin-top: 25px;
        }
      }
    }
  }

  .adequacySelects {
    display: flex;
    flex-wrap: wrap;

    .evaluationTitle {
      display: block;
      color: #1e1f24;
      @extend %fontLight;
      margin-bottom: 12px;
      padding: 4px 0;
      width: calc(100% - 50px);
      font-size: 20px;
    }

    .fieldset {
      margin: 16px 16px 0 0;
    }

    @media screen and (max-width: 640px) {
      flex-direction: column;

      .fieldset {
        width: 100%;
      }
    }
  }

  .removeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    max-width: 45px;
    height: 37px;
    border: 1px solid #aabee799;
    border-radius: 2px;
    transition: 100ms ease-in-out;
    cursor: pointer;
    margin-left: auto;
    position: absolute;
    right: 64px;
    top: 48px;
    opacity: 1;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #f3f3f3;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 32px 24px 40px;

    .removeButton {
      top: 32px;
      right: 24px;
    }
  }
}
