@import "../../../../styles/base.scss";

.sheetActivityItem {
  border-bottom: 1px solid #c0c3f8;

  .cardContent {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;

    .activityAttachments {
      display: flex;
      align-items: center;

      .lastFile {
        display: flex;
        align-items: center;

        .file {
          font-size: 14px;
          color: #43444e;
          line-height: 16px;
          padding-right: 8px;
          letter-spacing: 1px;
        }

        .fileBlock {
          font-size: 12px;
          text-transform: uppercase;
          border: 1px solid #8586cc;
          color: #8586cc;
          border-radius: 2px;
          line-height: 16px;
          letter-spacing: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px 3px 0 3px;
          margin-right: 8px;
        }

        .fileVersion {
          font-size: 14px;
          color: #6b6c78;
          padding-right: 8px;
        }
      }
    }

    .avatar {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 3px;

      .avatarIcon {
        width: 27px;
        height: 27px;
        margin-right: 8px;

        .avatarLetter {
          font-size: 14px;
          @extend %fontRoundBold;
        }
      }

      & > span {
        margin-top: 3px;
        color: #6b6c78;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 6px 0;

      .attachmentType {
        text-transform: uppercase;
        color: #43444e;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;

        .blockInfo {
          width: 70px;
          display: flex;
          border: 1px solid #7f808e;
          color: #7f808e;
          padding: 3px 6px 0 6px;
          border-radius: 2px;
          @extend %fontLight;
          text-transform: uppercase;
          margin-left: 8px;
          margin-bottom: 2px;
          font-size: 11px;
          letter-spacing: 0.1em;
        }
      }

      .attachmentDate {
        color: #43444e;
        font-size: 14px;
        font-weight: 100;
      }
    }

    .attachmentName {
      color: #007eb5;
      @extend %fontRoundBold;
      margin: 6px 0;
      cursor: pointer;
      word-break: break-word;

      a {
        color: #007eb5;
        text-decoration: none;
      }
    }

    .allAttachments {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #007eb5;
      @extend %fontRoundBold;
      margin-bottom: 0;
      padding-top: 3px;

      .scriptBoxCaller {
        position: relative;
        font-size: 16px;
      }
    }

    .attachmentVersion {
      margin: 6px 0 0 0;
      color: #6b6c78;
      @extend %fontLight;
    }
  }
}

@media screen and (max-width: 640px) {
  .sheetActivityItem {
    .cardContent {
      padding: 24px 16px;
    }
  }
}
