@import "../../../../styles/base.scss";

.ScriptBox {
  .windowScriptBox {
    .thesaurusAlertsContainer {
      position: relative;
      top: -14px;
      display: flex;
      flex-direction: column;

      .thesaurusAlert:last-of-type {
        margin-bottom: 16px;
      }
    }

    .formItem {
      label {
        color: $terciaryColor;
        @extend %fontRegular;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
      }
    }

    .boxFooter {
      .buttonCancel {
        border: none;
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .windowScriptBox {
      .boxFooter {
        .buttonCancel {
          margin-right: 8px;
        }

        .simpleButton {
          padding: 8px 12px;
          height: 48px;
        }
      }
    }
  }
}
