#CreateAndUpdateBlockPlanning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  #FormAndFields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .alertIconContainer {
      display: flex;
      gap: 10px;
      margin: 12px 24px;
    }

    .chaptersNumberContainer {
      width: 100%;
      margin: 10px 0;

      .numberInput {
        margin: 0;
        padding-left: 24px;
      }
    }

    .customDatesContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 11px;
      width: 100%;
      padding: 24px;

      .customDate {
        flex-basis: calc(50% - 10px);
        display: flex;
        justify-content: center;

        .customDatepicker {
          width: 100%;
          position: relative;
        }
      }
    }

    .buttonCancelNormal {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    .customDatesContainer {
      flex-direction: column;
     

      .customDate {
        flex-basis: 100%;
      }
    }

    .boxFooter {
      position: fixed;
    }
  }

  @media screen and (max-width: 580px) {
    .customDatesContainer {
      flex-direction: column;

      .customDate {
        flex-basis: 100%;
      }
    }
    .boxFooter {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      position: relative;
    }
  }
}
