@import "../../../../styles/base.scss";

.modalTitle {
  display: flex;
  align-items: center;

  .titlePrefix,
  .modalTitleSeparator {
    color: $midnightBlue10;
  }

  .modalTitleSeparator {
    font-size: 24px;
    margin: 0 6px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;

    .modalTitleSeparator {
      font-size: 18px;
    }
  }
}
