@import "../../../../../styles/base.scss";

.modalHeader {
  display: flex;

  .icon {
    height: 100%;

    .typeIconReturner {
      min-width: 32px;
      width: 32px;
      height: 32px;
      justify-content: flex-start;
      margin-right: 12px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .headerInfo {
    display: flex;
    flex-direction: column;

    .title {
      @extend %fontRoundBold;
      color: #fff;
      font-size: 20px;
    }

    .creators {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0;
      color: $secondaryColor;
      font-size: 14px;

      span {
        display: block;
        text-transform: uppercase;
      }

      .creatorSeparator {
        margin: 0 6px;
        color: $midnightBlue10;
      }
    }

    .type {
      color: #fff;
    }

    .categories {
      color: #fff;
      margin-top: 4px;
    }
  }
}
