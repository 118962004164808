@import "../../../styles/base.scss";

#Home {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, rgb(36, 35, 61) 100%, rgb(54, 53, 90) 0%);
  padding: 0 24px;
  box-shadow: inset 0px -30px 30px rgba(42, 41, 71, 0.5);

  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .scriptIcon {
      margin: 112px 0 40px 0;
    }
  }
}
