@import "../../../../styles/base.scss";

.Financing {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  .label {
    width: 60%;
    line-height: 20px;
    text-transform: uppercase;
    color: $midnightBlue60;
    font-size: 13px;
    @extend %fontRoundBold;
  }

  .value {
    max-width: 38%;
    min-width: 30%;
    font-size: 14px;
    text-align: right;

    > .tooltipvalue {
      display: none;
      position: absolute;
      right: 30px;
      color: #7070b3;
    }

    &:hover {
      > .tooltipvalue {
        display: block;
      }
    }
  }
}
