@import "../../../styles/base.scss";

#Navigation {
  width: 100%;
  background: #36345a;

  .container {
    display: flex;
    align-items: center;
    height: 54px;

    .itemNavigation {
      font-size: 18px;
      color: #adafef;
      cursor: pointer;
      user-select: none;
      padding: 18px 24px;
      min-width: fit-content;
      display: flex;
      align-items: center;
      border-bottom: 3px solid transparent;
      height: 100%;
      @extend %fontRoundBold;

      span {
        margin-left: 10px;
        display: block;
      }

      .counter {
        display: block;
        padding-left: 4px;
        margin-left: 0;
      }

      &.active {
        color: #ffffff;
        border-bottom: 3px solid #ffffff;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .container {
      margin: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      .itemNavigation {
        span {
          display: none;
        }
      }
    }
  }
}
