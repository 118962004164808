@import "../../../styles/base.scss";

.typeIconReturner {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 56px;
  height: 56px;
  min-width: 56px;

  &.responsiveIcon {
    svg {
      width: 36px;
      height: 36px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-right: 12px;

    &.responsiveIcon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
