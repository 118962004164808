@import "../../../../styles/base";

#TableListActivity {
  .container {
    .containerListActivity {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;

      .itemList {
        display: flex;
        width: 100%;
        position: relative;

        & > li {
          width: calc(100% / 6);
          font-size: 14px;
          color: #7f808e;
          @extend %fontRegular;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-grow: 2;
          margin-bottom: 2px;
          background: #ffffff;

          &.blueArrow {
            color: #0098cd;
          }

          &.title {
            font-size: 13px;
            min-width: 215px;
            max-width: 215px;

            .containerTitle {
              display: flex;
              color: #979797;
              align-items: normal;
              @extend %fontRegular;

              .textTitle {
                @extend %fontRoundBold;
                color: #43444e;
                font-size: 16px;
              }

              .Author {
                text-transform: uppercase;
                margin-top: 2px;

                .separator {
                  margin: 0 2px;
                }

                .creator {
                  margin-bottom: 2px;
                }
              }

              .typeActivity {
                margin-top: 2px;
              }

              .typeIconReturner {
                min-width: 30px;
                width: 30px;
                height: 30px;
                margin-right: 10px;
                margin-bottom: 2px;

                svg {
                  width: 24px;
                  height: 24px;
                  margin-top: -10px;
                }
              }
            }
          }

          &.type {
            min-width: 135px;
            max-width: 135px;
          }

          &.attachments {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .activityTypeName {
              font-size: 16px;
              color: #979797;
              margin-bottom: 4px;
              display: block;
              @extend %fontRoundBold;
            }

            .attachmentsInfo {
              display: flex;
              align-items: center;
              width: 100%;
              flex-wrap: wrap;

              .file {
                font-size: 14px;
                color: #43444e;
                line-height: 18px;
                padding-right: 4px;
                letter-spacing: 1px;
                display: flex;
                flex-wrap: wrap;
              }

              .fileBlock {
                font-size: 12px;
                text-transform: uppercase;
                border: 1px solid #8586cc;
                color: #8586cc;
                border-radius: 2px;
                line-height: 16px;
                letter-spacing: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 3px 0 3px;
                margin-right: 8px;
                margin-left: 2px;
              }

              .fileVersion {
                font-size: 14px;
                color: #6b6c78;
                padding-right: 10px;
                white-space: nowrap;
              }
            }
          }

          &.analyst {
            display: flex;
            min-width: 200px;

            & > div {
              display: flex;
              align-items: center;

              .icon {
                min-width: 28px;
                height: 28px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                background: #9a8fdb;
                color: #ffffff;
                @extend %fontRoundBold;
              }
            }
          }

          &.canceledOrDone {
            min-width: 155px;
            max-width: 155px;
          }

          &.info {
            min-width: 125px;
            max-width: 125px;
            display: flex;
            align-items: center;
            justify-content: center;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              .deleteIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #c0c3f8;
                width: 40px;
                height: 40px;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 20px;
              }

              .feedbackIcon {
                margin-left: 20px;

                .newFeedback {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  cursor: pointer;

                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    bottom: 4px;
                    right: 5px;
                    background-color: #f42b5a;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      margin: 0;
      width: 100%;

      .containerListActivity {
        .itemList {
          flex-direction: column;
          margin-bottom: 2px;

          & > li {
            width: 100%;
            margin-bottom: 0;

            &.title {
              width: 100%;
              padding-bottom: 10px;
              padding-right: 142px;
              max-width: unset;
            }

            &.type,
            &.attachments,
            &.analyst {
              padding: 4px 20px 4px 60px;
              max-width: unset;
            }

            &.analyst {
              padding-bottom: 10px;
            }

            &.canceledOrDone,
            &.info {
              position: absolute;
              background-color: transparent;
              right: 0;
              width: fit-content;
              padding-bottom: 0;
              max-width: unset;
            }

            &.canceledOrDone {
              bottom: 16px;
              right: 0;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
