.InfoPaper {
  .contentInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 0;

    .left-Info {
      p {
        font-size: 13px;
        color: #7f808e;
        text-transform: uppercase;
        line-height: 22px;
      }
    }

    .right-info {
      p,
      .TagsProject {
        font-size: 14px;
        color: #000000;
        line-height: 22px;
      }
    }
  }
}
