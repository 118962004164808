@import '../../../../../../styles/base.scss';

.ReleaseTextInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  min-height: 60px;

  .switchRelease {
    width: 100%;
  }

  .type {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-right: 16px;
    @extend %fontRoundBold;

    .chapterNumber {
      display: block;
      margin-left: 4px;
    }

    .blockInfo {
      display: flex;
      border: 1px solid #7f808e;
      color: #7f808e;
      padding: 3px 6px 0 6px;
      border-radius: 2px;
      text-transform: uppercase;
      margin-left: 8px;
      font-size: 11px;
      letter-spacing: 0.1em;
      margin-bottom: 2px;
    }
  }

  .version {
    padding-right: 16px;
    font-size: 14px;
    color: #575863;
    @extend %fontRoundBold;
  }

  .date {
    font-size: 14px;
    padding: 8px 0;
  }

  &.noPadding {
    padding-bottom: 0;
    border-bottom: 1px solid #ccc;

    .switchRelease {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
