@import "../../../../styles/base.scss";

#TableListFiles {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .filesListCounter {
    width: 100%;
    padding-left: 16px;
  }

  .tableContainer {
    max-width: 1120px;
    width: 100%;
    min-height: fit-content;
    max-height: 645px;
    display: flex;
    flex-direction: column;

    .tableList {
      display: flex;
      flex-direction: column;
      width: 100%;

      .itemsList {
        display: flex;
        flex-direction: column;
        width: 100%;

        .headerList {
          @extend %fontRoundBold;
          background-color: transparent;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          padding: 8px 16px;
          min-height: 40px;
          color: #7f808e;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          border-bottom: 1px solid rgb(192, 195, 248);

          .headerType {
            width: 230px;
          }

          .headerVersion {
            width: 80px;
          }

          .headerName {
            flex: 1;
          }

          .headerReceivedAt {
            width: 120px;
          }

          .headerReleasedAt {
            width: 120px;
          }

          .headerActions {
            flex: 1;
            max-width: 130px;

            &.external {
              max-width: 100px;
            }
          }
        }

        .groupList {
          .listItem {
            display: flex;
            align-items: center;
            background-color: #eaecf7;
            border-bottom: 1px solid #d4d5e2;
            min-height: 65px;
            padding: 16px;
            width: 100%;

            .lightText {
              color: rgb(67, 68, 78);
              font-size: 14px;
            }

            &.mainItem {
              background-color: #fff;
            }

            &.colapsedItem {
              border-top-color: none;
            }

            .fileType {
              font-weight: bold;
              color: #1e1f24;
              font-size: 14px;
              width: 230px;

              .blockInfo {
                display: flex;
                border: 1px solid #7f808e;
                color: #7f808e;
                padding: 3px 6px 0 6px;
                border-radius: 2px;
                @extend %fontLight;
                text-transform: uppercase;
                margin-left: 8px;
                font-size: 11px;
                letter-spacing: 0.1em;
                margin-bottom: 2px;
              }
            }

            .fileVersion {
              width: 80px;

              .versionLabel {
                display: none;
              }

              &.hasItems {
                color: $cyan50;
                font-weight: bold;
                cursor: pointer;
              }

              .versionArrow {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 5px 0 5px;
                border-color: #999be0 transparent transparent transparent;
                margin-left: 5px;
                margin-bottom: 2px;
              }
            }

            .fileName {
              flex: 1;
              color: #7f808e;
              word-wrap: break-word;
              word-break: break-all;
              padding-right: 16px;
            }

            .receivedAt {
              width: 120px;
            }

            .releasedAt {
              width: 120px;
              padding-left: 10px;
            }

            .actions {
              display: flex;
              max-width: 130px;

              .actionItem {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                margin-left: 3px;
                cursor: pointer;

                &.download {
                  padding-top: 4px;
                }

                &.blockDelete {
                  cursor: not-allowed;

                  .tooltip {
                    .tipBalloon {
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }

            & > span {
              display: flex;
              align-items: center;
            }
          }

          .colapsedItems {
            max-height: 0;
            overflow-y: hidden;
            transition: 200ms ease-in-out;
          }

          &.opened {
            .colapsedItems {
              max-height: 1000px;
            }

            .listItem {
              .fileVersion {
                .versionArrow {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }

    &.researchFilesTable {
      .tableList {
        .itemsList {
          .headerList {
            .headerName {
              flex: 1;
            }
          }

          .groupList {
            .listItem {
              .fileName {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .filesListCounter {
      padding-left: 16px;
    }

    .tableContainer {
      .tableList {
        .itemsList {
          .headerList {
            justify-content: space-between;

            .headerType {
              width: 210px;
            }

            .headerVersion,
            .headerName,
            .headerActions {
              display: none;
            }

            .headerReceivedAt {
              display: flex;
              justify-content: flex-end;
              margin-left: auto;
            }

            .headerReleasedAt {
              display: flex;
              justify-content: flex-end;
              margin-left: auto;
            }
          }

          .groupList {
            .listItem {
              min-height: 111px;
              max-height: fit-content;
              flex-direction: column;
              align-items: flex-start;
              position: relative;

              .fileType {
                margin-bottom: 6px;
                order: 0;
              }

              .fileVersion {
                width: 80px;
                order: 2;

                .versionLabel {
                  display: block;
                  margin-left: 3px;
                }
              }

              .fileName {
                flex: 1;
                width: 100%;
                padding-right: 16px;
                order: 1;
                height: fit-content;
                align-items: flex-start;
                margin-bottom: 10px;
              }

              .receivedAt {
                position: absolute;
                right: 16px;
                top: 16px;
                display: flex;
                justify-content: flex-end;
              }

              .releasedAt {
                position: absolute;
                right: 16px;
                top: 16px;
                display: flex;
                justify-content: flex-end;
                padding-left: 10px;
              }

              .actions {
                position: absolute;
                right: 10px;
                bottom: 14px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .filesListCounter {
      .itemsCounter {
        padding-left: 0;
      }
    }
  }
}
