@import "../../../styles/base.scss";

#ReleasedTexts {
  padding-bottom: 32px;

  .NoContents {
    background-color: transparent;
  }

  .container {

    .paginationHeader {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      margin: 24px 0;

      .info {
        display: flex;
        flex-direction: column;
      }
    }

    &.contentReleasedAttachments {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin: 0 auto;

      .contentListAttachmentsReleased {
        max-width: calc(33% - 6px);
        flex: 1;
        flex-basis: 30%;
        background: #ffffff;
        padding: 14px 0 20px 0;
        border-radius: 6px;

        a {
          color: inherit;
          text-decoration: none;
        }

        .contentAttachments {
          display: none;
        }
      }
    }
  }

  .tabsContainer {
    background-color: $midnightBlue90;
    display: flex;
    width: 100%;
    height: 54px;

    ul {
      display: flex;
      background-color: $midnightBlue90;
      max-width: 1120px;
      width: 100%;
      height: 100%;
      margin: 0 auto;

      li {
        color: $midnightBlue10;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 18px 24px;
        font-size: 18px;
        @extend %fontRoundBold;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      margin: 0;
      max-width: 100%;
      min-width: 100%;

      .paginationHeader {
        padding: 0 16px;
        flex-direction: column;
        align-items: flex-start;
      }

      &.contentReleasedAttachments {
        padding: 0 16px;

        .contentListAttachmentsReleased {
          max-width: calc(50% - 8px);
          flex-basis: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {

      &.contentReleasedAttachments {
 
        .contentListAttachmentsReleased {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}
