@import "../../../../styles/base.scss";

.thesaurusAlert {
  display: flex;
  background-color: $alertColorSecondary;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 10px;

  .alertIconContainer {
    margin-right: 6px;
    height: 24px;

    svg {
      transform: rotate(180deg);
    }
  }

  .alertMessageContainer {
    font-size: 14px;
    color: $alertTextColor;
    padding-top: 5px;
    padding-right: 5px;
    display: flex;
    flex-wrap: wrap;
    white-space: normal;

    .alertTerm {
      @extend %fontRoundBold;
      min-width: fit-content;
      margin-left: 5px;
      margin-bottom: 2px;
    }

    .alertMessage {
      margin-left: 5px;
      margin-bottom: 2px;
      min-width: fit-content;
    }

    .alertTermTree {
      @extend %fontRoundBold;
      display: flex;
      flex-wrap: wrap;
      margin-left: 5px;

      .breadcrumbItem {
        display: flex;
        white-space: nowrap;
        margin-bottom: 2px;

        .breadcrumbSeparator {
          margin: 0 4px;
        }
      }
    }
  }
}
