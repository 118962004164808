@import "../../../../styles/base.scss";

.newActivity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  margin: 30px 0;

  .newActivityText {
    margin: 2px 16px 0 0;
    color: #43444e;
    @extend %fontRoundBold;
  }

  a {
    text-decoration: none;
  }

  .simpleButton {
    max-height: 48px;

    span {
      line-height: 20px;
      font-size: 16px;
      margin-top: 3px;
    }
  }

  @media screen and (max-width: 920px) {
    max-width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;

    .newActivityText {
      margin-left: 4px;
    }

    .simpleButton {
      max-width: 210px;
      min-width: fit-content;
      text-align: center;

      span {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .simpleButton {
      max-width: 210px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 25px;
  }

  @media screen and (max-width: 520px) {
    .simpleButton {
      max-width: fit-content;
    }
  }
}
