@import "../../../styles/base.scss";

.searchNavigation {
  height: 54px;
  background-color: $terciaryColor;
  justify-content: center;
  overflow-x: scroll;
  position: sticky;
  top: 0;
  z-index: 10;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
  }

  .searchesNavigationContainer {
    height: 100%;
    display: flex;
    align-items: center;
    min-width: fit-content;
    max-width: 1120px;
    margin: 0 auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0;
      background: transparent;
    }

    .type {
      color: rgb(173, 175, 239);
      @extend %fontRoundBold;
      cursor: pointer;
      user-select: none;
      font-size: 18px;
      line-height: 18px;
      padding: 18px 24px;
      min-width: fit-content;
      display: flex;
      align-items: center;
      max-height: 100%;

      svg {
        margin-right: 8px;
      }

      &.active {
        color: #fff;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 4px;
          background-color: #fff;
          position: absolute;
          bottom: -1px;
          left: 0;
        }
      }

      &.disabledNavigation {
        pointer-events: none;
      }

      .totalLabel {
        margin-left: 4px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .searchesNavigationContainer {
      .type {
        padding: 18px 18px;

        svg {
          margin-right: 0;
        }

        .typeLabel {
          display: none;
        }
      }
    }
  }
}
