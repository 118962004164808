@import "../../../../styles/base.scss";

.editRelatedContent {
  padding-bottom: 15px;

  .formField {
    margin-bottom: 25px;
  }

  .formFieldColumn {
    margin-bottom: 25px;

    .formField {
      width: 100%;
      margin-bottom: 0;

      .AssociatedProject {
        width: 100%;

        .formField {
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }

  .associateFieldset {
    .fieldset {
      margin-bottom: 0;
    }
  }

  .dashedBox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed rgba(170, 190, 231, 0.5);
    height: 44px;
    margin: 30px 0 25px 0;

    span {
      display: block;
      text-align: center;
      font-size: 14px;
      color: #38a86e;
      @extend %fontRoundBold;
      cursor: pointer;
    }
  }
}
