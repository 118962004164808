@import "../../../styles/base.scss";

.printConflictBody {
  overflow: visible;
}

#Conflicts {
  background-color: #e0e2ff;
  min-height: calc(100vh - 100px);

  .notFoundConflicts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20vh;

    svg {
      margin: 20vh 0 25px 0;
    }

    .notFoundText {
      font-size: 18px;
      text-align: center;
      color: #5c5b97;
    }
  }

  .tableSection {
    padding: 0 16px;

    .tableContainer {
      max-width: 1120px;
      margin: 0 auto;

      .listInfo {
        display: flex;
        align-items: center;
        min-height: 70px;
        max-width: 1120px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3px;

        .itemsCounter {
          display: flex;
          width: fit-content;
          margin-left: 0;
          margin-right: 0;
        }

        .simpleButton {
          margin-right: auto;
          color: #0098cd;
        }

        .listLegend {
          display: flex;
          flex-wrap: wrap;

          .levelContainer {
            display: flex;
            flex-wrap: wrap;
            max-width: 270px;

            .importanceMark {
              line-height: 22px;

              .dot {
                min-width: 10px;
                height: 10px;
              }

              &:nth-child(1),
              &:nth-child(2) {
                width: 40%;
              }

              &:nth-child(1) {
                order: 0;
              }

              &:nth-child(2) {
                order: 2;
              }

              &:nth-child(3) {
                order: 1;
              }

              &:nth-child(4) {
                order: 3;
              }

              &:nth-child(3),
              &:nth-child(4) {
                width: 60%;
              }
            }
          }

          .typeContainer {
            display: flex;
            flex-wrap: wrap;
            max-width: 240px;

            .importanceMark {
              line-height: 22px;
              max-height: fit-content;

              &:nth-child(1),
              &:nth-child(2) {
                flex: 1;
              }

              &:nth-child(1) {
                order: 0;
              }

              &:nth-child(2) {
                order: 2;
              }

              &:nth-child(3) {
                order: 1;
              }

              &:nth-child(3) {
                width: 90px;
                justify-content: flex-end;
              }
            }
          }
        }
      }

      .printList {
        display: none;
      }
    }
  }

  @media screen and (max-width: 920px) {
    .tableSection {
      .tableContainer {
        .listInfo {
          flex-wrap: wrap;
          margin-bottom: 25px;

          .itemsCounter {
            width: 50%;
            padding-right: 0;
          }

          .simpleButton {
            margin: 0 0 0 auto;
          }

          .listLegend {
            margin-top: 15px;
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .tableSection {
      .tableContainer {
        .notFoundConflicts {
          margin-bottom: 10vh;

          svg {
            margin-top: 10vh;
          }
        }
      }

      padding: 0;
    }
  }

  @media screen and (max-width: 640px) {
    .tableSection {
      .tableContainer {
        .listInfo {
          flex-wrap: wrap;
          margin-bottom: 25px;

          .listLegend {
            padding: 0 16px;

            .levelContainer,
            .typeContainer {
              width: 50%;
              flex-direction: column;

              .importanceMark {
                min-width: 100%;
              }
            }

            .levelContainer {
              .importanceMark {
                &:nth-child(1),
                &:nth-child(2) {
                  width: 50%;
                }

                &:nth-child(1) {
                  order: 0;
                }

                &:nth-child(2) {
                  order: 1;
                }

                &:nth-child(3) {
                  order: 2;
                }

                &:nth-child(4) {
                  order: 3;
                }

                &:nth-child(3),
                &:nth-child(4) {
                  width: 50%;
                }
              }
            }

            .typeContainer {
              .importanceMark {
                &:nth-child(1) {
                  order: 0;
                }

                &:nth-child(2) {
                  order: 1;
                }

                &:nth-child(3) {
                  order: 2;
                  width: 50%;
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  * {
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.4em;
    overflow: visible;
    height: auto;

    #Header,
    #Breadcrumb,
    .conflictsFilter,
    .listInfo,
    #PaginationContent {
      display: none !important;
    }

    .tableSection {
      padding: 0 !important;

      .tableContainer {
        .listInfo,
        .renderList {
          display: none;
        }

        .printList {
          display: block !important;

          .conflictsTable {
            margin: 0 !important;

            .conflictItem {
              .conflictHeader,
              .conflict {
                border-bottom: 1px solid #ccc;
              }

              .conflictHeader {
                .itemArrow {
                  transform: rotate(90deg);
                }
              }

              .conflict {
                max-height: 1000px;
                margin-bottom: 1px;
                height: 150px;
                overflow: hidden;

                .conflictBox {
                  opacity: 1;
                  overflow: hidden;
                  padding-bottom: 10px;
                  max-height: 100%;

                  .productInfo {
                    .productInfoName {
                      font-size: 14px;
                    }

                    .productPeriod {
                      font-size: 12px;
                    }
                  }

                  .characterInfo {
                    max-height: 100%;

                    .characterName {
                      font-size: 14px;

                      .nickName {
                        font-size: 14px;
                      }
                    }

                    .characterDescription {
                      max-height: 100px;
                      overflow: hidden;

                      span {
                        font-size: 12px;
                      }
                    }
                  }

                  .productInfo {
                    .conflictedProducts {
                      min-height: 14px;
                      position: relative;
                      color: #43444e;
                      font-size: 14px;
                      margin-bottom: 2px;

                      .importanceMark {
                        position: absolute;
                        left: -15px;
                        margin-right: 14px;
                        margin-top: 4px;
                      }
                    }
                  }
                }

                .scriptBoxCaller {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@page {
  margin: 0;
}
