@import "../../../styles/base";

.alertsContainer {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 0 16px;
  animation: show 100ms linear;
  min-height: 80px;
  z-index: 30;

  .alert {
    display: flex;
    border-radius: 4px;
    max-width: 1280px;
    width: 420px;
    min-width: 300px;
    text-align: center;
    z-index: 10;
    background-color: #43444e;
    padding: 18px 22px 0 22px;
    border-left: 3px solid #43444e;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px, rgba(0, 0, 0, 0.12) 0px 1px 18px,
      rgba(0, 0, 0, 0.14) 0px 6px 10px;
    animation: showAlert 300ms linear, fadeIn 100ms linear;
    will-change: transform;

    @keyframes showAlert {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .successIcon,
    .warningIcon,
    .errorIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      border-radius: 50%;
    }

    .successIcon {
      background-color: $successColor;
      padding-top: 2px;
    }

    .errorIcon {
      background-color: #f42b5a;
    }

    &.success {
      border-color: $successColor;
    }

    &.error {
      border-color: #f42b5a;
    }

    &.warning {
      border-color: #FDCD02;
    }

    .messageContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-bottom: 15px;
      position: relative;
      @extend %fontRegular;
      color: #ffffff;
      text-align: left;
      margin-left: 18px;
      padding-top: 2px;
      line-height: 22px;
      padding-right: 10px;

      p {
        margin-bottom: 10px;
      }
    }

    .closeIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      border-radius: 50%;
      cursor: pointer;
      transition: 50ms ease-in-out;

      svg {
        min-width: 18px;
        min-height: 18px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.486);
      }
    }
  }

  @media screen and (max-width: 360px) {
    .alert {
      min-width: 288px;
    }
  }

  @media screen and (max-width: 340px) {
    .alert {
      p {
        font-size: 14px;
        margin-left: 10px;
        margin-bottom: 20px;
        padding-top: 0;
      }
    }
  }
}
