@import "../../../../styles/base.scss";

.conflictsFilter {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 158px;
  background-color: #d2d5fd;
  padding: 16px 16px 10px 16px;
  margin: 0 auto 0 auto;

  .fieldsContainer {
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 1120px;

    .fieldset {
      margin-bottom: 30px;
      margin-right: 14px;
      flex: 1;
    }

    .datepickerFields {
      display: flex;
      align-items: flex-end;

      .customDatepickerHolder {
        margin: 0 6px 30px 6px;
        position: relative;

        .recomendationMessage {
          position: absolute;
          font-size: 13px;
          color: #595a66;
          margin-top: 8px;
          white-space: nowrap;
        }
      }
    }

    .simpleButton {
      margin-left: 6px;
      margin-bottom: 30px;
      z-index: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    max-height: fit-content;
    padding-top: 32px;

    .fieldsContainer {
      flex-wrap: wrap;

      .fieldset {
        min-width: 100%;
      }

      .datepickerFields {
        .customDatepickerHolder {
          &:nth-child(1) {
            margin-left: 0;
          }
        }
      }

      .simpleButton {
        flex: 1;
        justify-content: center;
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .fieldsContainer {
      .fieldset {
        min-width: 100%;
        margin-bottom: 15px;
      }

      .datepickerFields {
        width: 100%;

        .customDatepickerHolder {
          flex: 2;

          &:nth-child(2) {
            margin-right: 0;
            margin-top: 16px;
          }
        }
      }

      .simpleButton {
        margin-left: 0;
        min-width: 100%;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .fieldsContainer {
      .datepickerFields {
        flex-wrap: wrap;

        .customDatepickerHolder {
          flex: 1;
          min-width: 100%;

          &:nth-child(2) {
            margin-left: 0;
          }
        }
      }

      .simpleButton {
        margin-top: 0;
      }
    }
  }
}
