@import "../../../../../styles/base.scss";

.resultsHeader {
  display: flex;
  justify-content: space-between;
  height: 46px;
  padding: 0 16px;
  width: 100%;
  position: sticky;
  top: 54px;
  z-index: 5;
  background-color: #e0e2ff;

  .headerItem {
    display: flex;
    align-items: center;

    &.contentTitle {
      width: 275px;
      min-width: 275px;
    }

    &.contentDate {
      width: 90px;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }
  }
}
