@import "../../../../styles/base.scss";

.conflictsGraphic {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  max-height: 10000px;
  max-height: 0;
  overflow-y: hidden !important;
  overflow-x: hidden !important;

  .timeTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    padding-right: 19px;
    margin-bottom: 16px;

    .yearHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      height: 30px;
      margin-bottom: 10px;
      background-color: $midnightBlue0;
      color: $midnightBlue70;
      @extend %fontRoundBold;
      font-size: 15px;
      text-align: center;
    }

    .months {
      display: flex;
      width: 100%;
      min-width: 100%;
      margin-bottom: 20px;
    }

    .months {
      min-height: 30px;

      .monthCell {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-top: 3px;
        color: $midnightBlue70;
        @extend %fontLight;
        text-transform: uppercase;
        font-size: 14px;
        border-right: 1px solid $midnightBlue0;

        &:first-of-type {
          border-left: 1px solid $midnightBlue0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .timeTable {
      .months {
        height: 40px;
        margin-bottom: 12px;

        .monthCell {
          .monthName {
            transform: rotate(-90deg);
            font-size: 11px;
          }
        }
      }
    }
  }
}
