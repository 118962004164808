@import "../../../../../styles/base.scss";

.groupSelect {
  width: 100%;

  .groupSelect__control {
    border: 2px solid #d2d5fd;
    background-color: #d2d5fd;
    border-radius: 4px;
    min-height: 60px;
    width: 100%;

    &:hover {
      border: 2px solid #d2d5fd;
    }

    .groupSelect__value-container {
      padding: 6px 8px;
      display: flex;
      min-height: 60px;

      .groupSelect__placeholder {
        font-size: 18px;
        color: #8586cc;
      }

      .groupSelect__input {
        font-size: 18px;
      }

      .groupSelect__single-value {
        font-size: 18px;
      }

      .groupSelect__multi-value {
        display: flex;
        flex-direction: row-reverse;
        margin: 4px;
        min-height: 38px;
        background-color: #e4f8ff;
        border: 1px solid #aaf0fd;
        border-radius: 4px;

        .groupSelect__multi-value__remove {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 26px;
          max-width: 26px;
          color: #17b4e1;
          border-right: 1px solid #aaf0fd;
          cursor: pointer;

          &:hover {
            background-color: #d0ecfd;
          }
        }

        .groupSelect__multi-value__label {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          flex-wrap: wrap;
          max-width: 100%;
          padding: 6px 10px 4px 10px;
          white-space: normal;
        }
      }
    }

    .groupSelect__indicators {
      .groupSelect__indicator-separator,
      .groupSelect__dropdown-indicator {
        display: none;
      }

      .groupSelect__clear-indicator {
        cursor: pointer;
      }
    }
  }

  .groupSelect__control--is-focused {
    border-color: #17b4e1;
    background-color: #fff;
    box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);

    &:hover {
      border-color: #17b4e1;
    }
  }

  .groupSelect__menu {
    top: calc(100% - 11px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    border: 2px solid #17b4e1;
    border-top-width: 1px;
    border-top-color: #d4d5e2;
    box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
    max-height: 250px;

    .groupSelect__menu-list {
      padding: 0;
      max-height: 247px;

      .groupSelect__option {
        font-size: 18px;
        padding: 14px 12px;
        cursor: pointer;

        &:hover {
          background-color: #e4f8ff;
        }

        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .groupSelect__option--is-focused {
        background-color: #e4f8ff;
        color: #000;
      }

      .groupSelect__option--is-selected {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .groupSelectOption {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 14px;

    &:hover {
      background-color: #e4f8ff;
    }

    .optionColor {
      min-width: 11px;
      max-width: 11px;
      min-height: 11px;
      max-height: 11px;
      margin-right: 12px;
      border-radius: 50%;
    }

    .optionText {
      font-size: 16px;
      padding-top: 3px;
    }
  }

  .statusSingleValue {
    display: flex;
    align-items: center;
    padding: 6px;
    font-size: 14px;

    .optionColor {
      min-width: 11px;
      max-width: 11px;
      min-height: 11px;
      max-height: 11px;
      margin-right: 12px;
      border-radius: 50%;
    }

    .optionText {
      font-size: 16px;
      padding-top: 3px;
    }
  }

  .dropdownIndicator {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.isFocused {
      transform: rotate(180deg);
    }
  }
}
