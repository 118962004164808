@import "../../../styles/base";

#ListStatusActivities {
  padding-bottom: 50px;

  #Breadcrumb {
    .activityHeader {
      display: flex;
      flex-direction: column;

      span {
        font-size: 16px;
        color: $midnightBlue10;
        @extend %fontRoundBold;
        text-transform: capitalize;
        display: block;
        margin-top: 4px;
      }
    }

    .goBack {
      white-space: nowrap;
      font-size: 16px;
      color: $cyan40;
      @extend %fontRoundBold;
      text-align: right;
      text-decoration: none;
      margin-left: 20px;
    }
  }

  .containerFilterList {
    background: #d2d5fd;
    padding: 40px 0;

    .container {
      & > h2 {
        @extend %fontLight;
        font-size: 25px;
        color: #1f1f1f;
        margin: 0;
      }
    }
  }

  .paginationActivities {
    width: 100%;
    height: 50px;
    border: 1px solid red;
  }

  @media screen and (max-width: 1024px) {
    .totalCountActivities {
      .container {
        margin: 0;
        padding: 0 16px;
      }
    }

    .containerFilterList {
      .container {
        margin: 0;
        padding: 0 16px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 640px) {
    #Breadcrumb {
      .goBack {
        span:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
