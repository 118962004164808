@import "../../../styles/base.scss";

.helpLabel {
  padding-top: 8px;
  line-height: 16px;
  font-size: 11px;
  letter-spacing: 0.1em;
  color: #575863;
  display: inline;
}
