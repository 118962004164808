@import "../../../styles/base.scss";

.redirecting {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(0deg, rgb(36, 35, 61) 100%, rgb(54, 53, 90) 0%);
  box-shadow: inset 0px -30px 30px rgba(42, 41, 71, 0.5);

  .fullCircleLoading {
    border-top-color: #e2e4ff;
    border-right-color: #484879;
    border-bottom-color: #484879;
    border-left-color: #484879;
  }

  .redirectingMessage {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;
  }
}
