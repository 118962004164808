@import "../../../styles/base.scss";

.clientDetailsCheckboxes {
  display: flex;
  flex-direction: column;

  .parentContainer {
    padding-left: 16px;

    &.parentIsSelected {
      padding-top: 16px;
      padding-bottom: 16px;
      background-color: $midnightBlue5;
      margin-bottom: 12px;
      border-radius: 2px;
    }

    .detailItem {
      height: fit-content;
      width: fit-content;

      .checkBox {
        margin: 6px 0 12px 0;
      }
    }

    .subDetails {
      display: flex;
      flex-wrap: wrap;

      .checkBox {
        margin: 6px 0 12px 32px;

        .check {
          min-width: 20px;
        }
      }

      .detailItem {
        width: 33%;
      }

      .fieldset {
        width: 100%;
        margin: 6px 32px 24px 32px;
        padding-bottom: 12px;

        .inputContainer {
          input {
            padding-right: 16px;
          }
        }
      }

      &.tvglobo {
        width: 100%;

        .detailItem {
          width: 100%;
        }

        .subDetails {
          width: 100%;

          .fieldset {
            margin: 6px 32px 24px 32px;

            .inputContainer {
              width: 100%;

              input {
                width: 100%;
                padding-right: 16px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .parentContainer {
      .subDetails {
        .detailItem {
          width: 50%;

          .checkBox {
            margin: 6px 8px 12px 8px;
          }
        }

        .fieldset {
          margin: 6px 16px 24px 0;
        }

        &.tvglobo {
          .subDetails {
            .fieldset {
              margin: 6px 16px 24px 8px;
            }
          }
        }
      }
    }
  }
}
