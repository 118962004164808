@import "../../../../../styles/base.scss";

.clearFields {
  background-color: #fff;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  color: $cyan50;
  @extend %fontRoundBold;

  .clearArea {
    cursor: pointer;

    .cross {
      margin-right: 8px;
      font-size: 18px;
    }
  }
}
