@import "../../../../../styles/base.scss";

.evaluationPaper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  max-width: 884px;
  min-height: 1024px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  margin-top: 40px;
  margin: 36px auto 72px auto;
}
