@import "../../../../../styles/base.scss";

.slick-prev,
.slick-next {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8ebf7;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;

  &::before {
    display: none;
  }

  &:hover {
    background-color: #e8ebf7;
    opacity: 1;
  }
}

.slick-disabled {
  background-color: #e8ebf7 !important;
}
