@import "../../../styles/base.scss";

.searchInput {
  position: relative;
  max-width: 540px;
  min-width: 280px;
  width: 85vw;
  height: 60px;

  form {
    height: 60px;
    border-radius: 30px;
    background-color: #fff;

    input {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      padding: 18px 50px 18px 32px;
      @extend %fontRegular;
      font-size: 18px;
      border: none;
      outline: none;
      border: 2px solid #fff;
      transition: border 200ms ease-out 0s;
      line-height: 30px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-clip: padding-box;

      &:focus {
        border-color: #17b4e1;
        box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
      }

      &::placeholder {
        color: #8e8fcf;
      }
    }

    input[type="text"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-clip: padding-box;
    }
  }

  button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    top: 0;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: 64px;

    .searchIcon {
      width: 24px;
      height: 24px;
    }

    span {
      display: none;
    }
  }
}
