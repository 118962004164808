@import "../../../../../styles/base.scss";

.carouselCardContainer {
  padding: 0px;
  text-decoration: none;

  .carouselCard {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    flex: 1;
    height: 131px;
    background-color: #fff;
    padding: 12px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 2px 2px,
      rgba(0, 0, 0, 0.14) 0px 0px 2px;
    border-bottom: 3px solid #fff;

    .cardCarouselNumber {
      font-size: 16px;
      color: #1e1f24;
      margin-bottom: 10px;
      text-align: left;
    }

    .cardCarouselTitle {
      @extend %fontRoundBold;
      color: $cyan50;
      font-size: 16px;
      text-align: left;
    }

    &.currentSeason {
      border-bottom-color: $cyan50;
    }
  }
}
