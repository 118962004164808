@import "../../../../../styles/base.scss";

.chaptersList {
  .listAttachmentsHeader {
    display: flex;
    border-bottom: 1px solid $midnightBlue20;
    background-color: #fff;
    padding: 0 20px;

    .headerItem {
      color: $midnightBlue70;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      padding: 8px 16px;
      @extend %fontRoundBold;

      &.chapters {
        flex-grow: 2;
      }

      &.delivery {
        max-width: 250px;
        width: 100%;
        padding-left: 0;
        margin-right: 100px;
      }

      &.delivery,
      &.released {
        flex-grow: 1;
      }

      &.released {
        min-width: 235px;
        margin-right: 140px;
      }
    }
  }

  .attachmentsList {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    .listAttachmentsHeader {
      padding: 0;

      .headerItem {
        flex-grow: 1;

        &.chapters,
        &.delivery,
        &.released {
          flex-grow: 1;
          width: 100%;
          max-width: unset;
          margin: 0;
        }

        &.released {
          margin-right: 80px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .listAttachmentsHeader {
      padding: 0;

      .headerItem {
        flex-grow: 1;
        font-size: 10px;

        &.chapters {
          min-width: 120px;
        }

        &.chapters,
        &.delivery,
        &.released {
          width: 33%;
          max-width: unset;
          margin: 0;
        }

        &.released {
          margin-right: 80px;
        }
      }
    }
  }
}
