@import "../../../../styles/base.scss";

.scopeBreadcrumb {
  display: flex;
  align-items: center;
  padding: 32px 34px 0 34px;
  flex-wrap: wrap;

  .breadcrumbItem {
    display: flex;
    align-items: center;
    min-width: fit-content;

    & > span {
      color: $midnightBlue60;
      font-size: 16px;
      min-width: fit-content;
    }

    .breadcrumbName {
      cursor: pointer;
      min-width: fit-content;
      width: fit-content;
      text-align: center;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    .modalTitleSeparator {
      margin: 0 6px 12px 6px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 32px 16px 0 16px;
  }
}
