@import "../../../../styles/base.scss";

.importanceMark {
  display: flex;
  align-items: center;

  .dot {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;

    &.Central {
      color: #ec1045;
    }

    &.Secundária {
      color: #df9a37;
    }

    &.Terciária {
      color: #10af78;
    }

    &.unclassified {
      color: #9395a3;
    }
  }

  .dotLabel {
    display: block;
    font-size: 14px;
    margin: 0 6px;
  }
}
