@import "../../../../styles/base.scss";

#ParameterizedSearchModal {
  
  .windowScriptBox {
    width: 800px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    h2 {
      margin-left: 0;
    }
  
    .parameterizedModalContent {
      overflow: auto;
      max-height: 500px;
      flex: 1;
  
      .parametersArea {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: auto;
  
        #TypeParameter {
          padding: 24px;
  
          .radioInput {
            .radioOptionList {
              flex-direction: row;
              flex-wrap: wrap;
  
              .radioOption {
                margin-right: 40px;
  
                .radioButton {
                  margin-right: 10px;
                }
  
                .radioText {
                  margin-left: 10px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      } 
    }
  }

  @media screen and (max-width: 1024px) {
    .windowScriptBox {
      width: 100%;

      .content {
        
        .parameterizedModalContent {
          flex: 1;
          max-height: unset;
        }
      }
    }
  }
}

.parameterizedModalFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  max-height: 70px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 -2px 4px 0 #00000025;
  background-color: $midnightBlue0;
  margin-top: auto;

  .button.largeRound {
    padding: 10px 50px;
  }
}
