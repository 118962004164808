@import "../../../../styles/base.scss";

#EditSheetBasic {
  .registerForm {
    .formField {
      width: 100%;
      margin-bottom: 25px;
      position: relative;

      &.types {
        max-width: 522px;

        .switchTextContainer {
          .switchText {
            .switchOption {
              width: 33%;
            }
          }
        }
      }

      &.researchTitle {
        margin-bottom: 0;
      }

      .fieldset {
        flex: 1;
      }

      .formFieldColumn {
        margin-bottom: 0;
      }

      .numberInput {
        min-width: 200px;
        margin-bottom: 0;
        margin-left: 0;
      }

      .customDatepicker {
        margin-left: 30px;
        min-width: 210px;
      }

      .checkBoxContainer,
      .channelsCheckBoxContainer {
        margin-top: 20px;

        & > .label {
          color: $terciaryColor;
          @extend %fontRegular;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          display: block;
          margin-bottom: 8px;
        }

        .checkBoxes {
          display: flex;

          .checkBox {
            margin-left: 16px;
          }
        }

        .errorMessage {
          margin: 0 0 0 16px;
        }
      }

      .channelsCheckBoxContainer {
        margin-top: 0;
        width: 100%;

        & > .label {
          display: block;
          margin-bottom: 12px;
        }

        .subDetails {
          .fieldset {
            margin-bottom: 4px;
          }
        }
      }

      .chaptersContainer {
        height: fit-content;
        width: 150px;
        max-height: 60px;
        margin-left: 30px;

        .inputContainer {
          max-height: 60px;

          input {
            padding: 20px;
            max-height: 60px;
          }
        }

        .fieldset {
          margin-bottom: 0;
        }

        .optionalText {
          line-height: 16px;
          font-size: 11px;
          letter-spacing: 0.1em;
          color: #575863;
          display: block;
          padding-left: 2px;
          margin-top: 8px;
        }
      }

      .thesaurusDescriptionField {
        height: fit-content;
        width: 100%;

        #ThesaurusDescription {
          margin-bottom: 0;
          max-height: 20px;
          align-items: flex-start;
          position: relative;
          margin-bottom: 8px;

          & > div > svg {
            position: absolute;
            top: -5px;
          }
        }
      }
    }

    .formFieldColumn {
      position: relative;
      width: 100%;

      .formFieldColumn {
        margin-top: 25px;

        & > .label {
          color: $terciaryColor;
          @extend %fontRegular;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          display: block;
          margin-bottom: 8px;
        }
      }

      .helpContainer {
        margin-bottom: 0;
      }

      .scriptBoxCaller {
        // z-index: 1;
      }

      .optionalText {
        line-height: 16px;
        font-size: 11px;
        letter-spacing: 0.1em;
        color: #575863;
        display: block;
        padding-left: 2px;
        margin-top: 8px;
      }

      &.datepicker {
        max-width: fit-content;

        .errorMessage {
          margin-left: 30px;
        }
      }

      &.addNewTitleArea {
        // border: 1px solid red;
        margin-bottom: 25px;

        .switchContainer {
          margin-top: 0;

          .switchLabel {
            color: $midnightBlue90;
            text-transform: uppercase;
            font-size: 14px;
            @extend %fontRoundBold;
            margin-left: 8px;
          }
        }

        .fieldset {
          margin-top: 16px;
          margin-bottom: 4px;
        }

        .checkBox {
          .label {
            font-size: 16px;
          }
        }
      }
    }
  }

  .boxFooter {
    // z-index: 5;
    position: fixed;
    background-color: #e2e5fb;
  }

  @media screen and (max-width: 768px) {
    .registerForm {
      .formField {
        flex-wrap: wrap;

        .customDatepicker {
          margin-left: 0;
          min-width: 100%;
        }

        .titleNumber {
          margin-bottom: 25px;
        }

        .numberInput {
          margin-bottom: 0;
        }

        &.formatField {
          flex-direction: column;

          .chaptersContainer {
            margin-left: 0;
          }
        }
      }

      .formFieldColumn {
        &.datepicker {
          .errorMessage {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.ConfirmationBox {
  .ConfirmationWindow {
    border-radius: 4px;
    padding: 28px;

    .confirmationMessage {
      line-height: 32px;
      // max-width: 475px;
    }

    .confirmationButtons {
      margin-left: 0;
      width: 100%;

      & > div {
        width: 100%;
        display: flex;

        .buttonConfirm {
          flex: 1;
        }

        .buttonBlue {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .ConfirmationWindow {
      .confirmationButtons {
        & > div {
          flex-direction: column;

          .buttonCancel,
          .buttonConfirm,
          .buttonBlue {
            width: 100%;
            justify-content: center;
            margin-right: 0;
            margin-left: 0;

            &.simpleButton:last-of-type {
              margin-left: 0;
            }
          }

          .buttonCancel,
          .buttonConfirm {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
