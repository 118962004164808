@import "../../../../styles/base.scss";

.associateFieldsetOption {
  display: flex;
  padding: 16px 12px;
  border-bottom: 1px solid #d8d8d8;

  .typeIconContainer {
    height: 100%;

    .typeIconReturner {
      min-width: 24px;
      max-width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .optionInfo {
    display: flex;
    flex-direction: column;
    padding-top: 2px;

    .optionName {
      color: #43444e;
      font-weight: 600;
      padding-right: 32px;
      font-size: 16px;
      margin-bottom: 8px;
    }

    .creators {
      font-size: 14px;
      color: #43444e;
      padding-right: 32px;
      margin-bottom: 8px;
      text-transform: uppercase;

      .creator,
      .researcher {
        .separator {
          margin: 0 4px;
          color: $midnightBlue10;
        }
      }
    }

    .type {
      margin-bottom: 8px;
    }

    .type,
    .receivedAt {
      font-size: 14px;
      color: #43444e;
      padding-right: 24px;
    }
  }

  &:hover {
    background-color: #d1f0f9;
    cursor: pointer;
  }

  &.isFocused {
    background-color: #d1f0f9;
  }

  &:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}
