#HeaderListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 10px;

  li {
    text-transform: uppercase;
    color: #0098cd;
    font-size: 12px;
    margin-top: 3px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    &.title {
      width: calc(100% - 480px);
      justify-content: flex-start;
    }

    &.date {
      width: 100px;
    }

    &.status {
      width: 150px;
    }

    &.analise {
      width: 160px;

      &.red {
        color: #db1a33;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    li {
      &.date {
        width: 100px;
        justify-content: flex-end;
      }

      &.status {
        display: none;
      }

      &.analise {
        display: none;
      }
    }
  }
}
