@import "../../../styles/base.scss";

.numberInput {
  display: flex;
  flex-direction: column;
  flex-basis: 28%;
  margin-left: 30px;
  max-width: 200px;

  .label {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }

  .numberInputContainer {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    border: 2px solid #fff;
    user-select: none;

    &.darkBackgroundColor {
      border-color: #e2e4ff;
      background-color: #e2e4ff;
    }

    .inputButton {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.minus {
        padding-left: 10px;
      }

      &.more {
        padding-right: 10px;
      }
    }

    .numberValue {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 24px;
      font-size: 18px;
      color: #1f1f1f;
      text-align: center;
    }
  }

  &.error {
    .numberInputContainer {
      border-bottom-color: $errorColor;
    }
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    margin-left: 0;
  }
}
