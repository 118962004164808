@import "../../../styles/base.scss";

.loadError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
  animation: fadeIn 250ms;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .message,
  .reloadMessage {
    display: block;
    font-size: 16px;
    line-height: 20px;
    @extend %fontRoundBold;
    user-select: none;
  }

  .message {
    color: #43444e;
    margin: 15px 0 10px 0;
  }

  .reloadMessage {
    display: flex;
    align-items: center;
    color: #17b4e1;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  }
}
