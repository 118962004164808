@import "../../../../styles/base";

.fieldsFilter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  label {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }

  input[type="text"],
  input[type="password"],
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    font-size: 14px;
    color: #1f1f1f;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 16px 64px 16px 12px;
    transition: border 200ms ease-out 0s;
    border: 2px solid #ffffff;
    outline: none;

    &::placeholder {
      color: $placeholderColor;
    }

    &:focus {
      border: 2px solid #17b4e1;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
    }

    &.error {
      border-bottom-color: $errorColor;

      &:focus {
        border: 2px solid #17b4e1;
      }
    }

    &:disabled {
      background: #f1f2ff;
      border: 1px solid #f1f2ff;
    }
  }
}
