@import "../../../../styles/base.scss";

.scopeList {
  column-gap: 50px;
  column-count: 3;
  padding-bottom: 24px;
  max-width: 100%;
  padding: 32px 34px;

  &.childrenList {
    border-top: 1px solid $midnightBlue10;
  }

  @media screen and (max-width: 600px) {
    column-count: 2;
  }

  @media screen and (max-width: 420px) {
    column-count: 1;
  }
}
