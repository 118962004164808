@import "../../../../../styles/base.scss";

.contentStoryline {
  width: 100%;
  min-width: 210px;
  flex: 1;
  padding-left: 38px;
  padding-bottom: 4px;

  .storylineText {
    font-size: 15px;
    color: #1e1f24;
    line-height: 18px;
    display: inline-block;
  }

  .scriptBoxCaller {
    position: relative;
    font-weight: 200;
    color: $secondaryColor;
    display: inline-block;
    top: 0;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}
