@import "../../../../../styles/base.scss";

.episodesHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99.6%;
  color:#58627D;
  margin-top: 6px;
}


.datesHeaderRow {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: calc(100% - 40px);
  margin-left: 56px;  
  margin-bottom: 13px; // Ajuste conforme necessário para o espaçamento
}

.dateHeaderCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  text-align: center;
  margin-right: 8.8px;
}

.dateType {
  position: relative;
  width: auto;
  top: 4px;
  font-size: 11px;
  margin-bottom: 6px; 
  letter-spacing: 1px;
  font-weight: bold;
  padding-bottom: 4px;
  letter-spacing: 0.2px;

}

.dateType::after {
  content: '';
  position: absolute;
  top: 100%;
  left: -4px; 
  right: -4px; 
 
  
}

.datesNames {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 8px; 
  font-size: 11px;
  letter-spacing: 0.2px;
  font-weight: bold;
  padding-top: 4px;
}

.datesNames::before {
  content: ''; 
  position: absolute;
  top: 0; 
  left: -4px; 
  right: -4px; 
  border-top: 1px solid #000; 
 // margin-right: 16px;
  padding-right:16px ;
  padding-left:16px ;

}

.dateName {
  font-size: 10px;
  letter-spacing: 0.1px;
  margin-top: 4px;
}

@media screen and (max-width: 1200px) {
  .datesHeaderRow {
    width: 100%; /* Para telas menores, ajuste para a largura total */
    margin-left: 0; /* Remova a margem esquerda para telas menores */
    margin-right: 0; /* Remova a margem direita para telas menores */
    justify-content: space-between; 
  }
  .dateHeaderCell {
    flex-basis: calc(50% - 10px); /* Ajuste para duas células por linha */
    margin-right: 5px; /* Espaçamento menor entre as células */
    margin-bottom: 10px;
  }
}
