@import "../../../../../styles/base.scss";

.historyItem {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  background-color: #fff;
  border-bottom: 1px solid $midnightBlue10;

  .title {
    width: 42%;
    color: $midnightBlue60;
    @extend %fontRoundBold;
    font-size: 14px;
    white-space: pre;
  }

  .updatedBy {
    display: flex;
    align-items: center;
    width: 42%;
    color: #43444e;
    font-size: 12px;

    .avatarIcon {
      margin-right: 8px;

      .avatarLetter {
        @extend %fontRoundBold;
        text-transform: uppercase;
      }
    }
  }

  .updatedAt {
    width: 16%;
    min-width: 95px;
    color: #7f808e;
    font-size: 14px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 12px 24px;

    .title {
      margin-bottom: 8px;
      width: 70%;
    }

    .updatedBy {
      width: 70%;
    }

    .updatedAt {
      position: absolute;
      right: 24px;
      top: 16px;
      width: 25%;
      text-align: right;
    }
  }
}
