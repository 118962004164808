@import "../../../../../styles/base.scss";

.timelineItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2px 0;
  overflow-x: hidden;
  height: 40px;

  .timelineItemInfo {
    display: flex;
    margin-bottom: 2px;

    .timelineConflictName {
      font-size: 14px;
      @extend %fontRoundBold;
      color: #43444e;
    }
  }

  .timelineTrackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .timelineTrack {
      width: 100%;
      height: 1px;
      background-color: #d4d5e2;
      position: relative;
      top: 3px;
    }

    .graphicBarDotted {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5px;
      font-size: 14px;
      @extend %fontRoundBold;
      color: #fff;
      max-width: 100%;
      text-align: center;
      background-color: #fff;
    }
  }
}
