@import "../../../../../styles/base.scss";

.blockListItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid $midnightBlue0;

  .blockListItemHeaderNotClick {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f6f6ff;
    transition: 100ms ease-in-out;

    .blockListItemHeader {
      width: calc(100% - 110px);
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 15px 16px 15px 12px;

      .cell {
        margin-right: 16px;
        padding: 8px 16px;
      }

      .mainCell {
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-basis: 110px;
        max-width: 200px;

        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 5px 4px;
          border-color: transparent transparent $midnightBlue40 transparent;
          position: relative;
          transform: rotate(90deg);
          margin: 4px 8px 4px 0;
        }
      }

      .blockNumber {
        display: flex;
        align-items: center;
        width: 100px;
        font-size: 20px;
        white-space: nowrap;
        margin-right: 16px;

        & > svg {
          margin: 4px 4px 4px 0;
        }
      }

      .episode {
        margin-right: 16px;
        @extend %fontRoundBold;
      }

      .chaptersNumber {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100px;
        font-size: 16px;
        color: #9595a3;
        font-weight: 600;

        .responsive {
          display: none;
        }
      }

      .dates {
        min-width: 350px;
        max-width: 350px;
        display: flex;

        .date {
          display: flex;
          font-size: 14px;
          padding: 0 16px 0 16px;
          width: 33%;
          color: #9595a3;
          font-weight: 600;

          .datePlaceholder {
            display: flex;
            width: 100%;
            padding-right: 16px;
            justify-content: center;
          }
        }
      }

      .datesText {
        min-width: 205px;
        max-width: 205px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

        .date {
          display: flex;
          font-size: 14px;
          padding: 0 16px 0 16px;
          width: 100%;
          color: #9595a3;
          font-weight: 600;
          justify-content: center;
          text-align: center;

          .datePlaceholder {
            display: flex;
            width: 100%;
            padding-right: 16px;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }

  .blockActions {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 116px;
    justify-content: flex-end;
    padding-right: 16px;

    & > div,
    & > svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .responsiveDates {
    display: none;
    transition: 100ms ease-in-out;

    .datesHeader {
      width: 100%;
      text-transform: uppercase;
      margin: 0;
      padding: 0 16px 16px 16px;

      .dateType {
        letter-spacing: 1px;
        font-weight: 600;
        @extend %fontRoundBold;
        margin-bottom: 8px;
        padding: 4px 0;
      }

      .datesNames {
        color: $midnightBlue90;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        flex-direction: column;

        .dateName {
          font-weight: 600;
          @extend %fontRoundBold;
          width: 100%;
          justify-content: space-between;
          padding: 6px 0;

          .dateValue {
            font-weight: normal;
            @extend %fontRegular;
            padding-left: 16px;
            font-size: 12px;
            letter-spacing: normal;
          }
        }
      }
    }
  }

  .blockListItemContent {
    max-height: 0;
    overflow: hidden;
  }

  &.open {
    .blockListItemHeaderNotClick {
      background-color: #fff;

      .blockListItemHeader {
        .blockNumber {
          & > svg {
            transform: rotate(90deg);
          }
        }

        .episode {
          & > .triangle {
            transform: rotate(180deg);
          }
        }
      }
    }

    .blockListItemContent {
      max-height: 999999px;
    }

    .responsiveDates {
      background-color: #fff;
    }
  }

  @media screen and (max-width: 1200px) {
    .blockListItemHeaderNotClick {
      .blockListItemHeader {
        justify-content: space-between;

        .blockNumber {
          max-width: 120px;
          margin-right: 0;
        }

        .blockActions {
          flex-grow: 0;
        }

        .chaptersNumber {
          flex: 1;
          width: 100%;
          max-width: 100%;
          margin-right: 0;

          .responsive {
            display: block;
            margin-left: 6px;
          }
        }

        .dates {
          display: none;
        }
      }
    }

    .responsiveDates {
      display: flex;
      background-color: #f6f6ff;
    }
  }

  @media screen and (max-width: 620px) {
    .blockListItemHeader {
      .blockNumber {
        font-size: 18px;
      }

      .chaptersNumber {
        font-size: 16px;
      }
    }

    .responsiveDates {
      .datesHeader:nth-child(1) {
        padding: 0 12px 16px 16px;
      }
      .datesHeader:nth-child(2) {
        padding: 0 16px 16px 12px;
      }
    }
  }

  @media screen and (max-width: 440px) {
    .responsiveDates {
      .datesHeader:nth-child(1) {
        padding: 0 8px 16px 16px;
      }
      .datesHeader:nth-child(2) {
        padding: 0 16px 16px 8px;
      }
      .datesHeader {
        .datesNames {
          .dateName {
            font-size: 10px;
            .dateValue {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
