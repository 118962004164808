@import "../../../../../styles/base";

.totalCountActivities {
  font-size: 18px;
  @extend %fontRegular;
  font-style: italic;
  color: $midnightBlue70;
  padding: 30px 0;

  span {
    @extend %fontRoundBold;
    color: #43444e;
  }
}
