@import "../../../../../styles/base.scss";

.versionsListChapterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 16px 16px;
  background-color: #fff;

  .versionsListChapterHeader {
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 20px;
    color: $midnightBlue70;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    @extend %fontRoundBold;
    border-bottom: 1px solid $midnightBlue20;

    .version {
      display: flex;
      min-width: 70px;
      margin-right: 50px;
    }

    .file {
      flex-grow: 2;
    }

    .delivery {
      max-width: 262px;
      margin-right: 88px;
    }

    .delivery,
    .released {
      flex-grow: 3;
    }

    .released {
      max-width: 110px;
      width: 110px;
      margin-right: 127px;
      padding-left: 16px;
    }
  }

  .versionsListChapterItems {
    .versionItemChapter {
      display: flex;
      align-items: center;
      background-color: $midnightBlue0;
      border-bottom: 1px solid #fff;
      padding: 16px 16px 16px 20px;
      font-size: 14px;

      .versionChapter {
        display: flex;
        min-width: 70px;
        padding-left: 20px;
        margin-right: 50px;
        color: #9192a2;
      }

      .fileChapter {
        display: flex;
        flex-grow: 2;
        color: #9192a2;
        word-break: break-all;
        max-width: 345px;
        padding-right: 16px;
      }

      .deliveryChapter {
        max-width: 260px;
      }

      .deliveryChapter,
      .releasedChapter {
        flex-grow: 3;

        .datePlaceholderChapter {
          display: flex;
          padding-left: 24px;
        }
      }

      .releasedChapter {
        max-width: 100px;
        width: 100px;
        margin-left: auto;
        margin-right: 3px;
        padding-left: 16px;
      }

      .actionsChapter {
        display: flex;
        align-items: center;
        flex-basis: 135px;
        justify-content: flex-end;

        & > svg,
        & > div,
        & > span {
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0 10px 16px 10px;

    .versionsListChapterHeader {
      padding: 8px 0;

      .version {
        min-width: 110px;
      }

      .file {
        display: none;
      }

      .version,
      .delivery,
      .released {
        padding: 0;
        max-width: 33%;
        width: 33%;
        margin: 0;
        font-size: 11px;
        flex-grow: 1;
      }

      .released {
        margin-right: 74px;
        padding-left: 12px;
      }
    }

    .versionsListChapterItems {
      .versionItemChapter {
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        padding: 16px 0 16px 0;

        .version {
          order: 0;
          margin-right: 0;
          max-width: 110px;
          max-width: 33%;
          margin: 0;
        }

        .version,
        .delivery,
        .released {
          min-width: calc(33% - 22px);
          max-width: calc(33% - 22px);
          margin: 0;
          font-size: 12px;
        }

        .released {
          padding-left: 12px;
        }

        .fileChapter {
          order: 4;
          max-width: 55%;
          flex-basis: 50%;
          margin-top: 16px;
          padding-left: 12px;
          bottom: 16px;
          left: 16px;
        }

        .actionsChapter {
          order: 5;
          max-width: 50%;
          flex-basis: 130px;
          justify-content: flex-end;
          margin-top: 16px;
          bottom: 16px;
          right: 8px;
          margin-left: auto;
          padding-right: 6px;
          align-self: flex-end;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .versionsListChapterHeader {
      .version,
      .delivery,
      .released {
        font-size: 10px;
      }
    }

    .versionsListChapterItems {
      .versionItemChapter {
        .deliveryChapter,
        .releasedChapter {
          font-size: 11px;
        }
      }
    }
  }
}
