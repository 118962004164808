@import "../../../styles/base.scss";

.listAttachments {
  .attachmentsHeader {
    display: flex;
    align-items: center;
    height: 34px;
    background-color: #f1f2ff;
    margin-bottom: 1px;
    padding: 0 24px;

    li {
      color: #43444e;
      font-size: 12px;
      @extend %fontRoundBold;
      text-transform: uppercase;
      padding-top: 4px;
    }

    .type {
      flex: 3;
      max-width: 335px;
    }

    .version {
      width: 60px;
      text-align: center;
      margin-right: 70px;
    }

    .file {
      flex: 4;
      word-break: break-all;
    }

    .receivedAt {
      width: 125px;
    }
  }

  .attachmentItem {
    display: flex;
    flex-direction: column;

    .mainVersionItem {
      display: flex;
      align-items: center;
      height: 64px;
      background-color: #fff;
      margin-bottom: 1px;
      padding: 0 24px;

      .type {
        display: flex;
        align-items: center;
        flex: 3;
        cursor: pointer;
        max-width: 335px;

        .checkBox {
          .check {
            width: 18px;
            height: 18px;
          }
        }

        .typeName {
          @extend %fontRoundBold;
          font-size: 14px;
          margin-right: 8px;
          padding-top: 3px;
        }

        .blockNumber {
          @extend %fontLight;
          min-width: 60px;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.1em;
          padding-top: 3px;
        }
      }

      .version {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        margin-right: 70px;
        user-select: none;

        span {
          display: block;
          font-size: 14px;
          text-align: center;
          margin-right: 4px;
        }

        .versionText {
          display: none;
        }

        .itemArrow {
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 6px;
          border-color: transparent transparent transparent #999be0;
          margin-bottom: 2px;
          margin-left: 4px;
          transition: 200ms ease-in-out;
        }

        &.hasVersions {
          @extend %fontRoundBold;
          color: $cyan50;
          cursor: pointer;
        }
      }

      .file {
        @extend %fontRoundBold;
        color: $cyan50;
        font-size: 14px;
        flex: 4;
        word-break: break-all;
        padding-right: 16px;
      }

      .receivedAt {
        font-size: 14px;
        width: 100px;
      }

      .activitiesNumber {
        position: relative;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #7070b3;
          color: #ffff;
          position: absolute;
          bottom: -5px;
          right: -5px;
          border-radius: 100%;
          font-size: 10px;
          @extend %fontRoundBold;
          padding-top: 3px;
        }
      }
    }

    .versionsList {
      max-height: 0;
      overflow-y: hidden !important;
    }

    &.opened {
      .mainVersionItem {
        .version {
          .itemArrow {
            transform: rotate(90deg);
          }
        }
      }

      .versionsList {
        max-height: 10000px;

        .versionItem {
          display: flex;
          align-items: center;
          height: 64px;
          background-color: #eaecf7;
          margin-bottom: 1px;
          padding: 0 23px;

          .type {
            display: flex;
            align-items: center;
            flex: 3;
            cursor: pointer;
            max-width: 335px;

            .checkBox {
              .check {
                width: 18px;
                height: 18px;
              }
            }

            .typeName {
              @extend %fontRoundBold;
              font-size: 14px;
              margin-right: 8px;
              padding-top: 3px;
            }

            .blockNumber {
              @extend %fontLight;
              text-transform: uppercase;
              font-size: 12px;
              letter-spacing: 0.1em;
              padding-top: 3px;
            }
          }

          .version {
            font-size: 14px;
            width: 60px;
            text-align: center;
            margin-right: 70px;

            .versionText {
              display: none;
            }
          }

          .file {
            @extend %fontRoundBold;
            color: $cyan50;
            font-size: 14px;
            flex: 4;
            word-break: break-all;
            padding-right: 16px;
          }

          .receivedAt {
            font-size: 14px;
            width: 100px;
          }

          .activitiesNumber {
            position: relative;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              width: 16px;
              height: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #7070b3;
              color: #ffff;
              position: absolute;
              bottom: -5px;
              right: -5px;
              border-radius: 100%;
              font-size: 10px;
              @extend %fontRoundBold;
              padding-top: 3px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    .attachmentsHeader {
      padding: 0 12px;

      .type,
      .version {
        display: none;
      }

      .receivedAt {
        text-align: right;
      }
    }

    .attachmentItem {
      position: relative;

      .mainVersionItem,
      .versionItem {
        height: fit-content;
        padding: 8px 12px 75px 12px;
        position: relative;

        .type {
          max-width: 100%;
        }

        .file {
          position: absolute;
          left: 12px;
          bottom: 48px;
          word-break: break-all;
        }

        .version {
          position: absolute;
          bottom: 12px;
          left: 12px;
          width: 80px;
          text-align: left;

          .versionText {
            display: block;
          }
        }

        .receivedAt {
          text-align: right;
        }

        .activitiesNumber {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }
      }

      &.opened {
        .versionsList {
          .versionItem {
            height: fit-content;
            padding: 8px 12px 75px 12px;
            position: relative;

            .type {
              .typeName {
                margin-right: 8px;
              }

              .blockNumber {
                min-width: 60px;
              }
            }

            .version {
              display: flex;

              .versionNumber {
                margin-right: 4px;
              }

              .versionText {
                display: block;
              }
            }

            .activitiesNumber {
              position: absolute;
              bottom: 16px;
              right: 16px;
            }
          }
        }
      }
    }
  }
}
