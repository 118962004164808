@import "../../../../styles/base.scss";

#ParametersList {
  .parametersListTitle {
    display: block;
    font-size: 24px;
    color: #43444e;
    margin-top: 12px;
  }

  .tagsList {
    display: flex;
    flex-direction: column;

    .parameterContainer {
      display: flex;
      flex-direction: column;

      .parameterLabel {
        font-size: 14px;
        color: #7f808e;
        margin: 10px 0 5px 0;
      }

      .parameterTagList {
        display: flex;
        flex-wrap: wrap;
      }

      .parameterTag {
        display: flex;
        align-items: baseline;
        margin: 1px 3px 2px 0;
        padding: 4px 26px 4px 7px;
        border-radius: 2px;
        background-color: #ffffff99;
        font-size: 14px;
        color: #7f808e;
        width: fit-content;
        position: relative;
        min-height: 24px;

        .parameterTerm {
          width: calc(100% - 4px);
          margin-right: 4px;
          @extend %fontRoundBold;
          display: flex;
          flex-wrap: wrap;
          position: relative;
        }

        .removeTag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          transition: 50ms ease-in-out;
          cursor: pointer;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          font-weight: normal;
          @extend %fontRegular;

          &:hover {
            background-color: #7f808e;
            color: #fff;
          }
        }
      }
    }
  }

  .changeParameters {
    display: block;
    color: $cyan50;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
  }
}
