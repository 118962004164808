@import "../../../../../styles/base.scss";

.titleHistories {
  padding-left: 38px;
  color: #7f808e;
  margin-bottom: 4px;

  .oldTitleKey {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 4px;
  }

  .oldTitleValue {
    font-size: 15px;

    .separator {
      margin: 0 4px;
      border-bottom: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}
