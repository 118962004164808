@import "../../../../styles/base.scss";

.activitiesNavigation {
  height: 54px;
  background-color: $terciaryColor;
  justify-content: center;
  overflow-x: auto;
  position: sticky;
  top: 0;
  z-index: 10;
  scrollbar-width: none;

  .outterContainer {
    margin: 0 auto;
    max-width: 1120px;
    position: relative;

    .goRight,
    .goLeft {
      opacity: 0;
      transition: 100ms ease-in-out;
    }

    &:hover {
      .goRight,
      .goLeft {
        opacity: 1;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  .activitiesNavigationContainer {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    position: relative;

    &.centerLoading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    .typesContainer {
      max-width: 1120px;
      overflow: visible;
      display: flex;

      .type {
        color: rgb(173, 175, 239);
        @extend %fontRoundBold;
        cursor: pointer;
        user-select: none;
        font-size: 18px;
        line-height: 18px;
        padding: 18px 24px;
        min-width: fit-content;
        display: flex;
        align-items: center;
        max-height: 100%;

        svg {
          margin-right: 8px;
        }

        &.active {
          color: #fff;
          position: relative;

          &::after {
            content: "";
            width: 100%;
            height: 4px;
            background-color: #fff;
            position: absolute;
            bottom: -1px;
            left: 0;
          }
        }

        &.disabledNavigation {
          pointer-events: none;
        }

        .totalLabel {
          margin-left: 4px;
        }
      }
    }
  }

  .goRight {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 64px;
    box-shadow: inset -29px 0px 23px -23px $midnightBlue40;

    svg {
      position: relative;
      left: 32px;
    }
  }

  .goLeft {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 64px;
    box-shadow: inset 29px 0px 23px -23px $midnightBlue40;

    svg {
      position: relative;
      left: 10px;
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: 540px) {
    .searchesNavigationContainer {
      .type {
        padding: 18px 18px;

        svg {
          margin-right: 0;
        }

        .typeLabel {
          display: none;
        }
      }
    }
  }
}
