@import "../../../../../styles/base.scss";

.noBlocksOrEpisodes {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 36px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .message {
    margin-bottom: 24px;
    font-size: 18px;
    text-align: center;
  }

  .simpleButton {
    padding: 8px 44px;
  }
}
