@import "../../../styles/base.scss";

#BalloonDropdown {
  width: fit-content;
  display: flex;

  .visibleContainer {
    display: flex;

    .moreAttachments {
      font-size: 14px;
      color: $cyan50;
      position: relative;
      display: flex;

      & > span {
        user-select: none;
        cursor: pointer;
        @extend %fontRoundBold;
        display: block;
        white-space: nowrap;
      }

      .invisibleContainer {
        position: absolute;
        right: -16px;
        top: calc(100% + 4px);
        width: 240px;
        display: flex;
        flex-direction: column;
        z-index: 12;
        display: none;

        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 8px 6px 8px;
          border-color: transparent transparent #ffffff transparent;
          position: relative;
          left: 165px;
        }

        .dropdownContent {
          background-color: #fff;
          padding: 16px 0;
          border-radius: 4px;
          box-shadow: 0 0 18px 0 #00000080;

          .closeIcon {
            display: none;
          }

          .dropdownList {
            max-height: 230px;
            overflow: auto;

            .fileItem {
              display: flex;
              align-items: center;
              padding: 10px 4px 10px 16px;
              position: relative;

              &:not(:last-child)::after {
                content: "";
                display: block;
                width: 90%;
                height: 1px;
                background-color: #6b6c7834;
                position: absolute;
                bottom: 0;
                left: 5%;
              }

              .fileCap {
                font-size: 14px;
                color: #43444e;
                line-height: 16px;
                padding-right: 4px;
                letter-spacing: 1px;
              }

              .fileBlock {
                font-size: 12px;
                text-transform: uppercase;
                border: 1px solid #8586cc;
                color: #8586cc;
                border-radius: 2px;
                line-height: 16px;
                letter-spacing: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 3px 0 3px;
                margin-left: 3px;
              }

              .fileVersion {
                font-size: 14px;
                color: #6b6c78;
                margin-left: auto;
                padding-right: 10px;
              }
            }
          }
        }
      }

      &.openedDropdown {
        .invisibleContainer {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .visibleContainer {
      .moreAttachments {
        .invisibleContainer {
          top: 120px;
          right: -16px;
          left: 16px;
          bottom: 64px;
          position: fixed;
          width: calc(100% - 32px);
          max-width: 100%;

          .triangle {
            display: none;
          }

          .dropdownContent {
            height: 100%;

            .closeIcon {
              display: flex;
              margin-left: auto;
              justify-content: flex-end;
              align-items: center;
              padding-right: 16px;
            }

            .dropdownList {
              max-height: calc(100% - 32px);
              padding: 16px;
            }
          }
        }
      }
    }
  }
}
