@import "../../../../styles/base.scss";

#ParameterizedSearchButton {
  display: flex;
  align-items: center;
  margin-top: 26px;
  cursor: pointer;

  .buttonText {
    display: block;
    height: 25px;
    font-size: 18px;
    color: $secondaryColor;
    margin-left: 12px;
    margin-top: 4px;
  }
}
