@import "../../../../../styles/base.scss";

.cellDatesRow{
    display: flex;
    margin-left: 55px;
    gap: 6px;
}

.cellDates{
  display: grid;
  grid-template-columns:repeat(2, 65px);
  }

  .date{
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 600;
    color: '#9595a3';
    .datePlaceholder {
      display: flex;
      justify-content: space-between;
      margin-left: 22px;
    }
  }

  .cellDatesRowMobile{
    display: none;
  }

  .cellDatesRowMobile{
    padding-bottom: 10px;
  }
  @media screen and (max-width: 1024px) {
    .cellDatesRow{
      display: none;
    }
  
    .cellDates{
      display: none;
    }

    .cellDatesRowMobile{
      display: grid;
      grid-template-columns:repeat(2, 350px);
      grid-column-gap: 19%;
      margin-left: -10%;
    }
    .datesCellMobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 0;
      text-align: center;
      margin-right: 8.8px;
      margin-top: 10px;
      margin-bottom: 10px ;
    }
    .datesTitleMobile{
      border-bottom: 1px solid #000; 
      position: relative;
      width: 460px;
      top: 4px;
      font-size: 12px;
      margin-bottom: 6px; 
      letter-spacing: 1px;
      font-weight: bold;
      padding-bottom: 4px;
    }

    .datesTitleMobile::after {
      content: '';
      position: absolute;
      top: 100%;
      left: -4px; 
      right: -4px; 
    }

    .datesAggroupMobile{
      display: flex;
      flex-direction: row;
      width: 460px;
      justify-content: left;
      gap: 73%;
    }

    .datesMobile{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px; 
      font-size: 11px;
      letter-spacing: 0.2px;
      font-weight: bold;
      padding-top: 4px;
    }

    .datesMobile::before {
      content: ''; 
      position: absolute;
      top: 0; 
      left: -7px; 
      right: -7px; 
      border-top: 0px solid #000; 
      padding-right:16px ;
      padding-left:16px ;
    
    }

    .dateMobile{
      font-size: 11px;
  letter-spacing: 1px;
  margin-top: 4px;
  .datePlaceholder {
    display: flex;
    margin-left: 25px;
  }
    }
  }

  @media screen and (max-width: 768px) {
    .cellDatesRow{
      display: none;
    }
  
    .cellDates{
      display: none;
    }

    .cellDatesRowMobile{
      display: grid;
      grid-template-columns:repeat(2, 260px);
      grid-column-gap: 19%;
      margin-left: -10%;
    }
    .datesCellMobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 0;
      text-align: center;
      margin-right: 8.8px;
      margin-top: 10px;
      margin-bottom: 10px ;
    }
    .datesTitleMobile{
      border-bottom: 1px solid #000; 
      position: relative;
      width: 340px;
      top: 4px;
      font-size: 12px;
      margin-bottom: 6px; 
      letter-spacing: 1px;
      font-weight: bold;
      padding-bottom: 4px;
    }

    .datesTitleMobile::after {
      content: '';
      position: absolute;
      top: 100%;
      left: -4px; 
      right: -4px; 
    }

    .datesAggroupMobile{
      display: flex;
      flex-direction: row;
      width: 340px;
      justify-content: left;
      gap: 64%;
    }

    .datesMobile{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px; 
      font-size: 11px;
      letter-spacing: 0.2px;
      font-weight: bold;
      padding-top: 4px;
    }

    .datesMobile::before {
      content: ''; 
      position: absolute;
      top: 0; 
      left: -7px; 
      right: -7px; 
      border-top: 0px solid #000; 
      padding-right:16px ;
      padding-left:16px ;
    
    }

    .dateMobile{
      font-size: 11px;
  letter-spacing: 1px;
  margin-top: 4px;
  .datePlaceholder {
    display: flex;
    margin-left: 25px;
  }
    }
  }
  @media screen and (max-width: 425px) {
    .cellDatesRow{
      display: none;
    }
  
    .cellDates{
      display: none;
    }

    .cellDatesRowMobile{
      display: grid;
      grid-template-columns:repeat(2, 155px);
      margin-left: -50px;
    }
    .datesTitleMobile{
      position: relative;
      width: 180px;
      font-size: 12px;
      margin-bottom: 6px; 
      letter-spacing: 1px;
      font-weight: bold;
      letter-spacing: 0.29px;
      border-bottom: 1px solid #000; 
    }

    .datesAggroupMobile{
      display: flex;
      flex-direction: row;
      width: 178px;
      justify-content: left;
      gap: 65px;
    }

    .datesMobile{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px; 
      font-size: 10px;
      letter-spacing: 0.2px;
      font-weight: bold;
      padding-top: 4px;
    }
    .datesMobile::before {
      content: ''; 
      position: absolute;
      top: 0; 
      left: -6px; 
      right: -6px; 
      border-top: 0px solid #000; 
      padding-right:16px ;
      padding-left:16px ;
    }

    .dateMobile{
      font-size: 10px;
      letter-spacing: 1px;
      margin-top: 4px;
      .datePlaceholder {
        display: flex;
        margin-left: 25px;
      }
    }
  }
  @media screen and (max-width: 375px) {
    .cellDatesRow{
      display: none;
    }
  
    .cellDates{
      display: none;
    }

    .cellDatesRowMobile{
      display: grid;
      grid-template-columns:repeat(2, 135px);
    }
    .datesTitleMobile{
      position: relative;
      width: 155px;
      font-size: 12px;
      margin-bottom: 6px; 
      letter-spacing: 1px;
      font-weight: bold;
      letter-spacing: 1px;
      border-bottom: 1px solid #000; 
    }

    .datesAggroupMobile{
      display: flex;
      flex-direction: row;
      width: 155px;
      justify-content: left;
      gap: 40px;
    }

    .datesMobile{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px; 
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: bold;
      padding-top: 4px;
    }
    .datesMobile::before {
      content: ''; 
      position: absolute;
      top: 0; 
      left: -6px; 
      right: -6px; 
      border-top: 0px solid #000; 
      padding-right:16px ;
      padding-left:16px ;
    }

    .dateMobile{
      font-size: 10px;
      letter-spacing: 1px;
      margin-top: 4px;
      .datePlaceholder {
        display: flex;
        margin-left: 25px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .cellDatesRow{
      display: none;
    }
  
    .cellDates{
      display: none;
    }

    .cellDatesRowMobile{
      display: grid;
      grid-template-columns:repeat(2, 85px);
      grid-column-gap: 70px;
      margin-left: 8px;
    }
    .datesTitleMobile{
      position: relative;
      width: 145px;
      font-size: 12px;
      margin-bottom: 6px; 
      letter-spacing: 1px;
      font-weight: bold;
      border-bottom: 1px solid #000; 
    }

    .datesAggroupMobile{
      display: flex;
      flex-direction: row;
      width: 140px;
      justify-content: left;
      gap: 25px;
    }

    .datesMobile{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px; 
      font-size: 10px;
      letter-spacing: 0.2px;
      font-weight: bold;
      padding-top: 4px;
    }
    .datesMobile::before {
      content: ''; 
      position: absolute;
      top: 0; 
      left: -6px; 
      right: -6px; 
      border-top: 0px solid #000; 
      padding-right:16px ;
      padding-left:16px ;
    }

    .dateMobile{
      font-size: 10px;
      letter-spacing: 1px;
      margin-top: 4px;
      .datePlaceholder {
        display: flex;
        margin-left: 22px;
      }
    }
  }
  