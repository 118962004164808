@import "../../../styles/base.scss";

.selectContent {
  width: 100%;

  .reactSelect {
    min-height: 50px;

    .filterProjects__control {
      border: 2px solid #fff;
      border-radius: 4px;
      min-height: 50px;
      width: 100%;

      &:hover {
        border: 2px solid #fff;
      }

      .filterProjects__value-container {
        display: flex;
        height: 100%;

        .filterProjects__placeholder {
          font-size: 18px;
          color: #8586cc;
        }

        .filterProjects__input {
          font-size: 18px;
        }

        .filterProjects__single-value {
          font-size: 18px;
        }

        .filterProjects__multi-value {
          display: flex;
          flex-direction: row-reverse;
          margin: 4px;
          min-height: 38px;
          background-color: #e4f8ff;
          border: 1px solid #aaf0fd;
          border-radius: 4px;

          .filterProjects__multi-value__remove {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 26px;
            max-width: 26px;
            color: #17b4e1;
            border-right: 1px solid #aaf0fd;
            cursor: pointer;

            &:hover {
              background-color: #d0ecfd;
            }
          }

          .filterProjects__multi-value__label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            flex-wrap: wrap;
            max-width: 100%;
            padding: 6px 10px 4px 10px;
            white-space: normal;
          }
        }
      }

      .filterProjects__indicators {
        min-height: 100%;
        max-height: 100%;

        .filterProjects__indicator-separator,
        .filterProjects__dropdown-indicator {
          display: none;
        }

        .filterProjects__clear-indicator {
          cursor: pointer;
        }
      }
    }

    .filterProjects__control--is-focused {
      border-color: #17b4e1;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);

      &:hover {
        border-color: #17b4e1;
      }
    }

    .filterProjects__menu {
      top: calc(100% - 11px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none;
      border: 2px solid #17b4e1;
      border-top-width: 1px;
      border-top-color: #d4d5e2;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
      max-height: 250px;

      .filterProjects__menu-list {
        padding: 0;
        max-height: 247px;

        .filterProjects__option {
          font-size: 18px;
          padding: 14px 12px;
          cursor: pointer;

          &:hover {
            background-color: #e4f8ff;
          }

          &:last-of-type {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        .filterProjects__option--is-focused {
          background-color: #e4f8ff;
          color: #000;
        }

        .filterProjects__option--is-selected {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }

  .dropdownIndicator {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;

    &.isFocused {
      transform: rotate(180deg);
    }

    &.isLoading,
    &.hasValue {
      display: none;
    }
  }

  .singleValue {
    display: flex;
    padding: 8px 0 8px 6px;

    .singleValueInfo {
      display: flex;
      align-items: center;

      .contentName {
        display: flex;
        align-items: center;

        .typeIconReturner {
          min-width: 24px;
          max-width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .singleValueName {
          color: #43444e;
          font-weight: 600;
          padding-right: 32px;
          min-width: 320px;
          max-width: 320px;
          font-size: 16px;
          white-space: pre-wrap;
        }
      }

      .creators {
        min-width: 200px;
        max-width: 200px;
        font-size: 14px;
        color: #43444e;
        padding-right: 32px;
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;

        .creator,
        .researcher {
          .separator {
            margin: 0 4px;
            color: $midnightBlue10;
          }
        }
      }

      .type {
        width: 145px;
      }

      .type,
      .receivedAt,
      .origins {
        font-size: 14px;
        color: #43444e;
        padding-right: 24px;
      }
    }

    .singleValueName {
      color: #43444e;
      font-weight: 600;
    }
  }

  .optionItem {
    display: flex;
    padding: 16px 12px;
    border-bottom: 1px solid #d8d8d8;

    .optionInfo {
      display: flex;
      align-items: center;
      padding-top: 2px;

      .contentName {
        display: flex;
        align-items: center;

        .typeIconReturner {
          min-width: 24px;
          max-width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .optionName {
          color: #43444e;
          font-weight: 600;
          padding-right: 32px;
          min-width: 320px;
          max-width: 320px;
          font-size: 16px;
        }
      }

      .creators {
        min-width: 200px;
        max-width: 200px;
        font-size: 14px;
        color: #43444e;
        padding-right: 32px;
        text-transform: uppercase;

        .creator,
        .researcher {
          .separator {
            margin: 0 4px;
            color: $midnightBlue10;
          }
        }
      }

      .type {
        width: 145px;
      }

      .type,
      .receivedAt {
        font-size: 14px;
        color: #43444e;
        padding-right: 24px;
      }
    }

    &:hover {
      background-color: #d1f0f9;
      cursor: pointer;
    }

    &.isFocused {
      background-color: #d1f0f9;
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  .clearIndicator {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 100%;
    padding-right: 8px;
  }

  @media screen and (max-width: 1130px) {
    .singleValue {
      .singleValueInfo {
        flex-direction: column;
        align-items: flex-start;
        min-width: fit-content;
        max-width: fit-content;

        .creators {
          padding-left: 44px;
          margin-bottom: 8px;
          min-width: 420px;
          min-width: 420px;
        }

        .type {
          width: fit-content;
        }

        .type,
        .receivedAt,
        .origins {
          padding-left: 44px;
          margin-bottom: 8px;
        }

        .contentName {
          .singleValueName {
            margin-bottom: 8px;
            padding-top: 8px;
            min-width: 420px;
            max-width: 420px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .reactSelect {
      width: 100%;
    }
  }

  @media screen and (max-width: 920px) {
    .optionItem {
      .optionInfo {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 2px;

        .contentName {
          margin-bottom: 8px;

          .optionName {
            min-width: 320px;
            max-width: 320px;
          }
        }

        .creators {
          min-width: fit-content;
          max-width: 400px;
          padding-left: 44px;
          margin-bottom: 8px;
        }

        .type {
          margin-bottom: 8px;
        }

        .type,
        .receivedAt,
        .origins {
          padding-right: 24px;
          padding-left: 44px;
          width: 250px;
        }
      }
    }
  }

  @media screen and (max-width: 620px) {
    .singleValue {
      .singleValueInfo {
        .creators {
          padding-left: 37px;
          margin-bottom: 8px;
          min-width: 420px;
          min-width: 420px;
        }

        .type {
          width: fit-content;
        }

        .type,
        .receivedAt,
        .origins {
          padding-left: 37px;
          margin-bottom: 8px;
        }

        .contentName {
          .singleValueName {
            margin-bottom: 8px;
            padding-top: 8px;
            min-width: 420px;
            max-width: 420px;
          }
        }
      }
    }

    .optionItem {
      .optionInfo {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 2px;

        .contentName {
          margin-bottom: 8px;

          .optionName {
            min-width: 320px;
            max-width: 320px;
          }
        }

        .creators {
          min-width: fit-content;
          max-width: 400px;
          padding-left: 36px;
        }

        .type {
          margin-bottom: 8px;
        }

        .type,
        .receivedAt {
          padding-left: 36px;
          width: 250px;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .singleValue {
      .singleValueInfo {
        .creators {
          padding-left: 37px;
          margin-bottom: 8px;
          min-width: 275px;
          min-width: 275px;
        }

        .type {
          width: fit-content;
          max-width: 275px;
        }

        .type,
        .receivedAt,
        .origins {
          padding-left: 37px;
          margin-bottom: 8px;
        }

        .contentName {
          .singleValueName {
            margin-bottom: 8px;
            padding-top: 8px;
            min-width: 275px;
            max-width: 275px;
            padding-right: 64px;
          }
        }
      }
    }
  }
}
