@import "../../../styles/base.scss";

.scriptBoxCaller {
  display: block;
  color: $cyan60;
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: 0;
  z-index: 3;
  user-select: none;
}
